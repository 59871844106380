import { connect } from 'react-redux';
import { RouterProp, withRouter } from 'utils/withRouter';
import * as activityUtil from '@kritik/utils/activity';
import * as activityStatusUtil from '@kritik/utils/stage';
import * as creationStatusUtil from '@kritik/utils/creation/status';
import { selectAuthUser } from 'selectors/user';
import CardNotification from 'components/ActivityCard/Notifications/Main';
import RAISE_DISPUTE from 'images/disputes/dispute_white.svg';
import RESOLVE_DISPUTE from 'images/disputes/dispute_grey.svg';
import RAISE_COMMENT from 'images/comments/comment_white.svg';
import SEEN_COMMENT from 'images/comments/comment_grey.svg';
import Access from 'components/Access';
import ActivitySelector from 'selectors/activity';
import { setCreationTableFilter } from 'redux/creationTable/actions';
import { FILTERS } from 'components/CreationList/constant';
import { Activity, User, Creation } from '@kritik/types.generated';
import { AuthUser } from 'app-types';
import { useUserRoleInCourse } from 'hooks/course';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const ICON_STYLES = {
  width: 'inherit',
};

const FlaggedComments = (activity: Activity) => {
  const numFlags = activityUtil.getNumFlags(activity);
  if (!numFlags) {
    return null;
  }
  return (
    <CardNotification
      status="warning"
      label={<TranslatedText i18nKey="ActivityCard.Notifications.FlaggedComments" />}
      count={numFlags}
      icon={<i className="fa fa-flag" style={ICON_STYLES} />}
    />
  );
};

const FlaggedCommentsResolved = (activity: Activity) => {
  const numFlagsResolved = activityUtil.getNumFlagsResolved(activity);
  if (!numFlagsResolved) {
    return null;
  }
  return (
    <CardNotification
      status="resolved"
      label={<TranslatedText i18nKey="ActivityCard.Notifications.ResolvedFlags" />}
      count={numFlagsResolved}
      icon={<i className="fa fa-flag" style={ICON_STYLES} />}
    />
  );
};

const ActivityDisputes = (activity: Activity) => {
  const numDisputes = activityUtil.getNumDisputes(activity);
  if (!numDisputes) {
    return null;
  }
  return (
    <CardNotification
      status="warning"
      label={<TranslatedText i18nKey="ActivityCard.Notifications.GradeDisputes" />}
      count={numDisputes}
      icon={
        <img
          alt={localize({ message: 'ActivityCard.Notifications.DisputesAlt' })}
          src={RAISE_DISPUTE}
          style={ICON_STYLES}
        />
      }
    />
  );
};

const creationComment = ({
  creation,
  studentId,
  onClick,
}: {
  creation: Creation;
  studentId: string;
  onClick: () => void;
}) => {
  if (!creation || !creationStatusUtil.creationHasProfComment(creation)) {
    return null;
  }
  const isSeen = creation && creationStatusUtil.isStudentSeenComment({ creation, studentId });
  return (
    <CardNotification
      onClick={onClick}
      status={isSeen ? 'resolved' : 'comment'}
      label={<TranslatedText i18nKey="ActivityCard.Notifications.InstructorComment" />}
      icon={
        <img
          alt={localize({ message: 'ActivityCard.Notifications.CommentAlt' })}
          src={isSeen ? SEEN_COMMENT : RAISE_COMMENT}
          style={ICON_STYLES}
        />
      }
    />
  );
};

const CreationDispute = ({ creation }: { creation: Creation }) => {
  if (!creation) {
    return null;
  }
  const isDisputed = creationStatusUtil.isDispute(creation);
  if (isDisputed) {
    return (
      <CardNotification
        status="warning"
        label={<TranslatedText i18nKey="ActivityCard.Notifications.GradeDisputed" />}
        icon={
          <img
            alt={localize({ message: 'ActivityCard.Notifications.DisputesAlt' })}
            src={RAISE_DISPUTE}
            style={ICON_STYLES}
            data-testid="dispute-raised"
          />
        }
      />
    );
  }
  const isResolved = creationStatusUtil.isDisputeResolved(creation);
  if (isResolved) {
    return (
      <CardNotification
        status="success"
        label={<TranslatedText i18nKey="ActivityCard.Notifications.ResolvedGradeDispute" />}
        icon={
          <img
            alt={localize({ message: 'ActivityCard.Notifications.DisputesAlt' })}
            src={RESOLVE_DISPUTE}
            style={ICON_STYLES}
            data-testid="dispute-resolved"
          />
        }
      />
    );
  }
  return null;
};

const MissingGradeNotification = ({ numMissingGrades }: { numMissingGrades: number }) => {
  if (!numMissingGrades) {
    return null;
  }
  return (
    <CardNotification
      status="warning"
      label={<TranslatedText i18nKey="ActivityCard.Notifications.CreationsNotGraded" />}
      count={numMissingGrades}
      icon={<i className="fa fa-book" style={ICON_STYLES} />}
    />
  );
};

const pendingFinalizingNotification = () => {
  const renderLabel = () => {
    return (
      <div>
        <div>
          <b>
            <TranslatedText i18nKey="ActivityCard.Notifications.ReviewActivity" />
          </b>
        </div>
        <div>
          <TranslatedText i18nKey="ActivityCard.Notifications.ReviewActivityScores" />
        </div>
      </div>
    );
  };
  return (
    <CardNotification
      status="comment"
      label={renderLabel()}
      icon={<i className="fa fa-exclamation-circle" />}
    />
  );
};

function LateSubmissionNotification(props: {
  numPendingLateSubmissions: number;
  onClick: () => void;
}) {
  const { numPendingLateSubmissions, onClick } = props;
  return (
    <div onClick={onClick}>
      <CardNotification
        status="warning"
        label={
          <div>
            <div>
              {numPendingLateSubmissions}
              <TranslatedText
                i18nKey={
                  numPendingLateSubmissions > 1
                    ? 'ActivityCard.Notifications.LateSubmissions'
                    : 'ActivityCard.Notifications.LateSubmission'
                }
              />
            </div>
            <div>
              <TranslatedText i18nKey="ActivityCard.Notifications.NeedsYourAttention" />
            </div>
          </div>
        }
        count={numPendingLateSubmissions}
        icon={<i className="fa fa-exclamation-triangle" style={ICON_STYLES} />}
      />
    </div>
  );
}

function useRedirects({ router, isStudent }: { router: RouterProp; isStudent: boolean }) {
  if (isStudent) {
    return {
      creationComment: (courseId: string, activityId: string) => {
        router.push(`/course/${courseId}/assignment/${activityId}?activeTab=creation`);
      },
    };
  }
}

type CardNotificationListProps = {
  user: User;
  router: RouterProp;
  activity: Activity;
  creation: Creation;
  studentId: string;
  activityStats: { numPendingLateSubmissions: number };
  setCreationTableFilter: (filter: string) => object;
};
function CardNotificationList(props: CardNotificationListProps) {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();

  const redirects = useRedirects({ router: props.router, isStudent: isStudentInCourse });

  let notificationList: JSX.Element[] = [];
  if (isInstructorInCourse) {
    notificationList = [
      FlaggedComments(props.activity),
      FlaggedCommentsResolved(props.activity),
      ActivityDisputes(props.activity),
    ];
    if (activityStatusUtil.isGrading({ assignment: props.activity })) {
      notificationList.push(pendingFinalizingNotification());
    }
  } else if (isStudentInCourse) {
    const courseId: string = props.router.params.courseId;
    const activityId: string = props.activity._id;
    notificationList = [
      CreationDispute({ creation: props.creation }),
      creationComment({
        creation: props.creation,
        studentId: props.studentId,
        onClick: () => {
          redirects.creationComment(courseId, activityId);
        },
      }),
    ];
  }
  const renderNotifications = () => {
    return notificationList.map((notification: JSX.Element, index: number) => {
      if (notification) {
        return (
          <div key={`${notification}-${index}`} className="activity-card-notification-list__node">
            {notification}
          </div>
        );
      }
    });
  };
  const setCreationFilterToLateSubmission = () => {
    return props.setCreationTableFilter(FILTERS.LATE_CREATIONS);
  };
  return (
    <div className="activity-card-notification-list">
      {renderNotifications()}
      {props.activityStats && (
        <Access
          instructor={
            props.activityStats.numPendingLateSubmissions && (
              <LateSubmissionNotification
                numPendingLateSubmissions={props.activityStats.numPendingLateSubmissions}
                onClick={setCreationFilterToLateSubmission}
              />
            )
          }
        />
      )}
    </div>
  );
}

interface RootState {
  user: {
    authUser: AuthUser;
    student: {
      studentId: string;
    };
    assignment: Activity;
  };
}

interface mapStateReturn {
  user: User;
  studentId: string;
  activityStats: object;
}

const mapStateToProps = (state: RootState, ownProps: { activity: Activity }): mapStateReturn => {
  return {
    user: selectAuthUser(state),
    studentId: state.user.student.studentId,
    activityStats: ActivitySelector.selectActivityStatsById(state, ownProps.activity._id),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setCreationTableFilter,
  })(CardNotificationList)
);
