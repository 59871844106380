import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, ButtonContainer } from 'components/buttons';
import RadioMenu from 'components/core/form/RadioField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { updateCourse } from 'actions/courses';
import { getEdlinkCourses, redirectToEdlinkSSO } from 'actions/edlink';
import { getEdlinkUserAccount } from 'services/edlink';
import edlinkAction from 'actions/edlink/constants';
import { trackEvent } from 'utils/userEvents';
import { updateCourseMutation } from 'hooks/course';
import { Course, User } from '@kritik/types.generated';
import { localize } from 'locales';
import { AuthUser } from 'app-types';
import { getErrorMessageFromResponse } from 'utils/error';
import NoticeBoard from 'components/layout/NoticeBoard';

type LmsCourse = {
  authUser: AuthUser;
  courseName?: string;
  name?: string;
  lmsClassId?: string;
  courseId?: string;
  _id?: string;
  edlinkIntegrationId?: string;
};
type Props = {
  authUser: AuthUser;
  course: Course;
  open: boolean;
  close: () => void;
  getEdlinkCourses: (params: {
    userId: string;
    edlinkIntegrationId: string;
    courseId: string;
  }) => Promise<any>;
  isEdlinkIntegrated: boolean;
  userEmail: string;
};

function LmsCoursesModal(props: Props) {
  const STATUSES = {
    connecting: 'connecting',
    idle: 'idle',
    connectCourseSuccess: 'connectCourseSuccess',
    connectCourseFailure: 'connectCourseFailure',
    lmsConnectedSuccess: 'lmsConnectedSuccess',
    lmsConnectedFailure: 'lmsConnectedFailure',
    authenticate: 'authenticate',
    noLmsAccount: 'noLmsAccount',
    lmsBeingUsed: 'lmsBeingUsed',
  };

  const [courses, setCourses] = useState<LmsCourse[]>([]);
  const [integrations, setIntegrations] = useState<{ id: string; lms: String; name: string }[]>([]);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string>();
  const [optionValue, setOptionValue] = useState(null);
  const [status, setStatus] = useState(STATUSES.lmsConnectedSuccess);
  const updateCourse = updateCourseMutation();

  useEffect(() => {
    if (updateCourse.isSuccess) {
      setStatus(STATUSES.connectCourseSuccess);
    }

    if (updateCourse.isError) {
      if (
        getErrorMessageFromResponse((updateCourse.error as any).response) === 'LMSSettingBeingUsed'
      ) {
        setStatus(STATUSES.lmsBeingUsed);
      } else {
        setStatus(STATUSES.connectCourseFailure);
      }
    }
  }, [updateCourse.isSuccess, updateCourse.isError]);

  useEffect(() => {
    async function fetchCourses() {
      if (!selectedIntegrationId) {
        return;
      }
      const courseList = await getLmsCourseList(selectedIntegrationId);
      setCourses(courseList);
    }
    fetchCourses();
  }, [selectedIntegrationId]);

  const handleOnClose = () => {
    props.close();
  };

  const getLmsCourseList = async (edlinkIntegrationId: string) => {
    setStatus(STATUSES.connecting);
    let courseList: LmsCourse[] = [];
    const response = await props.getEdlinkCourses({
      userId: (props.course.user as User)._id,
      edlinkIntegrationId,
      courseId: props.course?._id,
    });
    if (!response.$error) {
      courseList = response;
    } else if (response.code === 403) {
      setStatus(STATUSES.authenticate);
      return [];
    }
    setStatus(STATUSES.idle);
    return courseList || [];
  };

  const onModalEnter = async () => {
    setCourses([]);
    setOptionValue(null);
    setStatus(STATUSES.lmsConnectedSuccess);
    const { integrations } = await getEdlinkUserAccount(props.userEmail);
    setIntegrations(integrations);
    if (integrations.length === 1) {
      setSelectedIntegrationId(integrations[0].id);
    }
  };

  const connectCourse = () => {
    setStatus(STATUSES.connecting);
    const data = {
      lms: {
        autoRosterSync: true,
        connectedCourseId: optionValue,
        connectedCourseName: getCourseName(),
        edlinkIntegrationId: getEdlinkIntegrationId(),
      },
      lmsClassId: getLmsClassId(),
    };
    updateCourse.mutate({ ...props.course, ...data });
  };

  const renderPermissionWarning = () => {
    const title = 'It looks like Kritik doesn’t have permission to connect courses from your LMS';
    const msg = 'Contact our customer support or your IT Administrator to resolve this issue';
    return (
      <div>
        {renderHeaders(title, msg)}
        <ButtonContainer>
          <Button type="secondary" onClick={handleOnClose}>
            Close
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const handleConnectCourse = () => {
    if (!optionValue) {
    } else {
      connectCourse();
    }
  };

  const renderHeaders = (title: string, msg: string) => {
    return (
      <div className="canvas-modal-headers">
        <h2 className="canvas-modal-headers--title" id="connect-course-to-lms">
          {title}
        </h2>
        <p className="canvas-modal-headers--message">{msg}</p>
      </div>
    );
  };

  const renderConnecting = () => {
    const lmsCourseName = getCourseName();
    let title = 'Setup: Connecting';
    let msg = `Connecting ${props.course.title} to ${lmsCourseName}. This may take a few moments`;
    if (!lmsCourseName) {
      title = 'Setup: Loading';
      msg = `This may take a few moments`;
    }

    return (
      <div>
        {renderHeaders(title, msg)}
        <LinearProgress color="secondary" variant="query" />
        <ButtonContainer className="canvas-modal__buttons">
          <Button type="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const getCourseName = () => {
    const course = courses.find((val) => {
      return val.courseId === optionValue || val._id === optionValue;
    });

    return course ? course.courseName || course.name : '';
  };

  const getLmsClassId = () => {
    const course = courses.find((val) => {
      return val.courseId === optionValue || val._id === optionValue;
    });

    return course ? course.lmsClassId || course.lmsClassId : null;
  };

  const getEdlinkIntegrationId = () => {
    const course = courses.find((val) => {
      return val.courseId === optionValue || val._id === optionValue;
    });

    return course?.edlinkIntegrationId;
  };

  const renderConnectSuccess = () => {
    const lmsCourseName = getCourseName();
    const title = 'Setup Complete';
    const msg = `${props.course.title} and ${lmsCourseName} have been successfully connected`;

    // TODO: Move this outside of rendering, to where we actually connect the LMS
    trackEvent('Course Connected to LMS', props.authUser, {
      courseName: props.course.title,
      institutionName: (props.course.institution as any)?.name,
      instructorName: props.authUser.email,
    });
    return (
      <div>
        {renderHeaders(title, msg)}
        <ButtonContainer className="canvas-modal__buttons">
          <Button
            type="secondary"
            onClick={handleOnClose}
            data-testid="close-success-lms-setup-button"
          >
            Close
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const handleAuthenticate = async () => {
    const response = await getEdlinkUserAccount(props.userEmail);
    if (Array.isArray(response.integrations) && response.integrations.length === 0) {
      return setStatus('noLmsAccount');
    }
    await redirectToEdlinkSSO({
      userIntegrationsList: response.integrations,
      userEmail: props.userEmail,
      role: 'teacher',
      action: edlinkAction.SSO_ACTION_CONNECT_COURSE,
      courseId: props.course._id,
    });
  };

  const renderAuthenticateInfo = () => {
    const title = 'You are not authenticated with your LMS';
    const msg =
      'We have detected that you are not authenticated with your LMS. Please authenticate first before connecting the course. You will be redirected to your LMS.';
    return (
      <div>
        {renderHeaders(title, msg)}
        <ButtonContainer className="canvas-modal__buttons">
          <Button type="primary" onClick={handleAuthenticate}>
            Authenticate
          </Button>
          <Button type="secondary" onClick={handleOnClose}>
            Close
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const renderNoLmsAccount = () => {
    const title = 'No LMS Account';
    const msg =
      'We could not detect that you have an LMS account. Please contact your administrator.';
    return (
      <div>
        {renderHeaders(title, msg)}
        <ButtonContainer className="canvas-modal__buttons">
          <Button type="secondary" onClick={handleOnClose}>
            Close
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const renderConnectFailure = () => {
    const lmsCourseName = getCourseName();
    const title = 'LMS Setup was unsuccessful';
    const msg = `We ran into an issue while trying to connect ${props.course.title} and ${lmsCourseName}`;
    return (
      <div>
        {renderHeaders(title, msg)}
        {updateCourse.error && (
          <NoticeBoard type="danger">
            {getErrorMessageFromResponse((updateCourse.error as any).response)}
          </NoticeBoard>
        )}
        <ButtonContainer className="canvas-modal__buttons">
          <Button type="primary" onClick={handleConnectCourse}>
            Retry
          </Button>
          <Button type="secondary" onClick={handleOnClose}>
            Close
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const renderLMSBeingUsedFailure = () => {
    const lmsCourseName = getCourseName();
    const title = 'LMS Setup was unsuccessful';
    const msg = `${lmsCourseName} is already being used in another course`;
    return (
      <div data-testid="lms-being-used-message">
        {renderHeaders(title, msg)}
        <ButtonContainer className="canvas-modal__buttons">
          <Button type="secondary" onClick={handleOnClose}>
            Close
          </Button>
        </ButtonContainer>
      </div>
    );
  };

  const handleCourseChange = (e: any) => {
    const value = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value);
    setOptionValue(value);
  };

  const renderCourseOptions = () => {
    if (selectedIntegrationId && courses.length === 0) {
      const title = 'Sorry, you have no courses to connect';
      const msg = 'Please check your LMS settings';
      return (
        <div>
          {renderHeaders(title, msg)}
          <ButtonContainer className="canvas-modal__buttons">
            <Button type="secondary" onClick={handleOnClose}>
              Close
            </Button>
          </ButtonContainer>
        </div>
      );
    }
    const getCourseOptions = () => {
      return courses.map((course, idx) => {
        return {
          value: course.courseId || course._id,
          label: course.courseName || course.name,
          testid: `option_${idx}`,
        };
      });
    };

    const title = 'Setup: Connect your course';
    const msg = `Choose the course you would like to sync with your Kritik course (${props.course.title}).`;
    return (
      <React.Fragment>
        {renderHeaders(title, msg)}
        <RadioMenu
          options={integrations.map((integration) => ({
            value: integration.id,
            label: `${integration.name} (${integration.lms})`,
          }))}
          value={selectedIntegrationId}
          onChange={(e: any) => setSelectedIntegrationId(e.target.value)}
          className="canvas-modal__course-options"
          legendText="Legend.Text.LMS.SelectIntegration"
          label={localize({ message: 'Legend.Text.LMS.SelectIntegration' })}
        />
        {courses.length > 0 && (
          <RadioMenu
            options={getCourseOptions()}
            value={optionValue}
            onChange={(e: any) => {
              return handleCourseChange(e);
            }}
            className="canvas-modal__course-options"
            legendText="Legend.Text.LMS.CourseSetup"
            label={localize({ message: 'Legend.Text.LMS.CourseSetup' })}
          />
        )}
        <ButtonContainer className="canvas-modal__buttons">
          <Button type="primary" testid="connect-course-button" onClick={handleConnectCourse}>
            Connect Course
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              return handleOnClose();
            }}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </React.Fragment>
    );
  };

  const renderContent = () => {
    switch (status) {
      case STATUSES.connecting:
        return renderConnecting();
      case STATUSES.connectCourseSuccess:
        return renderConnectSuccess();
      case STATUSES.connectCourseFailure:
        return renderConnectFailure();
      case STATUSES.lmsConnectedSuccess:
        return renderCourseOptions();
      case STATUSES.lmsConnectedFailure:
        return renderPermissionWarning();
      case STATUSES.authenticate:
        return renderAuthenticateInfo();
      case STATUSES.noLmsAccount:
        return renderNoLmsAccount();
      case STATUSES.lmsBeingUsed:
        return renderLMSBeingUsedFailure();
      default:
        return renderCourseOptions();
    }
  };

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      aria-labelledby="connect-course-to-lms"
      onEnter={onModalEnter}
      disableBackdropClick
    >
      <DialogContent>{renderContent()}</DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userEmail: state.user.authUser.email,
    authUser: state.user.authUser,
  };
};

export default connect(mapStateToProps, {
  updateCourse,
  getEdlinkCourses,
})(LmsCoursesModal);
