import { Component } from 'react';
import { connect } from 'react-redux';
import { createEvaluationFof } from 'actions/activity';
import { getAssignment } from 'selectors/activity';
import Button from 'components/buttons/Button';
import FOFSlider from 'components/Creation/Feedback/FOFSlider';
import * as localStorage from 'utils/localStorage';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormFieldTitle from 'components/core/form/Title';
import FormField from 'components/core/form/Field';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import InlineInformation from 'components/layout/InlineInformation';
import * as generalUtils from '@kritik/utils/general';
import { MINIMUM_WORD_COUNT } from '@kritik/constants/course';
import { withUserRole } from 'utils/withUserRole';
import { UserRoleInCourse } from 'app-types';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

type OwnEditState = any;

type EditState = OwnEditState & typeof Edit.defaultProps;

class Edit extends Component<
  {
    userRole: UserRoleInCourse;
  },
  EditState
> {
  static defaultProps = {
    onCancel: null,
  };

  localInterval: any;

  constructor(props) {
    super(props);
    this.localInterval = null;
    this.state = {
      critical: 0,
      motivational: 0,
      comment: '',
      submitted: false,
    };
  }

  componentDidMount() {
    if ((this.props as any).evaluation.feedbackOnFeedback.motivational !== 0) {
      this.setState({
        motivational: (this.props as any).evaluation.feedbackOnFeedback.motivational,
        critical: (this.props as any).evaluation.feedbackOnFeedback.critical,
        comment: (this.props as any).evaluation.feedbackOnFeedback.comment,
      });
    }
    if (this.props.userRole.isStudentInCourse) {
      const currentContent = localStorage.getText((this.props as any).evaluation._id);
      if (currentContent && currentContent.submitted === false) {
        this.setState({ comment: currentContent.text });
      }
      this.localInterval = setInterval(() => {
        const content = {
          submitted: false,
          text: this.state.comment,
        };
        localStorage.setText((this.props as any).evaluation._id, content);
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.localInterval);
  }

  onCancelEdit() {
    this.clearLocalStorage();
    return (this.props as any).onCancel();
  }

  onSubmit = () => {
    const fofData = {
      critical: parseInt(this.state.critical, 10),
      motivational: parseInt(this.state.motivational, 10),
      comment: this.state.comment,
      createdAt: new Date(Date.now()),
    };
    const MIN_COMMENT_LENGTH = MINIMUM_WORD_COUNT;
    const _comment = generalUtils.getWordCountFromHTML(this.state.comment);
    const commentTooShort = _comment < MIN_COMMENT_LENGTH;
    const fofOptions = [1, 2, 3, 4];

    if (
      fofOptions.indexOf(this.state.critical) === -1 ||
      fofOptions.indexOf(this.state.motivational) === -1
    ) {
      return this.setState({
        error: localize({ message: 'Creation.Feedback.Edit.Error.SelectPoint' }),
      });
    }

    if ((this.props as any).activity.settings?.feedbackCommentsRequired && commentTooShort) {
      return this.setState({
        error: localize({
          message: 'Creation.Feedback.Edit.Error.CommentTooShort',
          options: { minWordCount: MINIMUM_WORD_COUNT },
        }),
      });
    }

    (this.props as any)
      .createEvaluationFof({
        assignmentId: (this.props as any).submission.assignment,
        submissionId: (this.props as any).submission._id,
        scoreId: (this.props as any).evaluation._id,
        feedbackOnFeedback: fofData,
      })
      .then(() => {
        this.clearLocalStorage();
        // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
        return this.props.onSubmit();
      })
      .catch((err: any) => {
        this.setState({ error: err.errors?.message || err.message });
      });
  };

  onFOFGraphChange = (e: any) => {
    const category = e.currentTarget.name;
    const { value } = e.currentTarget;
    if (!this.state.submitted) {
      this.setState({ [category]: value, error: null });
    }
  };

  getErrors() {
    if (!this.state.error) {
      return null;
    }
    return (
      <InlineInformation
        testid="save-feedback-error"
        className="evaluation-editor__section__word-count"
        type="danger"
      >
        {this.state.error}
      </InlineInformation>
    );
  }

  clearLocalStorage = () => {
    localStorage.removeText((this.props as any).evaluation._id);
  };

  render() {
    return (
      <>
        <FormFieldTitle
          size="lg"
          label={localize({ message: 'Creation.Feedback.Edit.YourFeedback' })}
        />
        <FormField>
          <FOFSlider
            critical={this.state.critical}
            motivational={this.state.motivational}
            onChange={this.onFOFGraphChange}
            commentsRequired={(this.props as any).activity.settings?.feedbackCommentsRequired}
          />
        </FormField>
        <FormRichTextEditor
          label={
            (this.props as any).activity.settings?.feedbackCommentsRequired ? (
              <TranslatedText i18nKey="Creation.Feedback.Edit.WrittenFeedbackRequired" />
            ) : (
              <TranslatedText i18nKey="Creation.Feedback.Edit.WrittenFeedbackOptional" />
            )
          }
          value={this.state.comment}
          onChange={(comment: any) => {
            this.setState({ comment, error: null });
          }}
          testid="feedback-editor"
        />
        <div className="word-count-container">
          <TranslatedText
            i18nKey="Creation.Feedback.Edit.WordCount"
            values={{ wordCount: generalUtils.getWordCountFromHTML(this.state.comment).toString() }}
          />
        </div>
        <FormSubmitButtons errors={this.getErrors()}>
          <Button
            data-testid="save-feedback"
            type="primary"
            onClick={() => {
              return this.onSubmit();
            }}
          >
            <TranslatedText i18nKey="SubmitAssignment" />
          </Button>
          {(this.props as any).onCancel && (
            <Button
              type="secondary"
              onClick={() => {
                this.onCancelEdit();
              }}
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          )}
        </FormSubmitButtons>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default withUserRole(
  connect(mapStateToProps, {
    createEvaluationFof,
  })(Edit)
);
