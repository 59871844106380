import App from './app';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserHistory } from 'history';
import { RouterProvider } from 'react-router-dom';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';

import createRouter from 'router';
import configureStore from 'store/configureStore';
import hyperDxInit from './hyperDxInit';
import ConfigService from 'services/config';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import ErrorPageTemplate from 'components/ErrorPage/ErrorPageTemplate';

import { i18nInstance, localize } from './locales';
import { GrowthBookProviderMain } from 'context/growthbookContext';
import MaintenancePageTemplate from 'containers/Maintenance';
import { Snackbar } from 'components/core/Snackbar';

new ConfigService().get().then(({ data: { config, user } }: any) => {
  // Init Application
  (window as any).App = new App(config);

  if (user?.preferredLanguage) {
    i18nInstance.changeLanguage(user.preferredLanguage);
  }

  const history = createBrowserHistory();

  const store = configureStore(
    {
      user: {
        authenticated: Boolean(user),
        isWaiting: false,
        errors: [],
        authUser: user,
      },
    },
    history
  );

  hyperDxInit(store.getState());

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 15000,
        retry: false,
      },
    },
  });

  const container = document.getElementById('app');
  const root = createRoot(container);
  const router = createRouter(store);
  window.router = router;
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  if (window?.App?.config.get('maintenanceMode')) {
    root.render(
      <MaintenancePageTemplate
        icon="🚧"
        title="Kritik is currently under maintenance"
        descriptions={["We'll be back soon!"]}
        iconVariant="yellow"
      />
    );
  } else {
    root.render(
      <ErrorBoundary
        fallback={(error) => (
          <ErrorPageTemplate
            icon="🚒"
            title={localize({ message: 'Error.SomethingWrongHappened' })}
            buttonText={localize({ message: 'Error.ErrorBoundaryButtonText' })}
            descriptions={[
              localize({ message: 'Error.ErrorBoundaryText1' }),
              localize({ message: 'Error.ErrorBoundaryText2' }),
            ]}
            iconVariant="red"
            authUser={user.authUser}
          />
        )}
      >
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          Components={{
            success: Snackbar,
            error: Snackbar,
            info: Snackbar,
            warning: Snackbar,
          }}
        />
        <I18nextProvider i18n={i18nInstance}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <GrowthBookProviderMain>
                <RouterProvider router={router} />
              </GrowthBookProviderMain>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </I18nextProvider>
      </ErrorBoundary>
    );
  }
});
