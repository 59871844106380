import { courseService } from 'services/index';
import { useParams } from 'react-router';
import { withRouter } from 'utils/withRouter';
import Table from 'components/layout/SimpleTable';
import TableBody from 'components/layout/SimpleTable/Body';
import TableRow from 'components/layout/SimpleTable/Row';
import TableCell from 'components/layout/SimpleTable/Cell';
import { useFetchCourseCollaborators, useGetCachedCourse, useUserRoleInCourse } from 'hooks/course';
import { InlineInformation } from 'components/layout';
import { isCourseConnected } from 'utils/lms';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { localize } from 'locales';
import CollaboratorInvitationMenu from 'components/Course/CollaboratorsPanel/InvitationMenu';
import RemoveCollaboratorMenu from 'components/Course/CollaboratorsPanel/RemoveMenu';
import { TranslatedText } from 'components/TranslatedText';

const Collaborators = ({ router }) => {
  const { courseId } = useParams();
  const course = useGetCachedCourse(courseId);
  const { isOwnerInCourse } = useUserRoleInCourse();
  const isCourseLMSConnected = isCourseConnected(course);

  const { data: collaborators, isLoading: isLoadingCollaborators } =
    useFetchCourseCollaborators(courseId);

  if (!course || isLoadingCollaborators) {
    return;
  }

  const getStatus = (status) => {
    switch (status) {
      case 'removed':
        return localize({ message: 'RosterManager.CollaboratorsTab.CollaboratorStatus.Removed' });
      case 'enrolled':
        return localize({ message: 'RosterManager.CollaboratorsTab.CollaboratorStatus.Enrolled' });
      case 'pending':
        return localize({ message: 'RosterManager.CollaboratorsTab.CollaboratorStatus.Pending' });
    }
  };

  const handleReinviteCollaborator = async ({ collaboratorEmail }) => {
    return await courseService().reinviteCollaborators({ courseId, collaboratorEmail });
  };

  if (collaborators.length > 0) {
    return (
      <div className="roster-container">
        {isCourseConnected(course) && (
          <InlineInformation type="information">
            {localize({
              message: 'RosterManager.ConnectedCourse.CollaboratorsTab.PreventMismatch',
            })}
          </InlineInformation>
        )}
        <Table renderSeparator={false}>
          <thead>
            <TableRow className="collaborator-list__header">
              <TableCell>
                <b>
                  {localize({
                    message: 'RosterManager.CollaboratorsTab.CollaboratorTable.Name',
                  })}
                </b>
              </TableCell>
              <TableCell>
                <b>
                  {localize({
                    message: 'RosterManager.CollaboratorsTab.CollaboratorTable.Email',
                  })}
                </b>
              </TableCell>
              <TableCell>
                <b>
                  {localize({
                    message: 'RosterManager.CollaboratorsTab.CollaboratorTable.Status',
                  })}
                </b>
              </TableCell>
            </TableRow>
          </thead>
          <TableBody>
            {collaborators.map((collaborator: any) => {
              return (
                <TableRow
                  key={collaborator.email}
                  className="collaborator-row"
                  testid={`collaborator-${collaborator.email}`}
                >
                  <TableCell>
                    <div className="student-list__row-name">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AvatarDisplay
                          user={{ profile: { picture: collaborator.picture } }}
                          size="50px"
                          style={{ marginRight: 5 }}
                          showBorder={false}
                          hoverable={false}
                          anonymous={false}
                        />
                        <p>
                          {collaborator.firstName} {collaborator.lastName}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{collaborator.email}</TableCell>
                  <TableCell>{getStatus(collaborator.enrollmentStatus)}</TableCell>
                  <TableCell>
                    {collaborator.enrollmentStatus === 'pending' && (
                      <CollaboratorInvitationMenu
                        collaboratorEmail={collaborator.email}
                        handleReinviteCollaborator={handleReinviteCollaborator}
                      />
                    )}
                    {collaborator.enrollmentStatus === 'enrolled' &&
                      !isCourseLMSConnected &&
                      isOwnerInCourse && (
                        <RemoveCollaboratorMenu collaboratorEmail={collaborator.email} />
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (isCourseConnected(course)) {
    return (
      <div className="roster-container">
        <h3>
          {localize({
            message: 'RosterManager.ConnectedCourse.CollaboratorsTab.Title',
          })}
        </h3>
        <TranslatedText i18nKey="RosterManager.ConnectedCourse.CollaboratorsTab.Content" />
        <InlineInformation type="information">
          <p>
            <b>
              {localize({
                message: 'RosterManager.ConnectedCourse.CollaboratorsTab.Info.Title',
              })}
            </b>
          </p>
          {localize({
            message: 'RosterManager.ConnectedCourse.CollaboratorsTab.Info.Content',
          })}
        </InlineInformation>
      </div>
    );
  }

  return (
    <div className="roster-container">
      <h3>
        {localize({
          message: 'RosterManager.CollaboratorsTab.Title',
        })}
      </h3>
      <p>
        <TranslatedText i18nKey="RosterManager.CollaboratorsTab.Content1" />
      </p>
      <InlineInformation type="information">
        <b>
          {localize({
            message: 'RosterManager.CollaboratorsTab.Info.Title',
          })}
        </b>
        <p>
          {localize({
            message: 'RosterManager.CollaboratorsTab.Info.Content',
          })}
        </p>
      </InlineInformation>
    </div>
  );
};

export default withRouter(Collaborators);
