import { useState } from 'react';
import { connect } from 'react-redux';
import { InlineInformation } from 'components/layout';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import FormTextInput from 'components/core/form/TextField';
import FormRadio from 'components/core/form/RadioField';
import { Button } from 'components/buttons';
import FormTitle from 'components/core/form/Title';
import { assignmentService } from 'services';
import * as ErrorUtils from 'utils/error';

const ActivitySettingsContainer = () => {
  const [activityId, setActivityId] = useState(null);
  const [activity, setActivity] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSetActivityId = (e: any) => {
    setError(null);
    setActivity(null);
    setActivityId(e.target.value);
  };

  const handleGetActivitySettings = async () => {
    if (!activityId) {
      return setError('You must provide an Activity ID');
    }
    setLoading(true);
    try {
      const response = await assignmentService().get({ id: activityId });
      setActivity(response.data);
      setLoading(false);
    } catch (err) {
      const _error = ErrorUtils.getErrorMessageFromResponse(err);
      setError(_error);
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Activity Settings" />
      <FormTextInput
        autoFocus
        name="activityId"
        label="Please enter Activity ID"
        value={activityId}
        onChange={handleSetActivityId}
      />
      {error && (
        <InlineInformation type="danger" style={{ marginBottom: '20px' }}>
          <div>{error}</div>
        </InlineInformation>
      )}
      {activity ? (
        <UpdateActivitySettings activityId={activityId} activity={activity} />
      ) : (
        <Button
          onClick={async () => {
            await handleGetActivitySettings();
          }}
          loading={loading}
          type="primary"
        >
          Get Activity
        </Button>
      )}
    </PageContainer>
  );
};

const UpdateActivitySettings = ({ activityId, activity }: any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [commentsRequired, setCommentsRequired] = useState(
    `${activity.settings?.feedbackCommentsRequired ?? false}`
  );

  const handleUpdateActivitySettings = async () => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      await assignmentService().update({
        id: activityId,
        data: {
          ...activity,
          settings: {
            feedbackCommentsRequired: commentsRequired === 'true',
          },
        },
      });
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      const _error = ErrorUtils.getErrorMessageFromResponse(err);
      setLoading(false);
      setError(_error);
    }
  };

  return (
    <>
      <InlineInformation type="success">
        {`You are updating settings for activity "${activity.title}"`}
      </InlineInformation>
      <FormTitle label="FoF Comments required" />
      <FormRadio
        options={[
          { value: 'true', label: 'Required comments' },
          { value: 'false', label: 'Optional comments' },
        ]}
        onChange={(e: any) => {
          setSuccess(false);
          setError(false);
          setCommentsRequired(e.target.value);
        }}
        value={commentsRequired}
        disabled
        type="button"
        legendText="Legend.Text.Admin.UpdateActivitySettings"
      />
      {success && (
        <InlineInformation type="success">
          {`Activity "${activity.title}" was successfully updated`}
        </InlineInformation>
      )}
      {error && (
        <InlineInformation type="danger">
          {`Activity "${activity.title}" could not be updated`}
        </InlineInformation>
      )}
      <Button
        onClick={async () => {
          await handleUpdateActivitySettings();
        }}
        loading={loading}
        type="primary"
        className="update-activity"
      >
        Update Activity Settings
      </Button>
    </>
  );
};

export default connect(null)(ActivitySettingsContainer);
