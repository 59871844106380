import * as studentUtil from 'utils/student';
import { simplePercent } from '@kritik/utils/format';
import {
  isFinalized,
  getEvaluationStage,
  studentParticipatesInStageFromInstructorView,
} from '@kritik/utils/stage';
import * as GradeHistoryUtils from '@kritik/utils/gradeHistory';
import { isGroupPresentationActivity, isPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales';

const EvaluationScoreCell = (props: any) => {
  const { activity, student, course, isGroupItem } = props;

  const evaluationStage = getEvaluationStage(activity);
  if (isGroupPresentationActivity(activity) && isGroupItem) {
    return localize({ message: 'Activity.StudentTable.Cell.NA' });
  }
  if (
    isPresentationActivity(activity) &&
    !studentParticipatesInStageFromInstructorView({
      stage: evaluationStage,
      studentId: student._id,
    })
  ) {
    return localize({ message: 'Activity.StudentTable.Cell.NA' });
  }

  const gradeHistory = GradeHistoryUtils.getStudentActivityGradeHistory(
    student.gradeHistories,
    activity._id
  );

  if (!gradeHistory && !isFinalized({ assignment: activity })) {
    return '-';
  }
  const evaluationScore =
    props.evaluationScore || props.evaluationScore === 0
      ? props.evaluationScore
      : studentUtil.getEvaluationScoreByActivity(student, activity, course);
  return simplePercent(evaluationScore);
};

export default EvaluationScoreCell;
