import { useState } from 'react';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { changeLeader } from 'actions/groups';
import AssignNewGroupModal from './AssignNewGroupModal';
import { useUserRoleInCourse } from 'hooks/course';
import { Group, Student } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';

const mapStateToProps = (state: any) => {
  return {};
};

type MemberMenuProps = {
  canMoveToDifferentGroup: boolean;
  changeLeader: ({ groupId, leaderId }: { groupId: string; leaderId: string }) => void;
  group: Group;
  groups: Group[];
  member: Student;
  testid: string;
};
const MemberMenu = ({
  canMoveToDifferentGroup,
  changeLeader,
  group,
  groups,
  member,
  testid,
}: MemberMenuProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isInstructorInCourse } = useUserRoleInCourse();
  const [showMoveStudentModal, setShowMoveStudentModal] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isInstructorInCourse) {
    return null;
  }

  return (
    <div className="group-member-menu">
      <button
        className="group-member-menu__switch"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="group-ellipsis"
      >
        <i className="fas fa-ellipsis-v" />
      </button>
      <Menu
        id="simple-menu"
        className="group-member-menu__popup"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid={`${testid}-popup`}
      >
        {group && (
          <MenuItem
            className="group-member-menu__popup-item"
            data-testid={open ? 'make-leader-button' : ''}
            onClick={() => {
              changeLeader({ groupId: group._id, leaderId: member._id });
              handleClose();
            }}
          >
            <TranslatedText i18nKey="RosterManager.GroupTab.GroupManager.MakeLeader" />
          </MenuItem>
        )}
        {canMoveToDifferentGroup && (
          <MenuItem
            className="group-member-menu__popup-item"
            data-testid="move-group-button"
            onClick={() => {
              setShowMoveStudentModal(true);
            }}
          >
            <TranslatedText i18nKey="RosterManager.GroupTab.GroupManager.MoveToGroup" />
          </MenuItem>
        )}
      </Menu>
      {showMoveStudentModal && (
        <AssignNewGroupModal
          groups={groups}
          onClose={() => setShowMoveStudentModal(false)}
          memberId={member._id}
          currentGroupId={group?._id}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, {
  changeLeader,
})(MemberMenu);
