import FormTextInput from 'components/core/form/TextField';
import { Field } from 'react-final-form';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { localize } from 'locales';

const CourseNameField = ({ label = localize({ message: 'CourseNameField.DefaultLabel' }) }) => {
  return (
    <Field
      required
      name={COURSE_FIELD_NAMES.COURSE_NAME}
      type="text"
      validate={validators.composeValidators(validators.isRequired, validators.minLength(3))}
    >
      {({ input, meta }) => {
        return (
          <FormTextInput
            isRequired
            label={label}
            value={input.value}
            placeholder={localize({ message: 'Course.Create.CourseNameField.Placeholder' })}
            onChange={input.onChange}
            error={meta.touched && localize({ message: 'Course.Create.CourseNameField.Required' })}
            testid="course-name"
            id="course-name"
          />
        );
      }}
    </Field>
  );
};

export default CourseNameField;
