import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PanelDisplay from 'components/layout/PanelDisplay';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import Button from 'components/buttons/Button';
import {
  getStudentFromUser,
  selectCurrentStudent,
  selectStudentScoreHistories,
} from 'selectors/student';
import { getAssignment } from 'selectors/activity';
import RANK_STAR_UP from 'images/rank-star-up.svg';
import RANK_STAR_DOWN from 'images/rank-star-down.svg';
import { selectUserFromStudent } from 'selectors/user';
import { getGradingScore } from './utils';
import { getRankLevelChange, useGetRankLevelChange } from 'hooks/useRankLevel';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';
import { getCourse } from 'selectors/course';

const generateRankLevelChangeLabel = (rankLevelChange: any) => {
  if (rankLevelChange > 0) {
    return `+${rankLevelChange}`;
  }
  if (rankLevelChange < 0) {
    return rankLevelChange;
  }
};

function RankChangeStarLabel({ scoreHistories, activity }: any) {
  const rankLevelChange = getRankLevelChange(scoreHistories, activity);

  const rankLevelChangeLabel = generateRankLevelChangeLabel(rankLevelChange);
  const containerClassNames = classNames(
    'rank-label',
    { 'rank-label--up': rankLevelChange > 0 },
    { 'rank-label--down': rankLevelChange < 0 },
    { 'rank-label--no-change': rankLevelChange === 0 },
    { 'an-star-bounce': rankLevelChange !== 0 }
  );
  const imageClassNames = classNames(
    { 'rank-up-star-img': rankLevelChange > 0 },
    { 'rank-down-star-img': rankLevelChange < 0 }
  );
  return (
    <div className={containerClassNames}>
      {rankLevelChange === 0 ? (
        <TranslatedText i18nKey="GradingChangeSideBox.RankChangeStarLabel.Accomplished" />
      ) : (
        <React.Fragment>
          <span className="rank-label-text">{rankLevelChangeLabel}</span>
          <img
            className={imageClassNames}
            src={rankLevelChange > 0 ? RANK_STAR_UP : RANK_STAR_DOWN}
            width="25px"
            height="25px"
            aria-hidden="true"
          />
        </React.Fragment>
      )}
    </div>
  );
}

const renderGradingPowerInformation = () => {
  return (
    <div>
      <h3 className="side-box__header" data-testid="grading-power-adjustment-header">
        <TranslatedText i18nKey="GradingChangeSideBox.GradingPowerAdjustment.Header" />
      </h3>
      <div data-testid="grading-power-adjustment-description">
        <TranslatedText i18nKey="GradingChangeSideBox.GradingPowerAdjustment.Description" />
      </div>
      <a
        className="side-box__learn-more-button"
        href="https://help.kritik.io/en/articles/6845495-grading-power-and-score-calculation-in-kritik"
        target="_blank"
        data-testid="learn-more-grading-power"
      >
        <TranslatedText i18nKey="LearnMore" />
        <i className="fa fa-external-link" aria-hidden="true"></i>
      </a>
    </div>
  );
};

function GradingChangeSideBox(props: any) {
  const { scoreHistories, activity, user } = props;
  const rankChangeTextLabel = useGetRankLevelChange(scoreHistories, activity);
  const gradingScore = getGradingScore({
    scoreHistories,
    activity,
  });
  return (
    <PanelDisplay className="calibration-box-container">
      <div className="side-box__rankChange">
        <AvatarDisplay user={user} myUser={user} kritikScore={gradingScore} showName={false} />
      </div>
      <RankChangeStarLabel scoreHistories={scoreHistories} activity={activity} />
      <div className="side-box__rankChangeLabel" data-testid="rank-change-label">
        {rankChangeTextLabel}
      </div>
      {renderGradingPowerInformation()}
    </PanelDisplay>
  );
}

const mapStateToProps = (state: any) => {
  const course = getCourse(state);
  const student =
    course.userRole === 'student'
      ? getStudentFromUser(state, state.selected.courseId)
      : selectCurrentStudent(state);
  return {
    user: selectUserFromStudent(state, student._id),
    activity: getAssignment(state, state.selected.assignmentId),
    student,
    scoreHistories: selectStudentScoreHistories(state, student._id),
  };
};

export default connect(mapStateToProps)(GradingChangeSideBox);
