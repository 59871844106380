import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonContainer } from 'components/buttons';
import { selectAuthUser } from 'selectors/user';
import { getAssignment } from 'selectors/activity';
import { withRouter } from 'utils/withRouter';
import EvaluationComment from 'components/CommentOnContent/EvaluationComment';
import * as CreationEvaluationUtil from '@kritik/utils/creation/evaluation';
import { isPresentationActivity } from '@kritik/utils/activity';
import { getCourse } from 'selectors/course';
import RemoveEvaluation from './RemoveEvaluation';
import RestoreEvaluation from './RestoreEvaluation';
import { isCommentNotFlagged } from '@kritik/utils/creation/status';
import InstructorFlagMenu from 'components/Creation/Flag/InstructorMenu';
import { localize } from 'locales';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import Tooltip from '@material-ui/core/Tooltip';
import { useUserRoleInCourse } from 'hooks/course';

const EvaluationOptions = (props: any) => {
  const [isEditingComment, setIsEditingComment] = useState(false);

  return (
    <>
      {props.children}
      <EvaluationActions
        user={props.user}
        evaluation={props.evaluation}
        spotlight={props.spotlight}
        activity={props.activity}
        course={props.course}
        handleSetIsEditingComment={() => {
          setIsEditingComment(true);
        }}
        isEditingComment={isEditingComment}
        creation={props.creation}
      />
      {isEditingComment && (
        <div className="evaluation-options__field">
          <EvaluationComment
            creation={props.creation}
            activityId={props.activity._id}
            creationUserId={props.creation.user._id}
            evaluation={props.evaluation}
            isInstructor
            onClose={() => {
              setIsEditingComment(false);
            }}
          />
        </div>
      )}
    </>
  );
};

const EvaluationActions = ({
  user,
  evaluation,
  activity,
  course,
  spotlight,
  handleSetIsEditingComment,
  isEditingComment,
  creation,
}) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (!isInstructorInCourse) {
    return null;
  }

  // If comment was flagged, we display the Resolve menu instead.
  const displayRemoveEvaluationOption = isCommentNotFlagged(evaluation);

  return (
    <ButtonContainer className="evaluation-options__field">
      {!CreationEvaluationUtil.scoreHasTeacherComment(evaluation) && (
        <Button
          type="secondary"
          onClick={handleSetIsEditingComment}
          disabled={isEditingComment}
          unavailable={isEditingComment}
          data-testid="comment-on-evaluation"
        >
          {localize({ message: 'Creation.Evaluation.Action.Comment' })}
        </Button>
      )}
      <SpotlightButton
        evaluation={evaluation}
        spotlight={spotlight}
        activity={activity}
        course={course}
      />
      {displayRemoveEvaluationOption ? (
        EvaluationScoreUtil.isEvaluationRemoved(evaluation) ? (
          <RestoreEvaluation
            evaluation={evaluation}
            ActionComponent={Button}
            actionTitle={localize({ message: 'Creation.Evaluation.Action.Restore' })}
            creation={creation}
            isInappropriate={false}
          />
        ) : (
          <RemoveEvaluation
            evaluation={evaluation}
            ActionComponent={Button}
            actionTitle={localize({ message: 'Creation.Evaluation.Action.Remove' })}
            creation={creation}
            isInappropriate={false}
          />
        )
      ) : null}
      <InstructorFlagMenu evaluation={evaluation} creation={creation} />
    </ButtonContainer>
  );
};

const SpotlightButton = withRouter(({ evaluation, spotlight, activity, course, router }) => {
  if (!CreationEvaluationUtil.evaluationHasComment(evaluation) || spotlight) {
    return null;
  }

  return (
    <Tooltip title={localize({ message: 'Creation.Evaluation.Tooltip.Spotlight' })} placement="top">
      <Button
        onClick={() => {
          const url = `/course/${course._id}/assignment/${activity._id}/spotlights/create?type=Evaluation&referenceId=${evaluation._id}`;
          router.push(url);
        }}
        type="secondary"
        data-testid="spotlight-evaluation"
      >
        <i className="fa fa-lightbulb-o button-padding-right" aria-hidden="true"></i>{' '}
        {localize({ message: 'Creation.Evaluation.Action.Spotlight' })}
      </Button>
    </Tooltip>
  );
});

const mapStateToProps = (state: any) => {
  return {
    user: selectAuthUser(state),
    course: getCourse(state),
    activity: getAssignment(state),
  };
};

export default withRouter(connect(mapStateToProps)(EvaluationOptions));
