import Typography from 'components/core/Typography';
import FormField from 'components/core/form/Field';
import NoticeBoard from 'components/layout/NoticeBoard';
import Checkbox from 'components/core/input/Checkbox';
import * as formatUtils from '@kritik/utils/format';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

export const MODAL_ID = 'student-invite-modal';

const PricingPlan = (props: any) => {
  const getCoursePrice = () => {
    return props.course.price / 100;
  };

  const renderCoursePrice = () => {
    const { currency } = props.institution;
    const coursePrice = formatUtils.displayCurrencyPrice(getCoursePrice(), currency);
    return (
      <Typography weight={500}>
        <TranslatedText i18nKey="PricingPlan.PricePerStudent" values={{ coursePrice }} />
      </Typography>
    );
  };

  return (
    <NoticeBoard type="information" title={localize({ message: 'PricingPlan.Title' })}>
      <p>
        <TranslatedText i18nKey="PricingPlan.CurrentTermPrice" /> {renderCoursePrice()}
      </p>
      {props.course.price === 0 ? (
        <p>{localize({ message: 'PricingInvite.Modal.Zero' })}</p>
      ) : (
        <FormField>
          <Checkbox
            testid="aware-kritik-will-bill-students"
            onChange={props.onChange}
            isChecked={props.isChecked}
            label={localize({ message: 'PricingInvite.Modal.CheckboxLabel' })}
          />
        </FormField>
      )}
    </NoticeBoard>
  );
};

export default PricingPlan;
