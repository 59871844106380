import PageHeader from 'components/layout/PageHeader';
import { Divider } from '@material-ui/core';
import ActivityType from 'containers/assignments/overview/Type';
import { TranslatedText } from 'components/TranslatedText';

function CourseTitleAndSection(props: { course: any }) {
  const { title, section } = props.course;
  return (
    <span className="scheduler__course-title">
      {title || ''}
      {section && ` [${section}]`}
    </span>
  );
}

type HeaderProps = {
  activity: any;
  course: any;
};

function Header(props: HeaderProps) {
  return (
    <>
      <CourseTitleAndSection course={props.course} />
      <PageHeader title={<TranslatedText i18nKey="Activity.Schedule.PageHeader" />} />
      <Divider />
      <ActivityType type={props.activity?.activityType} className="scheduler__activity-type" />
    </>
  );
}

export default Header;
