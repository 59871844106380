import React from 'react';
import { connect } from 'react-redux';
import * as activityUtils from '@kritik/utils/activity';
import DateCell from 'components/CreationList/StatusCells/Date';
import StudentInfoCell from 'components/CreationList/StatusCells/StudentInfo';
import localUtil from 'components/CreationList/util';
import { getAssignment } from 'selectors/activity';
import Cell from 'components/CreationList/Body/Cell';
import TableRow from 'components/CreationList/Body/Row';
import ToggleCell from 'components/CreationList/Body/ToggleCell';
import { selectCreationTableListBy } from 'redux/creationTable/select';
import { navigateToInstructorCreationView } from 'actions/activity';
import { selectStudent } from 'reducers/selected';
import { selectSubmission } from 'actions/select';
import { Activity } from '@kritik/types.generated';
import momentTZ from 'moment-timezone';
import { localize } from 'locales';

const getDisputeStatus = (status) => {
  switch (status) {
    case 'Resolved':
      return localize({ message: 'DisputeTable.Row.Status.Resolved' });
    case 'Dispute':
      return localize({ message: 'DisputeTable.Row.Status.Open' });
    default:
      return '-';
  }
};

const GroupRows = ({
  columns,
  creation,
  listBy,
  toggleGroupMembers,
  parentId,
  isExpand,
  activity,
}: {
  columns: Array<Column>;
  creation: Creation;
  listBy: any;
  toggleGroupMembers: any;
  parentId: any;
  isExpand: boolean;
  activity?: Activity & { user: { institution: { identifier: string } } };
}) => {
  const timezone = activity?.user.institution.identifier;

  const disputedOnDate = creation?.dispute.disputedOn
    ? momentTZ(creation?.dispute.disputedOn).tz(timezone)
    : null;
  const disputeResolveDate = creation?.dispute.resolvedOn
    ? momentTZ(creation?.dispute.resolvedOn).tz(timezone)
    : null;

  return (
    <React.Fragment>
      <Cell column={columns[1]}>{getDisputeStatus(creation?.dispute.status)}</Cell>
      <Cell column={columns[2]}>
        <DateCell date={disputedOnDate} />
      </Cell>
      <Cell column={columns[3]}>
        <DateCell date={disputeResolveDate} />
      </Cell>
      {localUtil.isGroupItem({ parentId }, listBy) && (
        <ToggleCell column={columns[4]} onClick={toggleGroupMembers} isExpand={isExpand} />
      )}
    </React.Fragment>
  );
};

const IndividualRows = ({
  columns,
  creation,
  activity,
}: {
  columns: Array<Column>;
  creation: Creation;
  activity?: Activity & { user: { institution: { identifier: string } } };
}) => {
  const timezone = activity?.user.institution.identifier;

  const disputedOnDate = creation?.dispute.disputedOn
    ? momentTZ(creation?.dispute.disputedOn).tz(timezone)
    : null;
  const disputeResolveDate = creation?.dispute.resolvedOn
    ? momentTZ(creation?.dispute.resolvedOn).tz(timezone)
    : null;

  return (
    <React.Fragment>
      <Cell column={columns[1]}>{getDisputeStatus(creation?.dispute.status)}</Cell>
      <Cell column={columns[2]}>
        <DateCell date={disputedOnDate} />
      </Cell>
      <Cell column={columns[3]}>
        <DateCell date={disputeResolveDate} />
      </Cell>
    </React.Fragment>
  );
};

const GroupMemberRow = ({
  columns,
  creation,
  activity,
}: {
  columns: Array<Column>;
  creation: Creation;
  activity?: Activity & { user: { institution: { identifier: string } } };
}) => {
  const timezone = activity?.user.institution.identifier;

  const disputedOnDate = creation?.dispute.disputedOn
    ? momentTZ(creation?.dispute.disputedOn).tz(timezone)
    : null;
  const disputeResolveDate = creation?.dispute.resolvedOn
    ? momentTZ(creation?.dispute.resolvedOn).tz(timezone)
    : null;

  return (
    <React.Fragment>
      <Cell column={columns[1]}>{getDisputeStatus(creation?.dispute.status)}</Cell>
      <Cell column={columns[2]}>
        <DateCell date={disputedOnDate} />
      </Cell>
      <Cell column={columns[3]}>
        <DateCell date={disputeResolveDate} />
      </Cell>
      <Cell column={columns[4]} />
    </React.Fragment>
  );
};

const Cols = (props: any) => {
  const { activity, item, index, columns, creation, listBy, toggleGroupMembers } = props;
  if (activityUtils.isGroupAssignment(activity)) {
    const { parentId, isVisible, isExpand } = item;
    if (!parentId) {
      const handleToggleGroupMembers = () => {
        toggleGroupMembers(index);
      };
      return (
        <GroupRows
          columns={columns}
          creation={creation}
          listBy={listBy}
          isExpand={isExpand}
          parentId={parentId}
          toggleGroupMembers={handleToggleGroupMembers}
          activity={activity}
        />
      );
    }
    if (isVisible) {
      return <GroupMemberRow columns={columns} creation={creation} activity={activity} />;
    }
    return null;
  }
  return <IndividualRows columns={columns} creation={creation} activity={activity} />;
};

const StudentInfo = (props: any) => {
  const { listBy, columns, item, student, group } = props;
  if (localUtil.isListByGroup(listBy)) {
    const infoCellColumn = { ...columns[0] };
    if (!localUtil.isGroupItem({ parentId: item.parentId }, listBy)) {
      infoCellColumn.align = 'left';
      infoCellColumn.isGroupMember = true;
    }

    return (
      <Cell column={infoCellColumn}>
        <StudentInfoCell
          student={student}
          group={group}
          isListByGroup={localUtil.isListByGroup(listBy)}
        />
      </Cell>
    );
  }
  return (
    <Cell column={columns[0]}>
      <StudentInfoCell
        student={student}
        group={group}
        isListByGroup={localUtil.isListByGroup(listBy)}
      />
    </Cell>
  );
};

const Row = (props: any) => {
  const {
    data,
    index,
    style,
    activity,
    listBy,
    navigateToInstructorCreationView,
    selectStudent,
    selectSubmission,
  } = props;
  const { stickyIndices, columns, toggleGroupMembers, rowList } = data;

  if (stickyIndices && stickyIndices.includes(index)) {
    return null;
  }
  if (localUtil.isListByGroup(listBy)) {
    if (columns.length !== 5) {
      return null;
    }
  } else if (columns.length !== 4) {
    return null;
  }

  const item = rowList[index];

  if (!item) {
    return <TableRow />;
  }

  const { student, creation, group } = item;

  const handleClickRow = () => {
    if (creation) {
      selectStudent(student._id);
      selectSubmission(creation._id);
      navigateToInstructorCreationView({
        assignmentId: activity._id,
        courseId: activity.course._id || activity.course,
        creationId: creation._id,
        groupView: !!localUtil.isGroupItem({ parentId: item.parentId }, listBy),
      });
    }
  };

  return (
    <TableRow
      key={student._id}
      isWarning={localUtil.hasCreationWarning(creation, activity)}
      disableHover={!creation}
      style={style}
      onClick={handleClickRow}
      testid={`progress_creation-row-${student._id}`}
    >
      <StudentInfo listBy={listBy} columns={columns} item={item} student={student} group={group} />
      <Cols
        activity={activity}
        item={item}
        index={index}
        columns={columns}
        creation={creation}
        listBy={listBy}
        toggleGroupMembers={toggleGroupMembers}
      />
    </TableRow>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    listBy: selectCreationTableListBy(state),
  };
};

export default connect(mapStateToProps, {
  navigateToInstructorCreationView,
  selectSubmission,
  selectStudent,
})(Row);
