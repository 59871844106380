import React from 'react';
import { connect } from 'react-redux';
import Page from 'pages/Page';
import { selectAssignment, selectCourse } from 'actions/select';
import SpotlightContainer from 'containers/Spotlight';
import { localize } from 'locales';

function Spotlight(props: any) {
  const pageTitle = () => {
    return localize({ message: 'Spotlight.Create.PageTitle' });
  };

  const pageMeta = () => {
    return [];
  };

  const pageLink = () => {
    return [];
  };

  const pageScript = () => {
    return [];
  };

  function getMetaData() {
    return {
      title: pageTitle(),
      meta: pageMeta(),
      link: pageLink(),
      script: pageScript(),
    };
  }

  return (
    <Page {...getMetaData()}>
      <SpotlightContainer {...props} />
    </Page>
  );
}

export default connect(null, { selectAssignment, selectCourse })(Spotlight);
