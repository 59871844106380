import { Field } from 'react-final-form';
import FormInfo from 'components/core/form/FieldInfo';
import FormRadio from 'components/core/form/RadioField';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const CourseStudentPermissions = () => {
  return (
    <>
      <h2 className="new-header-2">
        <TranslatedText i18nKey="CourseStudentPermissions.StudentPermissions" />
      </h2>
      <h3 className="course-details__class-average-title">
        <TranslatedText i18nKey="CourseStudentPermissions.ClassAverage" />
      </h3>
      <fieldset>
        <legend>
          <FormInfo label={localize({ message: 'CourseStudentPermissions.ClassAverageInfo' })} />
        </legend>
        <Field
          name={COURSE_FIELD_NAMES.HIDE_AVERAGE_GRADE}
          parse={(v) => v === 'true'}
          format={(v) => (v ? 'true' : 'false')}
        >
          {({ input }) => {
            return (
              <FormRadio
                options={[
                  {
                    value: 'true',
                    label: localize({ message: 'CourseStudentPermissions.HideClassAverage' }),
                    'aria-pressed': input.value === 'true',
                  },
                  {
                    value: 'false',
                    label: localize({ message: 'CourseStudentPermissions.ShowClassAverage' }),
                    'aria-pressed': input.value === 'false',
                  },
                ]}
                onChange={(e: any) => {
                  input.onChange(e.target.value);
                }}
                value={input.value}
                disabled
                type="button"
              />
            );
          }}
        </Field>
      </fieldset>
    </>
  );
};

export default CourseStudentPermissions;
