import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import NewTabButton from 'components/buttons/NewTab';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { updateRubricHighlightedCriteria, openRubricDropdown } from 'actions/rubrics';
import { shortenTextWithElipsis } from '@kritik/utils/format';
import Button from 'components/buttons/Button';
import { useScreenSize } from 'hooks/useMatchMedia';
import { useParams } from 'react-router';
import MaterialCollapse from 'components/layout/MaterialCollapse';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const Divider = () => {
  return <div className="dropdown-divider" />;
};

const RubricDropdown = (props: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const params = useParams();
  const { isDesktop } = useScreenSize();

  if (isInstructorInCourse) {
    return null;
  }

  const [highlightedCriterionIdx, setHighlightedCriterionIdx] = useState(0);

  const levelRef = useRef(
    _.range(_.maxBy(props.rubric.grid, 'length').length).map(() => {
      return React.createRef();
    })
  );

  const openDropdown = () => {
    props.openRubricDropdown(true);
    if (!props.highlightedCriteria && props.highlightedCriteria !== 0) {
      props.updateRubricHighlightedCriteria(0);
    }
  };

  const getActiveCriterion = () => {
    return !props.highlightedCriteria || props.highlightedCriteria === 0
      ? 0
      : props.highlightedCriteria;
  };

  useEffect(() => {
    props.updateRubricHighlightedCriteria(0);
    props.openRubricDropdown(true);
  }, []);

  useEffect(() => {
    setHighlightedCriterionIdx(getActiveCriterion());
  }, [props.highlightedCriteria]);

  useEffect(() => {
    if (props.isRubricOpen) {
      openDropdown();
    }
    setHighlightedCriterionIdx(getActiveCriterion());
  }, [props.isRubricOpen]);

  useEffect(() => {
    if (props.highlightedLevel < 0 || !levelRef.current[props.highlightedLevel].current) {
      return;
    }
    (levelRef as any).current[props.highlightedLevel].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [props.highlightedLevel]);

  if (!isDesktop) {
    return null;
  }

  const renderHeader = () => {
    const criterion = props.rubric.criteria[highlightedCriterionIdx];
    return (
      <div className="dropdown-criteria__header">
        <div className="dropdown-criteria__name" data-testid="criteria-name-popover">
          {shortenTextWithElipsis(criterion.name, 45)}
        </div>
        <div className="dropdown-criteria__weight">
          {criterion.weight}
          <TranslatedText i18nKey="Activity.Weight.SinglePoint" />
        </div>
      </div>
    );
  };

  const renderStars = (index: any) => {
    const numLevels = props.rubric.grid[highlightedCriterionIdx].length;
    if (numLevels === 2) {
      return index === 0 ? (
        <span>
          <ClearRoundedIcon />
        </span>
      ) : (
        <span>
          <CheckRoundedIcon />
        </span>
      );
    }
    return index === 0 ? (
      <span>
        <ClearRoundedIcon />
      </span>
    ) : (
      _.times(index, () => {
        return (
          <span>
            <GradeRoundedIcon />
          </span>
        );
      })
    );
  };

  const isLevelSelected = (levelNum: any) => {
    if (levelNum === props.highlightedLevel) {
      return 'dropdown-level--selected';
    }
    return '';
  };

  const renderLevel = (level: any, index: any) => {
    const description = props.rubric.levels[index];
    return (
      <React.Fragment>
        <div className={`dropdown-level ${isLevelSelected(index)}`}>
          <div className="dropdown-level__header" ref={levelRef.current[index]}>
            <div className="dropdown-level__name">{shortenTextWithElipsis(description, 25)}</div>
            <div className="dropdown-level__stars">{renderStars(index)}</div>
          </div>
          <div className="dropdown-level__text">{level}</div>
        </div>
        <Divider />
      </React.Fragment>
    );
  };

  const renderLevels = () => {
    const levels = props.rubric.grid[highlightedCriterionIdx];
    return (
      <div
        className="rubric-dropdown__content"
        key={`rubric-popup-content-${highlightedCriterionIdx}`}
      >
        {renderHeader()}
        <Divider />
        {levels.map((level: any, index: any) => {
          return renderLevel(level, index);
        })}
      </div>
    );
  };

  const changePage = (direction: any) => {
    const numOfCriteria = props.rubric.criteria.length;
    const newCriterion = (highlightedCriterionIdx + direction + numOfCriteria) % numOfCriteria;
    props.updateRubricHighlightedCriteria(newCriterion);
  };
  const currentPage = highlightedCriterionIdx + 1;

  const renderFooter = () => {
    return (
      <div className="dropdown-menu">
        <Button
          type="hidden"
          className="dropdown-menu__icon"
          onClick={() => {
            return changePage(-1);
          }}
          aria-label={localize({ message: 'Rubric.Button.GoToPreviousPage' })}
        >
          <i className="fas fa-chevron-left" />
        </Button>
        <div className="dropdown-menu__text">
          <TranslatedText
            i18nKey="Rubric.Dropdown.Pagination"
            values={{
              currentPage: currentPage.toString(),
              totalPages: props.rubric.criteria.length,
            }}
          />
        </div>
        <Button
          type="hidden"
          className="dropdown-menu__icon"
          onClick={() => {
            return changePage(1);
          }}
          aria-label={localize({ message: 'Rubric.Button.GoToNextPage' })}
        >
          <i className="fas fa-chevron-right" />
        </Button>
      </div>
    );
  };

  const onClickFullRubric = () => {
    window.open(
      `/course/${params.courseId}/assignment/${props.rubric.assignment}/rubric/${props.rubric._id}`
    );
  };

  const fullRubricButton = () => {
    return (
      <div className="dropdown-button">
        <NewTabButton
          onClick={onClickFullRubric}
          label={localize({ message: 'Rubric.Dropdown.FullRubric.Button' })}
          testid="full-rubric-student-popover"
        />
      </div>
    );
  };

  return (
    <div className="rubric-dropdown" data-testid="rubric-popover">
      <MaterialCollapse
        elementKey="rubric-dropdown"
        title={localize({ message: 'Rubric.Dropdown.Title' })}
        testid={`rubric-popover-toggleOpen`}
        className="rubric-dropdown__header"
        isOpen={props.isRubricOpen}
      >
        {renderLevels()}
        {renderFooter()}
        {fullRubricButton()}
      </MaterialCollapse>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    highlightedCriteria: state.rubric.rubrics.highlightedRubricCriteria,
    highlightedLevel: state.rubric.rubrics.highlightedRubricLevel,
    isRubricOpen: state.rubric.rubrics.rubricDropdownOpen,
  };
};

RubricDropdown.defaultProps = {
  highlightedCriteria: null,
  highlightedLevel: -1,
};

export default connect(mapStateToProps, {
  updateRubricHighlightedCriteria,
  openRubricDropdown,
})(RubricDropdown);
