import React from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import OPTION_ICONS from 'images/activity-type-options';
import { withRouter } from 'utils/withRouter';
import { isCalibrationActivity, isWithinGroupActivity } from '@kritik/utils/activity';
import { isCreateOrLater } from '@kritik/utils/stage';
import { TYPE_OPTIONS } from 'components/ActivityEdit/constants';
import OptionCard from './OptionCard';
import { localize } from 'locales';
import { isPresentationActivity } from '@kritik/utils/activity';
import { TranslatedText } from 'components/TranslatedText';

const TypeSelection = (props: any) => {
  let { activityType } = props;
  if (props.router && props.router.initialFormValues) {
    activityType = props.router.initialFormValues.activityType;
  }

  const renderIndividualCard = () => {
    return (
      <OptionCard
        icon={OPTION_ICONS.PEER}
        title={localize({ message: 'ActivityEdit.TypeSelection.Individual.Title' })}
        description={localize({ message: 'ActivityEdit.TypeSelection.Individual.Description' })}
        selectCard={() => {
          return props.onChange(ACTIVITY_TYPES.PEER);
        }}
        selected={activityType === ACTIVITY_TYPES.PEER}
      />
    );
  };

  const renderGroupCard = () => {
    return (
      <OptionCard
        icon={OPTION_ICONS.GROUP}
        title={localize({ message: 'ActivityEdit.TypeSelection.Group.Title' })}
        description={localize({ message: 'ActivityEdit.TypeSelection.Group.Description' })}
        selectCard={() => {
          return props.onChange(ACTIVITY_TYPES.GROUP);
        }}
        selected={
          activityType === ACTIVITY_TYPES.GROUP || activityType === ACTIVITY_TYPES.WITHIN_GROUP
        }
      />
    );
  };

  const renderCalibrationCard = () => {
    if (!props.activity) {
      return (
        <OptionCard
          icon={OPTION_ICONS.CALIBRATION}
          title={localize({ message: 'ActivityEdit.TypeSelection.Calibration.Title' })}
          description={localize({ message: 'ActivityEdit.TypeSelection.Calibration.Description' })}
          selectCard={() => {
            return props.onChange(ACTIVITY_TYPES.CALIBRATION);
          }}
          selected={activityType === ACTIVITY_TYPES.CALIBRATION}
        />
      );
    }
  };

  const renderPresentationCard = () => {
    if (
      (props.activity && props.isEdit && !isPresentationActivity(props.activity)) ||
      (props.activity && props.isDuplicating && !isPresentationActivity(props.activity))
    ) {
      return null;
    }

    return (
      <OptionCard
        icon={OPTION_ICONS.PRESENTATION}
        title={localize({ message: 'Activity.Presentation' })}
        description={localize({ message: 'Activity.Presentation.Card.Description' })}
        selectCard={() => {
          return props.onChange(ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION);
        }}
        selected={
          activityType === ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION ||
          activityType === ACTIVITY_TYPES.GROUP_PRESENTATION
        }
      />
    );
  };

  if (
    props.activity &&
    (isCalibrationActivity(props.activity) ||
      isWithinGroupActivity(props.activity) ||
      (isCreateOrLater(props.activity) && !props.isDuplicating))
  ) {
    return null;
  }

  const typesComponent = {
    [TYPE_OPTIONS.PEER]: renderIndividualCard,
    [TYPE_OPTIONS.GROUP]: renderGroupCard,
    [TYPE_OPTIONS.CALIBRATION]: renderCalibrationCard,
    [TYPE_OPTIONS.PRESENTATION]: renderPresentationCard,
  };

  return (
    <div className="activity-edit__type-selection">
      <h3>
        <TranslatedText i18nKey="ActivityEdit.TypeSelection.Title" />
      </h3>
      <div className="activity-option-card-list">
        {props.typesEnabled.map((type: string) => (
          <React.Fragment key={type}>{typesComponent[type]?.()}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

TypeSelection.defaultProps = {
  typesEnabled: [TYPE_OPTIONS.CALIBRATION, TYPE_OPTIONS.GROUP, TYPE_OPTIONS.PEER],
};

export default withRouter(connect(mapStateToProps, {})(TypeSelection));
