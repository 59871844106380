import React from 'react';
import RadioInput from 'components/core/input/Radio';
import { GRADE_SYNC_TYPE } from 'components/LmsGradeSync/constants';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const options = [
  {
    value: 'activity',
    onClick: null,
    label: localize({ message: 'ScoreSyncBreakdown.Option.ByActivity' }),
    testid: 'set-by-activity',
  },
  {
    value: 'overall',
    onClick: null,
    label: localize({ message: 'ScoreSyncBreakdown.Option.NoBreakdown' }),
  },
];

function ScoreSyncBreakdownSwitch(props: any) {
  if (!props.isNewIntegration) {
    return null;
  }

  const renderLabel = () => {
    if (props.value === GRADE_SYNC_TYPE.ACTIVITY) {
      return (
        <p>
          <TranslatedText i18nKey="ScoreSyncBreakdown.Label.ByActivity" />
        </p>
      );
    }
    return (
      <p>
        <TranslatedText i18nKey="ScoreSyncBreakdown.Label.Overall" />
      </p>
    );
  };
  return (
    <div className="scoretypesync__switch">
      <h4>
        <TranslatedText i18nKey="ScoreSyncBreakdown.Title" />
      </h4>
      <p>
        <TranslatedText i18nKey="ScoreSyncBreakdown.Description" />
      </p>
      <RadioInput
        value={props.value}
        options={options}
        onChange={props.onChange}
        type="button"
        legendText="Legend.Text.LMS.ScoreSyncBreakdown"
      />
      {renderLabel()}
    </div>
  );
}

export default ScoreSyncBreakdownSwitch;
