import { localize } from 'locales';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

type BetaBadgeProps = {
  label?: string;
  className?: string;
  toolTipTitle?: string;
  toolTipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

export function BetaBadge({ label, className, toolTipTitle, toolTipPlacement }: BetaBadgeProps) {
  const classes = classNames('beta-badge', className);
  if (!label) {
    label = localize({ message: 'Beta' });
  }

  return (
    <Tooltip
      title={toolTipTitle || localize({ message: 'Badges.Beta.ToolTip' })}
      placement={toolTipPlacement}
    >
      <span className={classes}>{label}</span>
    </Tooltip>
  );
}
