import { Component } from 'react';
import { connect } from 'react-redux';
import CreateAssignmentContainer from 'containers/CreateAssignment';
import Page from 'pages/Page';
import { localize } from 'locales';

type EditActivityProps = {
  isEditingRubric: boolean;
  isViewingRubric: boolean;
};

class EditActivity extends Component<EditActivityProps, any> {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return this.props.isEditingRubric
      ? localize({
          message: 'Activity.EditRubric.PageTitle',
        })
      : this.props.isViewingRubric
        ? localize({
            message: 'Activity.Rubric.PageTitle',
          })
        : localize({
            message: 'Activity.Edit.PageTitle',
          });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <CreateAssignmentContainer {...this.props} />
      </Page>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isEditingRubric: state.activityEdit.rubric.isRubricEditShow,
    isViewingRubric: state.activityEdit.rubric.isRubricViewShow,
  };
}

export default connect(mapStateToProps, {})(EditActivity);
