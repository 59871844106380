import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Button } from 'components/buttons';
import { TranslatedText } from 'components/TranslatedText';
import FormFieldLabel from 'components/core/form/FieldLabel';
import Select from 'components/core/form/SelectField';
import { I18nKey, localize } from 'locales';
import { useFlagCreation } from 'hooks/activityActionItems';
import { enqueueSnackbar } from 'notistack';

type FlagCreationModalProps = {
  creationId: string;
  open: boolean;
  onClose: () => void;
};

type FlagCreationReason = creations.$id.flag.POST.Request['reason'];
const reasons: Record<FlagCreationReason, I18nKey> = {
  AttachmentOrLinkCannotBeOpened: 'FlagCreation.Reason.AttachmentOrLinkCannotBeOpened',
  PotentialPlagiarism: 'FlagCreation.Reason.PotentialPlagiarism',
  PotentialAIContent: 'FlagCreation.Reason.PotentialAIContent',
  InappropriateContent: 'FlagCreation.Reason.InappropriateContent',
  Other: 'FlagCreation.Reason.Other',
};

export function FlagCreationModal({ open, onClose, creationId }: FlagCreationModalProps) {
  const [reason, setReason] = useState<FlagCreationReason>(null);
  const [explanation, setExplanation] = useState('');
  const { mutateAsync, isLoading } = useFlagCreation();

  function closeModal() {
    setReason(null);
    setExplanation('');
    onClose();
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await mutateAsync({ creationId, reason, explanation });
      closeModal();
      enqueueSnackbar(
        localize({
          message: 'FlagCreation.Success.Message',
        }),
        {
          title: localize({
            message: 'FlagCreation.Success.Title',
          }),
          variant: 'success',
        }
      );
    } catch (error) {
      enqueueSnackbar(
        localize({
          message: 'FlagCreation.Error.Message',
        }),
        {
          title: localize({
            message: 'FlagCreation.Error.Title',
          }),
          variant: 'error',
        }
      );
    }
  }

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>
        <TranslatedText i18nKey="FlagCreation.Title" />
      </DialogTitle>
      <DialogContent>
        <TranslatedText i18nKey="FlagCreation.Content" />
      </DialogContent>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Select
            required
            label={<TranslatedText i18nKey="FlagCreation.Reason.Label" />}
            value={reason}
            onChange={(e: any) => {
              setReason(e.target.value as FlagCreationReason);
            }}
            options={Object.keys(reasons).map((reason) => ({
              label: <TranslatedText i18nKey={reasons[reason]} />,
              value: reason,
            }))}
            placeholder={localize({ message: 'FlagCreation.Reason.Placeholder' })}
            testid="flag-creation-reason"
          />

          <FormFieldLabel
            label={<TranslatedText i18nKey="FlagCreation.Explanation.Label" />}
            name="explanation"
            isRequired
          />
          <textarea
            required
            id="explanation"
            name="explanation"
            data-testid="flag-creation-explanation"
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
            style={{
              width: '100%',
              height: '100px',
              padding: '5px',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} type="secondary" onClick={closeModal}>
            <TranslatedText i18nKey="Cancel" />
          </Button>
          <Button
            disabled={isLoading || !reason || !explanation}
            type="primary"
            inputType="submit"
            data-testid="flag-creation-submit"
          >
            <TranslatedText i18nKey="FlagCreation.Submit" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
