import { useEffect, useState } from 'react';
import { withRouter } from 'utils/withRouter';
import CollapsibleArea from 'components/layout/CollapsibleArea';
import { groupService } from 'services';
import { I18nKey, localize } from 'locales';
import { EXCLUDE_CREATORS, SPECIFIC_GROUPS, SPECIFIC_STUDENTS } from '@kritik/constants/activity';

const getButtonOptions = ({
  course,
  courseHasGroupSets,
  activity,
}: {
  course: any;
  courseHasGroupSets: boolean;
  activity: any;
}): { label: I18nKey; linkText: I18nKey; url: string } => {
  if (course.students.length === 0) {
    return {
      label: 'Activity.Presentation.Overview.StudentParticipation.NoStudentEnrolled',
      linkText: 'Activity.Presentation.Overview.StudentParticipation.LinkEnrollStudents',
      url: `/course/${course._id}/roster`,
    };
  }

  if (!courseHasGroupSets) {
    return {
      label: 'Activity.Presentation.Overview.StudentParticipation.NoGroupSet',
      linkText: 'Activity.Presentation.Overview.StudentParticipation.LinkCreateGroupSet',
      url: `/course/${course._id}/roster?tab=groups`,
    };
  }

  return {
    label: 'Activity.Presentation.Overview.StudentParticipation.NoStudentSelected',
    linkText: 'Activity.Presentation.Overview.StudentParticipation.LinkEditParticipation',
    url: `/course/${course._id}/assignment/${activity._id}/edit-participation`,
  };
};

const StudentParticipation = withRouter(({ activity, course, router }) => {
  const [courseHasGroupSets, setCourseHasGroupSets] = useState(false);

  useEffect(() => {
    const getCourseGroupSets = async () => {
      try {
        const response = await groupService().getAllGroupSets({ courseId: course._id });
        if (response.data.length) {
          setCourseHasGroupSets(true);
        }
      } catch (ignore) {}
    };
    void getCourseGroupSets();
  }, []);

  const hasSelectedParticipationSettings =
    activity.participationSettings?.creatorGroups?.length > 0;

  const { label, linkText, url } = getButtonOptions({
    course,
    courseHasGroupSets,
    activity,
  });

  return (
    <CollapsibleArea title="Students">
      <div className="activity-overview-body__section-title participation-title">
        {localize({ message: 'Activity.Presentation.Overview.StudentParticipation.Table' })}
      </div>
      {!hasSelectedParticipationSettings ? (
        <div className="no-student-selected" data-testid="participation-no-student-selected">
          {localize({
            message: label,
          })}
          <a onClick={() => router.push(url)}>
            {localize({
              message: linkText,
            })}
          </a>
        </div>
      ) : (
        <StudentTable
          creatorGroups={activity.participationSettings.creatorGroups}
          evaluatorType={activity.participationSettings.evaluatorType}
          students={course.students}
          evaluators={activity.participationSettings.evaluatorStudents}
          evaluatorGroups={activity.participationSettings.evaluatorGroups || []}
        />
      )}
    </CollapsibleArea>
  );
});

const StudentTable = withRouter(
  ({ router, creatorGroups, evaluatorType, students, evaluators, evaluatorGroups }) => {
    const [_creatorGroups, setCreatorGroups] = useState([]);
    const [_evaluatorGroups, setEvaluatorGroups] = useState([]);
    const { assignmentId } = router.params;

    useEffect(() => {
      const getGroups = async () => {
        const response = await groupService().getGroupPresentationParticipation(assignmentId);
        if (response) {
          setCreatorGroups(response.data.creatorGroups);
          setEvaluatorGroups(response.data.evaluatorGroups);
        }
      };
      void getGroups();
    }, []);

    if (_creatorGroups.concat(_evaluatorGroups).length === 0) {
      return null;
    }

    let stageParticipationMessage = localize({
      message: 'Activity.Presentation.Overview.StudentParticipation.Table.AllStudents',
    });
    if (evaluatorType === EXCLUDE_CREATORS) {
      stageParticipationMessage = localize({
        message: 'Activity.Presentation.Overview.StudentParticipation.Table.ExcludeCreators',
      });
    } else if (evaluatorType === SPECIFIC_STUDENTS) {
      stageParticipationMessage = localize({
        message: 'Activity.Presentation.Overview.StudentParticipation.Table.SpecificStudents',
      });
    }

    return (
      <div data-testid="student-participation-table">
        <div className="student-participation-table student-participation-table-header">
          <div>
            {localize({
              message: 'Activity.Presentation.Overview.StudentParticipation.Table.Create',
            })}
            <span>
              {localize({
                message: 'Activity.Presentation.Overview.StudentParticipation.Table.Presenters',
              })}
            </span>
          </div>
          <div>
            {localize({
              message: 'Activity.Presentation.Overview.StudentParticipation.Table.Evaluate',
            })}
          </div>
          <div>
            {localize({
              message: 'Activity.Presentation.Overview.StudentParticipation.Table.Feedback',
            })}
          </div>
        </div>
        <div className="student-participation-table">
          <div className="student-participation-table-creators">
            {creatorGroups.map((creatorGroupId, index) => {
              const group = _creatorGroups.find((group) => group._id === creatorGroupId);
              return (
                <div
                  className="student-participation-table-participant-name"
                  data-testid={`student-participation-table-group-${index}`}
                >
                  {group.name}
                </div>
              );
            })}
          </div>
          <div>
            {evaluatorType === SPECIFIC_STUDENTS
              ? students
                  .filter((s) => evaluators.includes(s._id))
                  .map((s) => (
                    <div className="student-participation-table-participant-name">
                      {s.user?.profile?.name}
                    </div>
                  ))
              : evaluatorType === SPECIFIC_GROUPS
                ? _evaluatorGroups.map((g) => {
                    return (
                      <div className="student-participation-table-participant-name">{g.name}</div>
                    );
                  })
                : stageParticipationMessage}
          </div>
          <div>
            {evaluatorType === SPECIFIC_STUDENTS
              ? students
                  .filter((s) => evaluators.includes(s._id))
                  .map((s) => (
                    <div className="student-participation-table-participant-name">
                      {s.user?.profile?.name}
                    </div>
                  ))
              : evaluatorType === SPECIFIC_GROUPS
                ? _evaluatorGroups.map((g) => {
                    return (
                      <div className="student-participation-table-participant-name">{g.name}</div>
                    );
                  })
                : stageParticipationMessage}
          </div>
        </div>
      </div>
    );
  }
);
export default StudentParticipation;
