import React from 'react';
import PageContainer from 'components/layout/PageContainer';
import InlineInformation from 'components/layout/InlineInformation';
import { Link } from 'react-router-dom';
import Button from 'components/buttons/Button';
import { TranslatedText } from 'components/TranslatedText';

const ErrorContainer = () => {
  return (
    <PageContainer>
      <div className="error-container" data-testid="error-container">
        <InlineInformation type="danger">
          <TranslatedText i18nKey="Error.SomethingWrongHappened" />
        </InlineInformation>
        <Link to="/">
          <Button className="error-back-button" type="primary">
            <TranslatedText i18nKey="Back" />
          </Button>
        </Link>
      </div>
    </PageContainer>
  );
};

export default ErrorContainer;
