import axios from 'axios';

import FileSaver from 'file-saver';
import GradebookService from 'services/gradebook';
import moment from 'moment';
import gradebookTypes from './types';
import { enqueueSnackbar } from 'notistack';
import { localize } from 'locales';

function downloadGradebook(course: any, setCancelController: any) {
  const gradebookService = new GradebookService();
  setCancelController?.(gradebookService.source);

  return async (dispatch: any) => {
    dispatch({
      type: gradebookTypes.DOWNLOAD_GRADEBOOK,
    });
    try {
      const res = await gradebookService.get({
        course: course._id,
      });
      const timeStamp = moment().format('DD-MM-YYYY');
      FileSaver.saveAs(res.data, `${course.title}--${timeStamp}.xlsx`);
      dispatch({
        type: gradebookTypes.DOWNLOAD_GRADEBOOK_SUCCESS,
      });
    } catch (error) {
      // check if is caused by cancelation, please check axios source code
      // https://github.com/axios/axios/blob/master/lib/cancel/isCancel.js
      const responseMsg = JSON.parse(await (error as any).response.data.text()).errors?.message;

      let errorMsg = '';

      if (!axios.isCancel(error)) {
        if (responseMsg) {
          errorMsg = responseMsg;
        } else {
          errorMsg = localize({ message: 'Gradebook.Download.GenericError.Content' });
        }
      }
      enqueueSnackbar(errorMsg, {
        title: localize({ message: 'Gradebook.Download.GenericError.Title' }),
        variant: 'error',
        testid: 'download-gradebook-error',
      });

      dispatch({
        type: gradebookTypes.DOWNLOAD_GRADEBOOK_FAILURE,
      });
    }
  };
}

export default {
  downloadGradebook,
};
