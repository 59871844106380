import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import { GroupPresentationActivity } from 'components/ActivityEdit/Presentation/StudentParticipation/GroupPresentationActivityStudentParticipation';
import { IndividualPresentationActivityStudentParticipation } from 'components/ActivityEdit/Presentation/StudentParticipation/IndividualPresentationActivityStudentParticipation';
import {
  isGroupPresentationActivity,
  isIndividualPresentationActivity,
} from '@kritik/utils/activity';
import { isEvaluateOrLater } from '@kritik/utils/stage';
import { withRouter } from 'utils/withRouter';
import { useFetchActivity } from 'hooks/activity';
import { useFetchGroupSet } from 'hooks/group';
import { useGetActiveStudents } from 'hooks/student';
import { TranslatedText } from 'components/TranslatedText';

export const EditActivityParticipation = withRouter(({ router }: { router: any }) => {
  const { assignmentId, courseId } = router.params;

  const { data: activity } = useFetchActivity(assignmentId);
  const { data: activityGroupSet } = useFetchGroupSet(assignmentId, activity);
  const { data: students } = useGetActiveStudents(courseId, activity);

  const createStageHasPassed = isEvaluateOrLater(activity);

  if (createStageHasPassed) {
    router.push(`/course/${courseId}/assignment/${assignmentId}`);
  }
  if (!activity) {
    return null;
  }

  return (
    <PageContainer>
      <PageHeader
        title={<TranslatedText i18nKey="Activity.EditStudentParticipation.PageHeader" />}
      />
      <InlineInformation
        title={localize({ message: 'Activity.EditStudentParticipation.Info.Title' })}
        skipSeparator
      >
        <ul className="activity-participation-notice">
          <li>{localize({ message: 'Activity.EditStudentParticipation.Info.p1' })}</li>
          <li>{localize({ message: 'Activity.EditStudentParticipation.Info.p2' })}</li>
          <li>{localize({ message: 'Activity.EditStudentParticipation.Info.p3' })}</li>
          <li>{localize({ message: 'Activity.EditStudentParticipation.Info.p4' })}</li>
        </ul>
      </InlineInformation>
      {isGroupPresentationActivity(activity) && (
        <GroupPresentationActivity
          activity={activity}
          activityGroupSet={activityGroupSet}
          students={students}
        />
      )}
      {isIndividualPresentationActivity(activity) && (
        <IndividualPresentationActivityStudentParticipation
          activity={activity}
          students={students}
        />
      )}
    </PageContainer>
  );
});
