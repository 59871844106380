import { useState } from 'react';
import { connect } from 'react-redux';
import {
  SignupContainer,
  SignupMainTitle,
  SignupContent,
  SignupText,
  SignupFooter,
} from 'components/AuthWrapper';
import FormFieldLabel from 'components/core/form/FieldLabel';
import Button from 'components/buttons/Button';
import FormTextInput from 'components/core/form/TextField';
import { Form } from 'components/Form';
import isEmail from 'validator/lib/isEmail';
import * as _ from 'lodash-es';
import * as RegionUtils from 'utils/region';
import RegionPicker from 'components/General/RegionPicker/index';
import { getEdlinkUserAccount } from 'services/edlink';
import { withRouter } from 'utils/withRouter';
import { Link } from 'react-router-dom';
import * as GeneralUtils from '@kritik/utils/general';
import { useEffect } from 'react';
import { localize } from 'locales';
import { isForbiddenEmailForRegister } from '@kritik/utils/email';

function SignUpOrSignInStep1(props: any) {
  const [email, setEmail] = useState(props.email || '');
  const [error, setError] = useState({});

  useEffect(() => {
    setEmail(props.router.location?.query?.email || '');
  }, [props.router.location]);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  const handleInputChange = (e: any) => {
    setError({});
    const _email = GeneralUtils.stripWhiteSpaces(e.target.value);
    setEmail(_email);
  };

  const isValidInput = () => {
    const errors = {};
    if (!isEmail(email)) {
      (errors as any).email = 'Please enter valid email';
    }
    if (!props.isSignIn && isForbiddenEmailForRegister({ email })) {
      (errors as any).email = localize({ message: 'SignUp.Error.EnterFacultyEmail' });
    }
    if (!_.isEmpty(errors)) {
      return setError(errors);
    }
    return true;
  };

  const handleOnSubmit = () => {
    if (isValidInput()) {
      getEdlinkUserAccount(email)
        .then((response) => {
          if (response) {
            if (response.integrations.length === 0) {
              return props.onNextStep(false, email, response?.ltiAdvantageIntegration);
            }
            if (response.integrations.length > 0) {
              return props.onNextStep(true, email, response?.ltiAdvantageIntegration);
            }
          }
          return props.onNextStep(false, email, response?.ltiAdvantageIntegration);
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  const getError = (field: any) => {
    if (error) {
      return error[field];
    }
    return null;
  };

  const redirectToRegion = (e: any) => {
    const region = e.target.value;
    const path = props.isSignIn ? '/login' : '/signup/instructor';
    RegionUtils.redirectToRegion({ region, path });
  };

  const renderTitleLabel = () => {
    if (props.isSignIn) {
      return 'Sign-in';
    }
    return 'Sign-up';
  };

  const renderSignUpText = () => {
    if (props.isSignIn) {
      return (
        <>
          Don't have account yet?{' '}
          <Link className="underlined-link" to="/signup">
            Sign-up
          </Link>
        </>
      );
    }
    return (
      <>
        Already have an account?{' '}
        <Link className="underlined-link" to="/login">
          Sign-in
        </Link>
      </>
    );
  };

  return (
    <SignupContainer>
      <SignupContent>
        <SignupMainTitle>{renderTitleLabel()}</SignupMainTitle>
        <FormFieldLabel color="light" label="Region" id="region-select-field" />
        <RegionPicker onChange={redirectToRegion} value={RegionUtils.getRegion()} />
        <Form>
          <FormTextInput
            id="email"
            testid="email"
            name="email"
            label="Email"
            value={email}
            onChange={handleInputChange}
            error={getError('email')}
            onEnterKey={handleOnSubmit}
            autoFocus
            isRequired
          />
          <Button data-testid="signin" type="primary" onClick={handleOnSubmit} inputType="submit">
            {renderTitleLabel()}
          </Button>
        </Form>
      </SignupContent>
      <SignupFooter>
        <SignupText weight="strong">{renderSignUpText()}</SignupText>
      </SignupFooter>
    </SignupContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

SignUpOrSignInStep1.defaultProps = {
  isSignIn: false,
};

export default withRouter(connect(mapStateToProps, {})(SignUpOrSignInStep1));
