import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import RadioInput from 'components/core/input/Radio';
import FormTitle from 'components/core/form/Title';
import Confirm from 'components/modals/ConfirmModal';
import { Field } from 'react-final-form';
import * as statusUtil from '@kritik/utils/stage';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormFieldInfo from 'components/core/form/FieldInfo';
import { copy, FIELD_NAME, FIELD_LABEL, TYPES } from './constants';
import { localize } from 'locales';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const CalibrationSource = (props: any) => {
  const [activitySource, setActivitySource] = useState(null);
  const [changeTypeConfirm, setChangeTypeConfirm] = useState(null);
  useEffect(() => {
    props.removeUpdateRubricNotice();
  }, [activitySource]);
  const getRadioOptions = () => {
    return [
      {
        value: TYPES.PAST_ACTIVITY,
        label: localize({ message: 'CalibrationSource.PastActivity' }),
        testid: 'select-from-past-activity',
      },
      {
        value: TYPES.NEW_ACTIVITY,
        label: localize({ message: 'CalibrationSource.NewActivity' }),
        testid: 'select-from-new-activity',
      },
    ];
  };

  const changeCalibrationSource = (activitySource: any) => {
    if (
      props.settings.activity &&
      !props.settings.isCloning &&
      !statusUtil.isDraft({ assignment: props.settings.activity })
    ) {
      return;
    }
    props.onChange(activitySource);
    cancelTypeSwitch();
  };

  const cancelTypeSwitch = () => {
    setChangeTypeConfirm(null);
  };

  useEffect(() => {
    changeCalibrationSource(activitySource);
  }, [activitySource]);

  const renderConfirm = () => {
    return (
      <Confirm
        title={localize({ message: 'CalibrationSource.ConfirmChangeTitle' })}
        description={localize({ message: 'CalibrationSource.ConfirmChangeDescription' })}
        isOpen={!!changeTypeConfirm}
        onCancel={() => {
          return cancelTypeSwitch();
        }}
        onConfirm={() => {
          return setActivitySource(changeTypeConfirm);
        }}
        cancelButton={localize({ message: 'Common.No' })}
        confirmButton={localize({ message: 'Common.Yes' })}
      />
    );
  };

  const changeEvaluationTypeRequest = (currentEvaluationType: any, targetType: any) => {
    if (currentEvaluationType !== targetType) {
      if (!props.formState.dirty) {
        setActivitySource(targetType);
      } else {
        setChangeTypeConfirm(targetType);
      }
    }
  };

  return (
    <Field name={LABEL.value} validate={validators.isRequired}>
      {({ input, meta }) => {
        return (
          <>
            <FormTitle label={LABEL.label} />
            <RadioInput
              value={input.value}
              options={getRadioOptions()}
              onChange={(e: any) => {
                return changeEvaluationTypeRequest(input.value, e.target.value);
              }}
              type="button"
              legendText="Legend.Text.CalibrationSource"
            />
            {meta.error && meta.touched && <span>{meta.error}</span>}
            <FormFieldInfo
              label={
                input.value === TYPES.PAST_ACTIVITY ? copy.existing_activity : copy.new_activity
              }
            />
            {renderConfirm()}
          </>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(CalibrationSource);
