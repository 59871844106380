import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import NoticeBoard from 'components/layout/NoticeBoard';
import { LateSubmissionInstructorNotice } from 'components/Assignment/NoticeBoards';
import { Button, ButtonContainer } from 'components/buttons';
import {
  getSubmissionLastEditedDate,
  wasCreationSubmittedInGracePeriod,
} from '@kritik/utils/creation/status';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { subTimeFromCreationDue } from '@kritik/utils/format';
import {
  SubmittedDate,
  LateReason,
  RejectedDate,
  AcceptedDate,
} from 'components/Creation/CreationOverview/LateCreationInstructorNoticeBoard/Fields';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

export function LateCreationInstructorNotice(props: any) {
  if (!props.creation || !props.activity) {
    return null;
  }
  const dueDate = ActivityStatusUtil.getCreationStage(props.activity).endDate;
  const submissionDate = getSubmissionLastEditedDate(props.creation);
  const timeAfterDue = subTimeFromCreationDue(dueDate, submissionDate);

  const GracePeriodBoard = () => {
    const creationStatus = ActivityStatusUtil.getCreationStage(props.activity);
    return (
      <LateSubmissionInstructorNotice
        creationDueDate={creationStatus.endDate}
        submissionDate={getSubmissionLastEditedDate(props.creation)}
      />
    );
  };

  const PendingBoard = () => {
    return (
      <NoticeBoard
        type="caution"
        title={localize({
          message: 'LateCreationInstructorNoticeBoard.PendingBoard.Title',
          options: { timeAfterDue },
        })}
        data-testid="pending-late-creation"
      >
        <SubmittedDate creation={props.creation} />
        <LateReason creation={props.creation} />
        <ButtonContainer className="late-submission-boards__buttons">
          <Button type="primary" onClick={props.onAccept} testid="accept-late-creation">
            <TranslatedText i18nKey="LateCreationInstructorNoticeBoard.PendingBoard.AcceptButton" />
          </Button>
          <Button type="secondary" onClick={props.onReject} testid="reject-late-creation">
            <TranslatedText i18nKey="LateCreationInstructorNoticeBoard.PendingBoard.RejectButton" />
          </Button>
        </ButtonContainer>
      </NoticeBoard>
    );
  };

  const RejectedBoard = () => {
    return (
      <NoticeBoard
        type="danger"
        title={localize({
          message: 'LateCreationInstructorNoticeBoard.RejectedBoard.Title',
          options: { timeAfterDue },
        })}
        data-testid="rejected-late-creation"
      >
        <SubmittedDate creation={props.creation} />
        <RejectedDate creation={props.creation} />
        <LateReason creation={props.creation} />
        <ButtonContainer className="late-submission-boards__buttons">
          <Button type="secondary" onClick={props.onAccept}>
            <TranslatedText i18nKey="LateCreationInstructorNoticeBoard.RejectedBoard.AcceptButton" />
          </Button>
        </ButtonContainer>
      </NoticeBoard>
    );
  };

  const AcceptedBoard = () => {
    return (
      <NoticeBoard
        type="success"
        title={localize({
          message: 'LateCreationInstructorNoticeBoard.AcceptedBoard.Title',
          options: { timeAfterDue },
        })}
        data-testid="accepted-late-creation"
      >
        <SubmittedDate creation={props.creation} />
        <AcceptedDate creation={props.creation} />
        <LateReason creation={props.creation} />
        <ButtonContainer className="late-submission-boards__buttons">
          <Button type="secondary" onClick={props.onReject}>
            <TranslatedText i18nKey="LateCreationInstructorNoticeBoard.AcceptedBoard.RejectButton" />
          </Button>
        </ButtonContainer>
      </NoticeBoard>
    );
  };

  if (LateSubmissionUtil.isLateSubmissionPending(props.creation)) {
    return <PendingBoard />;
  }

  if (LateSubmissionUtil.isLateSubmissionRejected(props.creation)) {
    return <RejectedBoard />;
  }
  if (LateSubmissionUtil.isLateSubmissionAccepted(props.creation)) {
    return <AcceptedBoard />;
  }
  if (wasCreationSubmittedInGracePeriod(props.creation)) {
    return <GracePeriodBoard />;
  }

  return null;
}

LateCreationInstructorNotice.defaultProps = {
  onAccept: null,
  onReject: null,
};
