import { useState } from 'react';
import { connect } from 'react-redux';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { wasCreationMissed } from '@kritik/utils/creation/status';
import { StartingSoon, MissedWork, NoAssignedFeedback } from 'components/Assignment/NoticeBoards';
import { EvaluationWithFeedback } from 'components/Creation';
import NoticeBoard from 'components/layout/NoticeBoard';
import * as ActivityUtils from '@kritik/utils/activity';
import { filterOutSelfEvaluation, getNumEvaluationsToDo } from '@kritik/utils/creation/evaluation';
import TabMenu, { statuses } from 'components/layout/TabMenu';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import * as FeedbackUtil from '@kritik/utils/creation/feedback';
import StudentSelectors from 'selectors/student';
import { getSubmissions } from 'selectors/creation';
import StudentContent from 'components/layout/StudentContent';
import { CreationView } from 'components/Creation/Create/View';
import * as StudentUtils from 'utils/student';
import { localize } from 'locales';
import { useUserRoleInCourse } from 'hooks/course';
import InstructionsCard from 'components/InstructionsCard/InstructionsCard';
import Button from 'components/buttons/Button';
import FB_IMAGE_1 from 'images/card-instructions/fb1.jpg';
import FB_IMAGE_2 from 'images/card-instructions/fb2.jpg';
import FB_IMAGE_3 from 'images/card-instructions/fb3.jpg';
import Checkbox from 'components/core/input/Checkbox';
import { useGetStudentEvaluationsInActivity } from 'hooks/evaluation';
import { TranslatedText } from 'components/TranslatedText';
import { useTranslation } from 'react-i18next';
import { Activity } from 'old-common/types.generated';

type StudentViewProps = {
  creation: any;
  activity: any;
  setEvaluationIndex: any;
  evaluationIndex: any;
  creations: any;
  completedFOF: number;
  showTab: boolean;
  completedEvaluationsReceived: any;
};

function StudentView(props: StudentViewProps) {
  const {
    creation,
    activity,
    setEvaluationIndex,
    evaluationIndex,
    creations,
    completedFOF,
    showTab,
    completedEvaluationsReceived,
  } = props;

  if (completedEvaluationsReceived.length === 0) {
    return <NoAssignedFeedback />;
  }

  const getCreation = (evaluationId) => {
    if (ActivityUtils.isPresentationActivity(activity)) {
      // If activity is group presentation, then the creation should be the one that the current evaluation belongs to
      // Not the current user creation
      return creations.find((creation) =>
        creation.scores.some((score) => score._id === evaluationId)
      );
    }
    return creation;
  };

  const renderStudentTabContent = () => {
    const peerEvaluation = completedEvaluationsReceived[evaluationIndex];
    if (!peerEvaluation) {
      return null;
    }
    return (
      <div key={peerEvaluation._id}>
        {ActivityUtils.isPresentationActivity(activity) && (
          <EvaluationCreation creation={getCreation(peerEvaluation._id)} activity={activity} />
        )}
        <EvaluationWithFeedback
          title={localize({
            message: 'StudentEvaluationsOverview.PeerEvaluation',
            options: { index: (evaluationIndex + 1).toString() },
          })}
          submission={getCreation(peerEvaluation._id)}
          evaluation={peerEvaluation}
          assignment={activity}
        />
      </div>
    );
  };

  const renderStudentTabMenu = () => {
    const getTabStatus = (evaluation: any) => {
      if (FeedbackScoreUtil.isFOFCompleted(evaluation)) {
        return statuses.SUCCESS;
      }
      if (ActivityStatusUtil.isPastFeedbackDeadline(props.activity)) {
        return statuses.DANGER;
      }
      return statuses.DEFAULT;
    };
    const labels = completedEvaluationsReceived.map((evaluation: any, i: any) => {
      return {
        id: i,
        title: localize({
          message: 'StudentEvaluationsOverview.Feedback',
          options: { index: (i + 1).toString() },
        }),
        status: getTabStatus(evaluation),
      };
    });
    return (
      <TabMenu
        labels={labels}
        onSelect={(e: any) => {
          return setEvaluationIndex(e);
        }}
        activeLabel={evaluationIndex}
      />
    );
  };

  return (
    <>
      {ActivityStatusUtil.isGradingOrLater(props.activity) ||
      completedEvaluationsReceived.length === 0 ||
      completedFOF > 0 ||
      showTab ? (
        <>
          {renderStudentTabMenu()}
          {renderStudentTabContent()}
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    student: StudentSelectors.getStudentFromUser(state, state.selected.courseId),
    user: state.user,
    creations: getSubmissions(state, state.selected.assignmentId),
  };
};

type EvaluationsOverviewProps = {
  assignment: any;
  creation: any;
  renderHelpBoard?: any;
  student: any;
  creations: any;
};

function StudentEvaluationsOverview({
  assignment,
  creation,
  renderHelpBoard,
  student,
  creations,
}: EvaluationsOverviewProps) {
  const [evaluationIndex, setEvaluationIndex] = useState(0);
  const { isInstructorInCourse } = useUserRoleInCourse();
  const [showTab, setShowTab] = useState(ActivityUtils.isPresentationActivity(assignment));

  const { completedFOF, toDo: FOFToDo } = FeedbackScoreUtil.numFOFCompleted(
    creation || {}, // Use an empty object as a fallback if props.mySubmission is not available
    assignment?._id,
    student?._id
  );

  if (!ActivityStatusUtil.isFeedbackOrLater({ assignment }) && !isInstructorInCourse) {
    if (ActivityUtils.isGroupAssignment(assignment)) {
      return (
        <NoticeBoard
          type="information"
          title={localize({ message: 'StudentEvaluationsOverview.NoFeedbackToShow.Title' })}
        >
          <TranslatedText i18nKey="StudentEvaluationsOverview.NoFeedbackToShow.Content" />
        </NoticeBoard>
      );
    }
    return <StartingSoon stage="FEEDBACK" />;
  }

  if (
    (!creation || wasCreationMissed(creation)) &&
    !ActivityUtils.isPresentationActivity(assignment)
  ) {
    return <MissedWork field="feedback" />;
  }

  if (!ActivityStatusUtil.isEvaluateOrLater(assignment)) {
    return (
      <NoticeBoard
        type="information"
        title={localize({ message: 'StudentEvaluationsOverview.NoEvaluationsReceived.Title' })}
      >
        <TranslatedText i18nKey="StudentEvaluationsOverview.NoEvaluationsReceived.Content" />
      </NoticeBoard>
    );
  }

  function getCompletedEvaluationsReceived() {
    const evaluations: any[] = (() => {
      if (ActivityUtils.isSelfEvaluate(assignment)) {
        return filterOutSelfEvaluation(creation);
      }
      if (ActivityUtils.isGroupAssignment(assignment) && !isInstructorInCourse) {
        return FeedbackUtil.getAssignedFeedbacks(creation.scores, student._id);
      }
      if (ActivityUtils.isPresentationActivity(assignment) && !isInstructorInCourse) {
        return FeedbackUtil.getAssignedFeedbacksFromAllCreations({
          creations,
          studentId: student._id,
        });
      }
      return creation.scores;
    })();

    return (evaluations || []).filter((evaluation) => !evaluation.lateEvaluation);
  }

  const completedEvaluationsReceived = getCompletedEvaluationsReceived();

  return (
    <>
      {renderHelpBoard && (
        <FeedbackInstructions
          activity={assignment}
          completedFOF={completedFOF}
          showTab={showTab}
          setShowTab={setShowTab}
          completedEvaluationsReceived={completedEvaluationsReceived}
          numEvalsAssigned={getNumEvaluationsToDo(creation, student?._id)}
          creation={creation}
          FOFToDo={FOFToDo}
          student={student}
        />
      )}
      <StudentView
        setEvaluationIndex={setEvaluationIndex}
        evaluationIndex={evaluationIndex}
        activity={assignment}
        creation={creation}
        creations={creations}
        completedFOF={completedFOF}
        showTab={showTab}
        completedEvaluationsReceived={completedEvaluationsReceived}
      />
    </>
  );
}

function InstructionsCardDisplay({ activity }: { activity: Activity }) {
  if (ActivityUtils.isPresentationActivity(activity)) {
    return null;
  }
  return (
    <div className="instructions-card-grid-container">
      <div className="instructions-card-grid" data-testid="feedback-cards">
        <InstructionsCard
          title={localize({
            message: 'Activity.Feedback.StudentView.Tab.InstructionsCard1.Title',
          })}
          imageSrc={FB_IMAGE_1}
        >
          <p className="truncate-text">
            {localize({
              message: 'Activity.Feedback.StudentView.Tab.InstructionsCard1.content',
            })}
          </p>
        </InstructionsCard>
        <InstructionsCard
          title={localize({
            message: 'Activity.Feedback.StudentView.Tab.InstructionsCard2.Title',
          })}
          imageSrc={FB_IMAGE_2}
        >
          <p className="truncate-text">
            {localize({
              message: 'Activity.Feedback.StudentView.Tab.InstructionsCard2.content',
            })}
          </p>
        </InstructionsCard>
        <InstructionsCard
          title={localize({
            message: 'Activity.Feedback.StudentView.Tab.InstructionsCard3.Title',
          })}
          imageSrc={FB_IMAGE_3}
        >
          <p className="truncate-text">
            {localize({
              message: 'Activity.Feedback.StudentView.Tab.InstructionsCard3.content',
            })}
          </p>
        </InstructionsCard>
      </div>
    </div>
  );
}

const FeedbackInstructions = ({
  activity,
  completedFOF,
  showTab,
  setShowTab,
  completedEvaluationsReceived,
  numEvalsAssigned,
  creation,
  FOFToDo,
  student,
}) => {
  const { data: evaluationsGiven, isLoading } = useGetStudentEvaluationsInActivity({
    studentId: student._id,
    activityId: activity._id,
  });

  if (isLoading) {
    return null;
  }
  const isGroupActivity = ActivityUtils.isGroupAssignment(activity);

  const getTitle = () => {
    if (isGroupActivity) {
      return localize({
        message: 'Activity.GroupActivity.StudentView.FeedbackInstructions.Title',
      });
    }
    return localize({
      message: 'Activity.Presentation.StudentView.FeedbackInstructions.Title',
    });
  };

  const hasCompletedAllEvaluationsOnTime =
    evaluationsGiven.length === numEvalsAssigned &&
    !evaluationsGiven.some((evaluation) => evaluation.lateEvaluation?.status === 'Pending');

  const showLateEvaluationNotice =
    activity.acceptLateEvaluations &&
    completedFOF === 0 &&
    FOFToDo > 0 &&
    !hasCompletedAllEvaluationsOnTime;

  return (
    <>
      <h2 className="stage-instructions__title">{getTitle()}</h2>
      <InstructionsCardDisplay activity={activity} />
      {showLateEvaluationNotice && !showTab ? (
        <LateEvaluationNotice
          hasMissingEvaluationsGiven={evaluationsGiven.length < numEvalsAssigned}
          onWaiveLateEvaluationRights={() => setShowTab(true)}
          creation={creation}
        />
      ) : (
        completedEvaluationsReceived.length === 0 ||
        (completedFOF === 0 && !showTab && (
          <Button
            type="primary"
            disabled={false}
            unavailable={false}
            onClick={() => setShowTab(true)}
            label={localize({ message: 'Button.Label.StartToGiveFeedback' })}
            data-testid="button-start-feedback"
          >
            <i className="fas fa-pen" />
            <span className="button-span-copy">
              <TranslatedText i18nKey="Activity.StudentView.FeedbackInstructions.ReadyToGiveFeedback" />
            </span>
          </Button>
        ))
      )}
    </>
  );
};

const EvaluationCreation = ({ creation, activity }) => {
  // TODO: here, for individual presentation activities, creation.user.profile.name will show anonymous.
  // We probably need to update the query to remove the filter.
  const title = `${
    ActivityUtils.isGroupPresentationActivity(activity)
      ? creation.group.name
      : creation.user.profile.name
  }'s Creation`;
  return (
    <StudentContent
      anonymous={false}
      group={creation.group}
      user={creation.user}
      score={StudentUtils.getNewKS(creation.student)}
    >
      <CreationView submission={creation} title={title} />
    </StudentContent>
  );
};

const LateEvaluationNotice = ({
  hasMissingEvaluationsGiven,
  onWaiveLateEvaluationRights,
}: {
  hasMissingEvaluationsGiven: boolean;
  creation: any;
  onWaiveLateEvaluationRights: () => void;
}) => {
  const { t } = useTranslation();
  const [isReadyToWaiveLateEvaluationRight, setIsReadyToWaiveLateEvaluationRight] = useState(false);

  if (hasMissingEvaluationsGiven) {
    return (
      <NoticeBoard
        title={t('Creation.Evaluation.StudentView.LateEvaluation.Notice.Title')}
        type="caution"
        testid="skip-submit-evaluations-notice"
      >
        <p>
          <TranslatedText i18nKey="Creation.Evaluation.StudentView.LateEvaluation.Notice.Content" />
        </p>
        <Checkbox
          isChecked={isReadyToWaiveLateEvaluationRight}
          onChange={() => {
            return setIsReadyToWaiveLateEvaluationRight(
              (isReadyToWaiveLateEvaluationRight) => !isReadyToWaiveLateEvaluationRight
            );
          }}
          label={t('Creation.Evaluation.StudentView.LateEvaluation.Notice.CheckboxLabel')}
          className="late-evaluations-checkbox"
          testid="confirm-skip-submit-evaluations"
        />
        <div>
          <Button
            type="primary"
            disabled={!isReadyToWaiveLateEvaluationRight}
            unavailable={!isReadyToWaiveLateEvaluationRight}
            onClick={onWaiveLateEvaluationRights}
            label={localize({
              message: 'Button.Label.SkipMissingEvaluationsAndStartToGiveFeedback',
            })}
            testid="start-giving-feedback"
          >
            {localize({ message: 'Button.Label.StartFeedback' })}
          </Button>
        </div>
      </NoticeBoard>
    );
  }

  return (
    <NoticeBoard
      title={t('Creation.Evaluation.StudentView.LateEvaluation.StartFeedback.Notice.Title')}
      type="caution"
      testid="skip-edit-scores-notice"
    >
      <div>
        <TranslatedText i18nKey="Creation.Evaluation.StudentView.LateEvaluation.StartFeedback.Notice.Content" />
      </div>
      <Checkbox
        isChecked={isReadyToWaiveLateEvaluationRight}
        onChange={() => {
          return setIsReadyToWaiveLateEvaluationRight(
            (isReadyToWaiveLateEvaluationRight) => !isReadyToWaiveLateEvaluationRight
          );
        }}
        label={t(
          'Creation.Evaluation.StudentView.LateEvaluation.StartFeedback.Notice.CheckboxLabel'
        )}
        className="late-evaluations-checkbox"
        testid="confirm-skip-edit-scores"
      />
      <div>
        <Button
          type="primary"
          disabled={!isReadyToWaiveLateEvaluationRight}
          unavailable={!isReadyToWaiveLateEvaluationRight}
          onClick={onWaiveLateEvaluationRights}
          testid="start-giving-feedback"
        >
          {localize({ message: 'Button.Label.StartFeedback' })}
        </Button>
      </div>
    </NoticeBoard>
  );
};

export default connect(mapStateToProps)(StudentEvaluationsOverview);
