import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { updateRubric, createRubricTemplate } from 'actions/rubrics';
import { selectAuthUser } from 'selectors/user';
import PageHeader from 'components/layout/PageHeader';
import FullScreenModal from 'components/modals/FullScreenModal';
import PageContainer from 'components/layout/PageContainer/index';
import RubricEdit from 'components/Rubric/Edit/Grid';
import TextField from 'components/core/form/TextField';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import Button from 'components/buttons/Button';
import { selectRubric } from 'redux/rubricManager/selectors';
import * as rubricUtils from '@kritik/utils/rubric';
import * as _ from 'lodash-es';
import InlineInformation from 'components/layout/InlineInformation';
import OnLeaveModal from 'components/modals/OnLeaveModal';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const TemplateEdit = (props: any) => {
  const [rubric, setRubric] = useState();
  const [templateTitle, setTemplateTitle] = useState();
  const [error, setError] = useState();
  const errorDisplayRef = useRef(null);

  useEffect(() => {
    setRubric(null);
  }, [props.isOpen]);

  useEffect(() => {
    setError(null);
  }, [props.rubric]);

  if (!props.rubric) {
    return null;
  }

  if (!rubric) {
    setRubric(props.rubric);
    setTemplateTitle(props.rubric.name);
    return null;
  }

  const onChangeTitle = (e: any) => {
    setError(null);
    setTemplateTitle(e.target.value);
  };

  const isCoreTemplate = (rubric: any) => {
    return !!rubric.default;
  };

  const scrollToError = () => {
    errorDisplayRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const handleSubmit = () => {
    const validationError =
      rubricUtils.validateName(templateTitle) ||
      rubricUtils.validateCriteriaName((rubric as any).criteria);

    if (validationError) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setError(validationError);
      scrollToError();
      return;
    }

    const capitalizedName = rubricUtils.capitalizeName(templateTitle);

    const newRubric = {
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...rubric,
      name: capitalizedName,
      criteria: (rubric as any).criteria.map((c: any) => {
        return {
          ...c,
          weight: Number(c.weight),
        };
      }),
    };

    if (_.isEqual(props.rubric, newRubric)) {
      props.onClose();
      return;
    }

    if (isCoreTemplate(rubric)) {
      props
        .createRubricTemplate({
          rubric: newRubric,
          userId: props.authUser.id,
        })
        .then((data: any) => {
          props.onClose();
        })
        .catch((err: any) => {
          setError(err.message);
          scrollToError();
        });
    } else {
      props
        .updateRubric(newRubric)
        .then((data: any) => {
          props.onClose();
        })
        .catch((err: any) => {
          setError(err.errors?.message || err.message);
          scrollToError();
        });
    }
  };

  return (
    <FullScreenModal open={props.isOpen}>
      <PageContainer>
        <PageHeader title={<TranslatedText i18nKey="Rubric.Edit.Template.PageHeader" />} />
        <div ref={errorDisplayRef}>
          {error && <InlineInformation type="danger">{error}</InlineInformation>}
        </div>

        <TextField
          label={localize({ message: 'RubricManager.TemplateEdit.RubricTemplateLabel' })}
          testid="edit-rubric-template-title"
          value={templateTitle}
          onChange={onChangeTitle}
        />
        <RubricEdit rubric={rubric} onChange={setRubric} />
        <FormSubmitButtons>
          <Button
            type="primary"
            onClick={handleSubmit}
            unavailable={rubricUtils.getTotalCriteriaWeight(rubric) === 0}
            disabled={rubricUtils.getTotalCriteriaWeight(rubric) === 0}
            data-testid="save-rubric"
          >
            <TranslatedText i18nKey="Save" />
          </Button>
          <Button type="secondary" onClick={props.onClose}>
            <TranslatedText i18nKey="Cancel" />
          </Button>
        </FormSubmitButtons>

        {props.rubric !== rubric || templateTitle !== (rubric as any).name ? (
          <OnLeaveModal
            noRef={true}
            saveAction={handleSubmit}
            customButtonCopy={localize({
              message: 'RubricManager.TemplateEdit.SaveRubricTemplate',
            })}
          />
        ) : null}
      </PageContainer>
    </FullScreenModal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    authUser: selectAuthUser(state),
    rubric: selectRubric(state),
  };
};

export default connect(mapStateToProps, { updateRubric, createRubricTemplate })(TemplateEdit);
