import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'components/buttons';
import { RubricEdit, RubricView } from 'components/Rubric';
import { PageHeader } from 'components/layout';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import {
  getTotalCriteriaWeight,
  hasLessLevelsThanPassLevel,
  hasCriteriaOrLevelChanged,
} from '@kritik/utils/rubric';
import { closeRubricEdit, closeRubricView, selectRubricTemplate } from 'redux/activityEdit';
import { updateRubric } from 'actions/rubrics';
import { FIELD_NAME as RUBRIC } from 'components/ActivityEdit/Details/Rubric/constants';
import Menu from 'components/ActivityEdit/Rubric/Menu';
import OnLeaveModal from 'components/modals/OnLeaveModal';
import { selectIsEditingTemplate } from 'redux/rubricManager/selectors';
import { localize } from 'locales';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import NoticeBoard from 'components/layout/NoticeBoard';
import ConfirmModal from 'components/modals/ConfirmModal';
import { TranslatedText } from 'components/TranslatedText';

function Rubric(props: any) {
  const form = props.formRef.current;
  const formState = form.getState();
  const activityId = formState.values._id;
  const [rubric, setRubric] = useState(formState.values.rubric);
  const [newRubricTemplateName, setNewRubricTemplateName] = useState('');
  const [isNumericGrading, setNumericGrading] = useState(formState.values.isNumericGrading);
  const [shouldRescore, setShouldRescore] = useState(false);

  const isCalibrationActivity = formState.values.activityType === ACTIVITY_TYPES.CALIBRATION;

  function changeTemplates(rubric: string) {
    setRubric(rubric);
  }

  const saveFormValues = () => {
    const editedRubric = {
      ...rubric,
      // In the case were we are creating a calibration activity from a previous activity
      // as soon as we update the rubric we don't want to keep track of the assignment id
      assignment: null,
    };
    if (newRubricTemplateName) {
      editedRubric.name = newRubricTemplateName;
    }
    form.mutators.setValue(RUBRIC, editedRubric);
    form.mutators.setValue('isNumericGrading', isNumericGrading);
    if (isCalibrationActivity && shouldRescore) {
      const calibrationCreations = formState.values.calibrationCreations.map((creation: any) => {
        if (creation) {
          creation.marks = [];
        }
        return creation;
      });
      form.mutators.setValue('calibrationCreations', calibrationCreations);
    }
  };

  const handleDoneEditRubric = () => {
    if (
      formState.values.settings.isEdit &&
      !rubric.default &&
      // In case of a calibration activity, we don't want to save the rubric changes before the instructor
      // actually saves the edit activity form, because it would create some discrepancy
      // between the new rubric and the calibration creations scores
      // Note: Rubric is updated when the activity is saved anyway
      formState.values.activityType !== ACTIVITY_TYPES.CALIBRATION
    ) {
      props.updateRubric({
        ...rubric,
        criteria: rubric.criteria.map((c: any) => {
          return {
            ...c,
            weight: Number(c.weight),
          };
        }),
      });
    }
    saveFormValues();
    props.closeRubricEdit();
  };

  const isCriteriaOrLevelChanged = () => {
    return hasCriteriaOrLevelChanged(formState.values.rubric, rubric);
  };

  const handleShowRescoreNotice = () => {
    if (isCriteriaOrLevelChanged()) {
      setShouldRescore(true);
    } else {
      handleDoneEditRubric();
    }
  };

  const onNumericGradingSelect = (value: any) => {
    setNumericGrading(value);
  };

  const handleRubricEdit = (newRubric: any) => {
    setRubric(newRubric);
    props.selectRubricTemplate(null);
  };

  const renderRubric = () => {
    if (props.isEditing) {
      return (
        <React.Fragment>
          <PageHeader title={<TranslatedText i18nKey="Rubric.Edit.PageHeader" />} />
          {isCalibrationActivity && (
            <NoticeBoard
              type="caution"
              title={localize({
                message: 'Rubric.Edit.Save.RescoreWarningTitle',
              })}
            >
              {localize({
                message: 'Rubric.Edit.Save.RescoreWarning',
              })}
            </NoticeBoard>
          )}
          <Menu rubric={rubric} onChangeTemplate={changeTemplates} />
          <RubricEdit
            assignmentId={activityId}
            activityType="peer"
            savedRubricIdx={null}
            onNumericGradingSelect={onNumericGradingSelect}
            numericGrading={isNumericGrading}
            onChange={handleRubricEdit}
            input={{ value: rubric }}
            initValues={rubric}
            newRubricTemplateName={newRubricTemplateName}
            onNewRubricTemplateNameChange={setNewRubricTemplateName}
            showUnsavedWarning={!props.isEdit}
            criteriaLevel={
              rubric.passFailCondition ? rubric.passFailCondition === 'Criteria' : true
            }
            minimumPercentageValue={rubric.minimumPercentage}
          />

          <FormSubmitButtons>
            <Button
              type="primary"
              onClick={isCalibrationActivity ? handleShowRescoreNotice : handleDoneEditRubric}
              unavailable={getTotalCriteriaWeight(rubric) === 0}
              disabled={
                getTotalCriteriaWeight(rubric) === 0 ||
                (!isNumericGrading && hasLessLevelsThanPassLevel(rubric))
              }
              data-testid="close-rubric-view"
              label={localize({ message: 'Button.Label.SaveRubric' })}
            >
              <TranslatedText i18nKey="Save" />
            </Button>
            <Button
              type="secondary"
              onClick={props.closeRubricEdit}
              data-testid="cancel-rubric-edit"
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          </FormSubmitButtons>
          {!props.isSubmitting && !props.isEditingTemplate && formState.values.rubric !== rubric ? (
            <OnLeaveModal
              formRef={props.formRef}
              saveAction={handleDoneEditRubric}
              customButtonCopy={localize({ message: 'Rubric.Edit.SaveAndExit' })}
            />
          ) : null}
          <ConfirmModal
            isOpen={shouldRescore}
            title={localize({ message: 'Rubric.Edit.Save.Modal.RescoreCreationNotice.Title' })}
            testid="rescore_creation_modal"
            description={localize({
              message: 'Rubric.Edit.Save.Modal.RescoreCreationNotice.Description',
            })}
            cancelButton={localize({
              message: 'Cancel',
            })}
            confirmButton={localize({
              message: 'Rubric.Edit.Save.Modal.RescoreCreationNotice.Button.Save',
            })}
            onConfirm={handleDoneEditRubric}
            onCancel={() => {
              setShouldRescore(false);
            }}
            maxWidth="sm"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <PageHeader title={<TranslatedText i18nKey="Rubric.PageHeader" />} />
        <RubricView rubric={rubric} assignment={formState.values.settings.activity} />
        <FormSubmitButtons>
          <Button
            type="primary"
            onClick={props.closeRubricView}
            label={localize({ message: 'Button.Label.CloseRubricView' })}
          >
            <TranslatedText i18nKey="Close" />
          </Button>
        </FormSubmitButtons>
      </React.Fragment>
    );
  };

  if (!rubric) {
    return null;
  }

  return <div>{renderRubric()}</div>;
}

const mapStateToProps = (state: any) => {
  return {
    activityId: state.selected.activityId,
    isEditingTemplate: selectIsEditingTemplate(state),
  };
};

Rubric.defaultProps = {
  isEditing: false,
};

export default connect(mapStateToProps, {
  closeRubricEdit,
  closeRubricView,
  selectRubricTemplate,
  updateRubric,
})(Rubric);
