import { connect } from 'react-redux';
import { selectTableSettings } from 'redux/creationTable/select';
import { LIST_BY_TYPES } from 'components/CreationList/constant';
import { localize } from 'locales';

const TableTitle = (props: any) => {
  if (props.tableSettings.listBy === LIST_BY_TYPES.GROUP) {
    return localize({ message: 'Groups' });
  }
  return localize({ message: 'Students' });
};

const mapStateToProps = (state: any) => {
  return {
    tableSettings: selectTableSettings(state),
  };
};

export default connect(mapStateToProps, {})(TableTitle);
