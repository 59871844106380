import { Field } from 'react-final-form';
import RadioInput from 'components/core/input/Radio';
import FormFieldTitle from 'components/core/form/Title';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormField from 'components/core/form/Field';
import { localize } from 'locales';

const OPTIONS = {
  PERCENTAGE: {
    value: true,
    label: 'Percentage',
    testid: 'marking-schema-percentage-option',
  },
  POINTS: {
    value: false,
    label: 'Points',
    testid: 'marking-schema-points-option',
  },
};

const CourseScoringField = () => {
  const getOptions = () => {
    return Object.values(OPTIONS);
  };

  const setFieldValue = (e: any, onChange: any) => {
    const isEnabled = e.target.value === String(OPTIONS.PERCENTAGE.value);
    onChange(isEnabled);
  };

  return (
    <FormField size="lg">
      <FormFieldTitle label={localize({ message: 'Activity.ScoringTypeField.Label' })} />
      <Field name="isPercentage" required>
        {({ input }) => {
          return (
            <RadioInput
              options={getOptions()}
              onChange={(e: any) => {
                return setFieldValue(e, input.onChange);
              }}
              value={input.value}
              type="button"
              legendText="Activity.ScoringTypeField.Legend"
            />
          );
        }}
      </Field>
      <FormFieldInfo label={localize({ message: 'Activity.ScoringTypeField.Info' })} />
    </FormField>
  );
};

export default CourseScoringField;
