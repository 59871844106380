import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { navigateToActivityList } from 'actions/activity';
import { Button, ButtonContainer } from 'components/buttons';
import LinearProgress from 'components/Loaders/ProgressBar';
import VerticalList from 'components/layout/VerticalList';
import { InlineInformation } from 'components/layout';
import {
  selectSuccessfulImports,
  selectActivityIdsToImport,
  selectIsImporting,
} from 'redux/activityImport/select';
import { abortImportAction } from 'redux/activityImport/actions';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const LoadingModal = (props: any) => {
  const onClose = () => {
    props.navigateToActivityList({ courseId: props.courseId });
  };

  const getProgress = () => {
    return props.successfulImports.length / props.activitiesToImport.length;
  };

  const renderLoadingDialog = () => {
    return (
      <Dialog open maxWidth="xs" fullWidth>
        <DialogTitle>
          <TranslatedText i18nKey="ActivityImport.ProgressModal.ImportingActivities" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TranslatedText i18nKey="ActivityImport.ProgressModal.ThisShouldOnlyTakeAMoment" />
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <LinearProgress
            progress={getProgress() * 100}
            progressLabel={localize({
              message: 'ActivityImport.ProgressModal.ProgressLabel',
              options: {
                successful: props.successfulImports.length,
                total: props.activitiesToImport.length,
              },
            })}
          />
        </DialogContent>
        <DialogActions className="kritik-confirm__buttons">
          <ButtonContainer>
            <Button
              onClick={() => {
                return props.abortImportAction();
              }}
              type="secondary"
              autoFocus
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          </ButtonContainer>
        </DialogActions>
      </Dialog>
    );
  };

  const getSuccessBanner = () => {
    if (props.successfulImports.length) {
      return (
        <InlineInformation
          type="success"
          title={localize({
            message: 'ActivityImport.ProgressModal.SuccessBanner',
            options: {
              count: props.successfulImports.length,
            },
          })}
        />
      );
    }
    return null;
  };

  const getErrorBanner = () => {
    const failedToImport = props.activitiesToImport.length - props.successfulImports.length;
    if (failedToImport > 0) {
      return (
        <InlineInformation
          type="danger"
          title={localize({
            message: 'ActivityImport.ProgressModal.ErrorBanner',
            options: {
              count: failedToImport,
            },
          })}
        />
      );
    }
    return null;
  };

  const renderResultDialog = () => {
    return (
      <Dialog open maxWidth="xs" fullWidth aria-labelledby="activity-import-complete-title">
        <DialogTitle id="activity-import-complete-title">
          <TranslatedText i18nKey="ActivityImport.ProgressModal.ActivityImportComplete" />
        </DialogTitle>
        <DialogContent>
          <VerticalList space="xs" itemList={[getSuccessBanner(), getErrorBanner()]} />
        </DialogContent>
        <DialogActions className="kritik-confirm__buttons">
          <ButtonContainer>
            <Button
              onClick={onClose}
              type="secondary"
              testid="close-import-activity-modal"
              autoFocus
            >
              <TranslatedText i18nKey="ActivityImport.ProgressModal.Close" />
            </Button>
          </ButtonContainer>
        </DialogActions>
      </Dialog>
    );
  };

  if (!props.isOpen) {
    return null;
  }

  if (props.isImporting) {
    return renderLoadingDialog();
  }

  return renderResultDialog();
};

const mapStateToProps = (state: any) => {
  return {
    courseId: state.selected.courseId,
    successfulImports: selectSuccessfulImports(state),
    activitiesToImport: selectActivityIdsToImport(state),
    isImporting: selectIsImporting(state),
  };
};

export default connect(mapStateToProps, {
  navigateToActivityList,
  abortImportAction,
})(LoadingModal);
