import React, { useState } from 'react';
import { Button } from 'components/buttons';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import DT from 'react-datetime';
import * as FormatUtils from '@kritik/utils/format';
import { localize } from 'locales';

//workaround for https://github.com/vitejs/vite/issues/2139#issuecomment-802981228
const DateTime = (DT as any).default ? (DT as any).default : DT;

function CustomTimePicker({ onBack, onSet, initialValue }: any) {
  const [selectedTime, setSelectedTime] = useState(initialValue);

  function handleSet() {
    return onSet(selectedTime);
  }

  return (
    <div className="custom-time-picker__container">
      <p>Custom time</p>
      <DateTime
        dateFormat={false}
        open
        input={false}
        onChange={(date: any) => {
          return setSelectedTime(FormatUtils.readableTimeFormat(date));
        }}
        value={selectedTime}
      />
      <FormSubmitButtons>
        <Button
          type="primary"
          onClick={handleSet}
          testid="set-time-button"
          label={localize({ message: 'Button.Label.SetTime' })}
        >
          Set
        </Button>
        <Button type="secondary" onClick={onBack}>
          Back
        </Button>
      </FormSubmitButtons>
    </div>
  );
}

export default CustomTimePicker;
