import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createEvaluationFlag } from 'actions/activity';
import userSelector from 'selectors/user';
import * as creationStatus from '@kritik/utils/creation/status';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveEvaluation from 'components/Creation/Evaluate/Options/RemoveEvaluation';
import { localize } from 'locales';
import RestoreEvaluation from 'components/Creation/Evaluate/Options/RestoreEvaluation';

const ResolveMenu = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const submitResolution = (resolutionStatus: any) => {
    if (isSubmitting) {
    } else {
      setIsSubmitting(true);
      props
        .createEvaluationFlag({
          submissionId: props.creation._id,
          scoreId: props.evaluation._id,
          flag: resolutionStatus,
        })
        .then(() => {
          setIsSubmitting(false);
        });
    }
  };

  const resolveFlag = () => {
    submitResolution(creationStatus.EVAL_FLAGS.RESOLVED);
  };

  const dismissFlag = () => {
    submitResolution(creationStatus.EVAL_FLAGS.DISMISSED);
  };

  const handleChange = (event: any) => {
    if (isSubmitting) {
      return;
    }
    switch (event.target.value) {
      case creationStatus.EVAL_FLAGS.RESOLVED:
        return resolveFlag();
      case creationStatus.EVAL_FLAGS.DISMISSED:
        return dismissFlag();
      default:
        return null;
    }
  };

  const getLabel = () => {
    if (
      creationStatus.isCommentRemoved(props.evaluation) ||
      creationStatus.isCommentFlagDismissed(props.evaluation)
    ) {
      return localize({ message: 'Creation.Evaluation.ResolveMenu.Action.Change' });
    }
    return localize({ message: 'Creation.Evaluation.ResolveMenu.Action.Resolve' });
  };

  return (
    <Select
      className="flag-resolve-menu"
      value="default"
      disableUnderline
      open={open}
      onClick={() => setOpen(!open)}
      data-testid="flag-resolve-menu"
    >
      <MenuItem value="default" disabled>
        {getLabel()}
      </MenuItem>
      <RemoveEvaluation
        evaluation={props.evaluation}
        ActionComponent={MenuItem}
        actionTitle={localize({
          message: 'Creation.Evaluation.ResolveMenu.Action.InappropriateAndRemove',
        })}
        isInappropriate
        creation={props.creation}
        onClose={() => setOpen(false)}
        disabled={creationStatus.isCommentRemoved(props.evaluation)}
      />
      <RestoreEvaluation
        evaluation={props.evaluation}
        ActionComponent={MenuItem}
        actionTitle={localize({
          message: 'Creation.Evaluation.ResolveMenu.Action.NotInappropriate',
        })}
        isInappropriate
        creation={props.creation}
        onClose={() => setOpen(false)}
        disabled={creationStatus.isCommentFlagDismissed(props.evaluation)}
      />
    </Select>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: userSelector.selectAuthUser(state),
  };
};

export default connect(mapStateToProps, {
  createEvaluationFlag,
})(ResolveMenu);
