import React from 'react';
import classNames from 'classnames';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import NewTabButton from 'components/buttons/NewTab';
import { updateRubricHighlightedCriteria, openRubricDropdown } from 'actions/rubrics';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ModeLabel from 'components/layout/ModeLabel';
import { localize } from 'locales';
import { Rubric } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';

const renderLevelIcons = (level: any) => {
  const style = { marginRight: '10px' };
  if (level.length === 2) {
    return (
      <React.Fragment>
        <span>
          <ClearRoundedIcon style={style} />
        </span>
        <span>
          <CheckRoundedIcon style={style} />
        </span>
      </React.Fragment>
    );
  }
  return level.map((lvl: any, index: any) => {
    if (index === 0) {
      return (
        <span key={index}>
          <ClearRoundedIcon style={style} />
        </span>
      );
    }
    return (
      <span key={index}>
        <GradeRoundedIcon style={style} />
      </span>
    );
  });
};

type Props = {
  rubric: Rubric;
  hideViewRubricButton: boolean;
  canSelectRow: boolean;
  canViewMode: boolean;
  highlightedCriteria: () => void;
  form: any;
  updateRubricHighlightedCriteria: (index: number) => void;
  openRubricDropdown: (val: boolean) => void;
};
const Preview = (props: Props) => {
  const params = useParams();
  const { rubric } = props;

  if (!rubric) {
    return null;
  }

  const { criteria } = rubric;
  const levels = rubric.grid;

  const onClickFullRubric = () => {
    window.open(`/course/${params.courseId}/assignment/${rubric.assignment}/rubric/${rubric._id}`);
  };

  const getRowClass = (index: any) => {
    const className = classNames(`rubric-preview__row rubric-preview__row-${index}`, {
      'rubric-preview__row--selected': props.canSelectRow && props.highlightedCriteria === index,
      'rubric-preview__row--selectable': props.canSelectRow,
    });
    return className;
  };

  const setSelectedRow = (index: any) => {
    if (!props.canSelectRow) {
      return;
    }
    props.updateRubricHighlightedCriteria(index);
    props.openRubricDropdown(true);
  };

  return (
    <div className="rubric-preview-container">
      <table className="rubric-preview" data-testid="rubric-table">
        <caption className="visually-hidden" tabIndex={0}>
          <TranslatedText i18nKey="Rubric.Preview.Title" />
        </caption>
        <thead>
          <tr className="rubric-preview__header">
            <th id="criteria" scope="col">
              <TranslatedText i18nKey="Rubric.Preview.Criteria" />
            </th>
            <th id="levels" scope="col">
              <TranslatedText i18nKey="Rubric.Preview.Levels" />
            </th>
            <th className="rubric-preview__decimal" id="weight" scope="col">
              <TranslatedText i18nKey="Rubric.Preview.Weight" />
            </th>
          </tr>
        </thead>
        <tbody>
          {criteria.map((criterion: any, index: any) => {
            return (
              <tr
                key={criterion.name}
                className={getRowClass(index)}
                onClick={() => setSelectedRow(index)}
                onFocus={() => setSelectedRow(index)}
                aria-label={localize({
                  message: 'Rubric.Preview.CriterionRow',
                  options: { criterionName: criterion.name },
                })}
                data-testid={`rubric-criterion-${index + 1}`}
              >
                <td
                  className="rubric-preview__criterion-name"
                  headers="criteria"
                  aria-label={localize({ message: 'Rubric.Preview.CriteriaName' })}
                >
                  {criterion.name}
                </td>
                <td
                  className="rubric-preview__stars"
                  headers="levels"
                  aria-label={localize({ message: 'Rubric.Preview.CriteriaLevels' })}
                >
                  <div
                    role="img"
                    aria-label={localize({
                      message: 'Rubric.Preview.CriterionLevels',
                      options: {
                        name: criterion.name,
                        levels: levels[index].length.toString(),
                      },
                    })}
                  >
                    {renderLevelIcons(levels[index])}
                  </div>
                </td>
                <td
                  className="rubric-preview__decimal preview-table__weight"
                  headers="weight"
                  aria-label={localize({ message: 'Rubric.Preview.CriteriaWeight' })}
                >
                  {props.canViewMode && criterion.useMode && (
                    <ModeLabel
                      style="preview"
                      type="information"
                      location="top"
                      title={localize({ message: 'Rubric.Mode.Tooltip.Normal' })}
                      testid={'preview-rubric-mode-label'}
                    />
                  )}
                  <span style={{ float: 'right' }}>{criterion.weight}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.hideViewRubricButton ? null : (
        <div className="rubric-preview__footer">
          <div className="rubric-preview__footer-cell">
            <NewTabButton
              onClick={onClickFullRubric}
              label={localize({ message: 'Rubric.Preview.FullRubric' })}
              testid="full-rubric-student"
            />
          </div>
        </div>
      )}
    </div>
  );
};

Preview.defaultProps = {
  hideViewRubricButton: false,
  canSelectRow: false,
  highlightedCriteria: -1,
};

const mapStateToProps = (state: any) => {
  return {
    highlightedCriteria: state.rubric.rubrics.highlightedRubricCriteria,
  };
};

export default connect(mapStateToProps, {
  updateRubricHighlightedCriteria,
  openRubricDropdown,
})(Preview);
