import { useState } from 'react';
import AttachmentTypeField from 'components/ActivityEdit/AdvancedOptions/Fields/AttachmentType';
import AttachmentTypeReadOnly from 'components/ActivityEdit/AdvancedOptions/Fields/AttachmentType/ReadOnly';
import RequireAttachmentField from 'components/ActivityEdit/AdvancedOptions/Fields/RequireAttachment';
import RequireAttachmentReadOnly from 'components/ActivityEdit/AdvancedOptions/Fields/RequireAttachment/ReadOnly';
import WeightField from 'components/ActivityEdit/AdvancedOptions/Fields/Weight';
import WeightReadOnly from 'components/ActivityEdit/AdvancedOptions/Fields/Weight/ReadOnly';
import StartingScoreField from 'components/ActivityEdit/AdvancedOptions/Fields/StartingScore';
import { StartingScoreReadOnly } from 'components/ActivityEdit/AdvancedOptions/Fields/StartingScore/StartingScoreReadOnly';
import { Button } from 'components/buttons';
import { localize } from 'locales';
import { Activity, Course } from 'old-common/types.generated';
import { isEvaluateOrLater } from '@kritik/utils/stage';

type PresentationOptionsProps = {
  isDuplicatingActivity: boolean;
  activity: Activity;
  course: Course;
};

function PresentationOptions(props: PresentationOptionsProps) {
  const [showForm, setShowForm] = useState(false);
  return (
    <div className="activity-options">
      <div className="kritik-form-container">
        <h3>{localize({ message: 'Activity.Options.Title' })}</h3>
        {showForm ? (
          <OptionsForm
            isDuplicatingActivity={props.isDuplicatingActivity}
            activity={props.activity}
            course={props.course}
          />
        ) : (
          <OptionsReadOnly course={props.course} />
        )}
        <div>
          {!showForm && (
            <Button
              onClick={() => setShowForm(true)}
              type="secondary"
              testid="edit-activity-option"
            >
              {localize({ message: 'Activity.Options.Edit' })}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

type OptionsFormProps = {
  isDuplicatingActivity: boolean;
  activity: Activity;
  course: Course;
};
function OptionsForm(props: OptionsFormProps) {
  return (
    <>
      <AttachmentTypeField
        disabled={isEvaluateOrLater(props.activity) && !props.isDuplicatingActivity}
      />
      <RequireAttachmentField
        disabled={isEvaluateOrLater(props.activity) && !props.isDuplicatingActivity}
      />
      <WeightField />
      {props.course.markingScheme.isPercentage && (
        <StartingScoreField isDuplicatingActivity={props.isDuplicatingActivity} />
      )}
    </>
  );
}

type OptionsReadOnlyProps = {
  course: Course;
};

const OptionsReadOnly = ({ course }: OptionsReadOnlyProps) => {
  return (
    <div className="readonly-container">
      <AttachmentTypeReadOnly />
      <RequireAttachmentReadOnly />
      <WeightReadOnly />
      {course.markingScheme.isPercentage && <StartingScoreReadOnly />}
    </div>
  );
};

export default PresentationOptions;
