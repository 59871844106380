import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';
import { selectRubrics } from 'selectors/rubric';
import RubricList from 'components/RubricManager/SideMenu/List';
import * as rubricUtils from '@kritik/utils/rubric';
import CollapsibleContent from 'components/layout/CollapsibleContent';
import Typography from 'components/core/Typography';
import { setSelectedRubricId } from 'redux/rubricManager/actions';
import CreateNewTemplate from 'components/RubricManager/SideMenu/CreateNewTemplate';
import { selectRubricId, selectRubric } from 'redux/rubricManager/selectors';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const SideMenuItem = (props: any) => {
  return <div className="rubric-mngr-s-menu__item">{props.children}</div>;
};

const SideMenuItemContent = (props: any) => {
  return (
    <div className="rubric-mngr-s-menu__item-content" data-testid={props.testid}>
      {props.children}
    </div>
  );
};

const SideMenu = (props: any) => {
  if (_.isEmpty(props.userRubrics)) {
    return null;
  }

  const getMyRubricList = () => {
    return props.userRubrics.filter((rubric: any) => !rubricUtils.isDefaultRubric(rubric));
  };

  const getDefaultRubricList = () => {
    return props.userRubrics.filter((rubric: any) => rubricUtils.isDefaultRubric(rubric));
  };

  const renderTitle = (title: any) => {
    return (
      <div className="rubric-mngr-s-menu__title">
        <Typography fontSize="14px" color="light" weight="400">
          {title}
        </Typography>
      </div>
    );
  };

  const handleRubricSelect = (rubricId: any) => {
    props.setSelectedRubricId(rubricId);
  };

  useEffect(() => {
    if (!props.rubric) {
      const firstOfMine = getMyRubricList()[0];
      const firstOfDefault = getDefaultRubricList()[0];

      if (firstOfMine) {
        props.setSelectedRubricId(firstOfMine._id);
      } else {
        props.setSelectedRubricId(firstOfDefault._id);
      }
    }
  }, [getMyRubricList().length]);

  return (
    <div className="rubric-mngr-s-menu">
      <SideMenuItem>
        <CollapsibleContent
          title={renderTitle(<TranslatedText i18nKey="RubricManager.SideMenu.MyTemplates" />)}
          content={
            <SideMenuItemContent testid="my-templates-list">
              <RubricList
                rubricList={getMyRubricList()}
                onSelect={handleRubricSelect}
                selectedId={props.rubricId}
              />
              <div className="create-new-template-btn">
                <CreateNewTemplate />
              </div>
            </SideMenuItemContent>
          }
          defaultExpanded
        />
      </SideMenuItem>
      <SideMenuItem>
        <CollapsibleContent
          title={renderTitle(<TranslatedText i18nKey="RubricManager.SideMenu.CoreTemplates" />)}
          content={
            <SideMenuItemContent testid="core-templates-list">
              <RubricList
                rubricList={getDefaultRubricList()}
                onSelect={handleRubricSelect}
                selectedId={props.rubricId}
              />
            </SideMenuItemContent>
          }
          defaultExpanded
          testid="rubric-core-template-menu"
        />
      </SideMenuItem>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    userRubrics: selectRubrics(state),
    rubricId: selectRubricId(state),
    rubric: selectRubric(state),
  };
};

SideMenu.defaultProps = {
  userRubrics: [],
};

export default connect(mapStateToProps, { setSelectedRubricId })(SideMenu);
