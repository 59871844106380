import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { CreationView } from 'components/Creation/Create/View';
import NoticeBoard from 'components/layout/NoticeBoard';
import { isPresentationActivity } from '@kritik/utils/activity';
import { TranslatedText } from 'components/TranslatedText';

const CreationContent = ({ creation, activity, children }: any) => {
  if (
    CreationStatusUtil.wasCreationMissed(creation) &&
    !CreationStatusUtil.creationHasProfComment(creation)
  ) {
    return (
      <NoticeBoard
        type="danger"
        title={<TranslatedText i18nKey="CreationContent.MissedCreationTitle" />}
      >
        <TranslatedText i18nKey="CreationContent.MissedCreationMessage" />
        {!isPresentationActivity(activity) && (
          <TranslatedText i18nKey="CreationContent.MissedCreationNoEvaluatorsMessage" />
        )}
      </NoticeBoard>
    );
  }
  return <CreationView submission={creation}>{children}</CreationView>;
};

export default CreationContent;
