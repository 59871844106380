import React, { Component, forwardRef, createContext } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';
import moment from 'moment';
import classNames from 'classnames';
import Confirm from 'components/modals/ConfirmModal';
import { removeStudent, reEnrollStudentInCourse } from 'actions/courses';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { getCourse } from 'selectors/course';
import { getStudentListFromSelectedCourse } from 'selectors/student';
import { getNewKS } from 'utils/student';
import { FixedSizeList as VirtualizedList } from 'react-window';
import SORT_ARROWS from 'images/sort-arrows';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import EmptyStudentListPlaceholder from 'components/RosterManager/Placeholders';
import ViewAsStudentModal from '../ViewAsStudentModal';
import { handleSetStudentViewMutation } from 'hooks/user';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';
import { isCourseConnected } from 'utils/lms';
import { Course, Student } from 'old-common/types.generated';
import { InlineInformation } from 'components/layout';
import ElipsesMenu from 'components/General/ElipsesMenu';
import Search from 'components/core/input/Search';

const ListContext = createContext(null);
ListContext.displayName = 'ListContext';

const ROW_HEIGHT = 65;

type Props = {
  student: any;
  handleRemoveStudent: (student: any) => void;
  handleReEnrollStudent: (student: any) => void;
  handleModalOpen: () => void;
  course: Course;
};

const StudentMenu = ({
  student,
  handleRemoveStudent,
  handleReEnrollStudent,
  handleModalOpen,
  course,
}: Props) => {
  const options: {
    label: string;
    action: () => void;
    testid: string;
  }[] = [];
  if (!student.removedOn) {
    options.push({
      label: localize({ message: 'StudentView.Menu.Label' }),
      action: handleModalOpen,
      testid: `view-as-student-${student._id}`,
    });
    if (!isCourseConnected(course)) {
      options.push({
        label: localize({ message: 'RosterManager.StudentsTab.Menu.RemoveStudent' }),
        action: () => {
          return handleRemoveStudent(student);
        },
        testid: `remove-student-${student._id}`,
      });
    }
  } else if (!isCourseConnected(course)) {
    options.push({
      label: localize({ message: 'RosterManager.StudentsTab.Menu.ReEnrollStudent' }),
      action: () => {
        return handleReEnrollStudent(student);
      },
      testid: `re-enroll-student-${student._id}`,
    });
  }

  return (
    <ElipsesMenu
      options={options}
      testid="instructor-student-menu"
      labelI18nKey="RosterManager.StudentsTab.MoreOptionsMenu.Label"
    />
  );
};

export const HeaderCell = ({ children, onClick, className }: any) => {
  const headerCellClasses = classNames('student-list-header__cell', className);
  return (
    <div className={headerCellClasses} onClick={onClick}>
      {children}
    </div>
  );
};

// @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type '{}'.
const HeaderRow = forwardRef(({ children, ...rest }, ref) => {
  const headerClasses = classNames('student-list__row', 'student-list__header');
  const getStyle = (index: any) => {
    return {
      top: index * ROW_HEIGHT - 1,
      left: 0,
      width: '100%',
      height: ROW_HEIGHT,
    };
  };
  const headerColumns = [
    {
      className: 'student-list__row-name',
      name: localize({ message: 'RosterManager.StudentsTab.Headers.Name' }),
    },
    {
      className: 'student-list__row-email',
      name: localize({ message: 'RosterManager.StudentsTab.Headers.Email' }),
    },
    {
      className: 'student-list__row-status',
      name: localize({ message: 'RosterManager.StudentsTab.Headers.Status' }),
    },
    {
      className: 'student-list__row-date',
      name: localize({ message: 'RosterManager.StudentsTab.Headers.Date' }),
    },
  ];
  return (
    <ListContext.Consumer>
      {({ stickyIndices, handleSortingSelection, sortBy, sortAscending }: $TSFixMe) => {
        const renderSortIcon = (columnName: any) => {
          if (columnName !== sortBy) {
            return;
          }
          return sortAscending ? (
            <img alt="sort ascending" src={SORT_ARROWS.ASCENDING} />
          ) : (
            <img alt="sort descending" src={SORT_ARROWS.DESCENDING} />
          );
        };
        return (
          // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
          <div ref={ref} {...rest}>
            {stickyIndices.map((index: any) => {
              return (
                <div key={index} className={headerClasses} style={getStyle(index)}>
                  {headerColumns.map((column) => {
                    return (
                      <HeaderCell
                        key={column.name}
                        className={column.className}
                        onClick={() => {
                          return handleSortingSelection(column.name);
                        }}
                      >
                        {column.name} {renderSortIcon(column.name)}
                      </HeaderCell>
                    );
                  })}
                  <div className="student-list__row-menu"> </div>
                </div>
              );
            })}
            {children}
          </div>
        );
      }}
    </ListContext.Consumer>
  );
});

const Row = ({ data, index, style }: any) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { stickyIndices } = data;
  if (stickyIndices && stickyIndices.includes(index)) {
    return null;
  }
  const student = data.studentList[index];
  if (!student.user) {
    return null;
  }
  const studentStatus = student.removedOn ? 'Removed' : 'Enrolled';
  const dateStatus = student.removedOn ? student.removedOn : student.createdAt;

  const rowClasses = classNames('student-list__row', {
    'student-list__row--removed': Boolean(student.removedOn),
  });

  return (
    <div
      data-testid={
        Boolean(student.removedOn)
          ? `removed-students-row-${student._id}`
          : `enrolled-students-row-${student._id}`
      }
      key={student._id}
      className={rowClasses}
      style={style}
      tabIndex={0}
    >
      <ViewAsStudentModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        student={{
          name: student.user.profile.name,
          id: student._id,
          email: student.user.email,
        }}
        redirectUrl={`/course/${student.course}/assignments`}
      />
      <div className="student-list__row-name">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AvatarDisplay
            user={student.user}
            kritikScore={getNewKS(student)}
            size="50px"
            style={{ marginRight: 5 }}
          />
          <p>
            {student.user.profile.firstName} {student.user.profile.lastName}
          </p>
        </div>
      </div>
      <div className="student-list__row-email"> {student.user.email} </div>
      <div className="student-list__row-status"> {studentStatus} </div>
      <div className="student-list__row-date">{moment(dateStatus).fromNow()}</div>
      <div className="student-list__row-menu">
        <StudentMenu
          student={student}
          handleRemoveStudent={data.handleRemoveStudent}
          handleModalOpen={() => setIsModalOpen(true)}
          handleReEnrollStudent={data.handleReEnrollStudent}
          course={data.course}
        />
      </div>
    </div>
  );
};

const List = ({
  children,
  stickyIndices,
  itemData,
  studentList,
  handleRemoveStudent,
  handleReEnrollStudent,
  handleSortingSelection,
  sortBy,
  sortAscending,
  course,
  ...rest
}: any) => {
  return (
    <ListContext.Provider
      value={{
        ItemRenderer: children,
        stickyIndices,
        handleSortingSelection,
        sortBy,
        sortAscending,
      }}
    >
      <VirtualizedList
        itemData={{
          ItemRenderer: children,
          stickyIndices,
          studentList,
          handleRemoveStudent,
          handleReEnrollStudent,
          course,
        }}
        {...rest}
      >
        {children}
      </VirtualizedList>
    </ListContext.Provider>
  );
};

type StudentListState = {
  sortBy: string;
  sortAscending: boolean;
  confirm: boolean;
  deleteStudent: Student;
  reEnrollStudent: Student;
  confirmReEnroll: boolean;
  loading: boolean;
  search: string;
};

type StudentListProps = {
  hasFirstSuccessfulSync: boolean;
  loadingBar: {
    default: number;
  };
  studentList: Student[];
  course: Course;
  removeStudent: ({ courseId, user }: { courseId: string; user: any }) => void;
  reEnrollStudentInCourse: ({
    studentId,
    courseId,
  }: {
    studentId: string;
    courseId: string;
  }) => void;
};

class StudentList extends Component<StudentListProps, StudentListState> {
  constructor(props: StudentListProps) {
    super(props);
    this.state = {
      sortBy: 'Date',
      sortAscending: true,
      confirm: false,
      deleteStudent: null,
      reEnrollStudent: null,
      confirmReEnroll: false,
      loading: true,
      search: '',
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps: StudentListProps, prevState: StudentListState) {
    if (this.state.loading === false && this.props.loadingBar.default !== 0) {
      this.setState({ loading: true });
    } else if (this.state.loading && this.props.loadingBar.default === 0) {
      this.setup();
    } else if (
      this.state.sortBy !== prevState.sortBy ||
      this.state.sortAscending !== prevState.sortAscending ||
      this.state.search !== prevState.search
    ) {
      this.setup();
    }
  }

  setup() {
    this.setState({ loading: false });
  }

  handleRemoveStudent = (user: any) => {
    this.props.removeStudent({ courseId: this.props.course._id, user });
  };

  handleReEnrollStudent = (student: Student) => {
    this.props.reEnrollStudentInCourse({
      studentId: student._id,
      courseId: this.props.course._id,
    });
  };

  sortStudents(students: any) {
    const ascending = this.state.sortAscending ? 1 : -1;
    const _students = [...students];

    switch (this.state.sortBy) {
      case 'Date':
        return this.state.sortAscending ? _students.reverse() : _students;
      case 'Name':
        return _students.sort((student1, student2) => {
          if (student1.user.profile.lastName < student2.user.profile.lastName) {
            return 1 * ascending;
          }
          return -1 * ascending;
        });
      case 'Email':
        return _students.sort((student1, student2) => {
          if (student1.user.email < student2.user.email) {
            return 1 * ascending;
          }
          return -1 * ascending;
        });
      default:
        return _students.sort((student1, student2) => {
          if (student1.removedOn < student2.removedOn) {
            return 1 * ascending;
          }
          return -1 * ascending;
        });
    }
  }

  handleSortingSelection(sortBy: any) {
    const sortAscending = sortBy === this.state.sortBy ? !this.state.sortAscending : false;
    this.setState({
      sortBy,
      sortAscending,
    });
  }

  renderDeleteConfirmation() {
    return (
      <Confirm
        isOpen={this.state.confirm}
        onCancel={() => {
          return this.setState({ confirm: false, deleteStudent: null });
        }}
        onConfirm={() => {
          return this.setState(
            { confirm: false },
            // @ts-expect-error TS(2345) FIXME: Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
            this.handleRemoveStudent(this.state.deleteStudent)
          );
        }}
        cancelButton="No"
        confirmButton="Yes"
        title="Remove this student from the course?"
      />
    );
  }

  handleReEnrollConfirmation() {
    this.setState({ confirmReEnroll: false });
    this.handleReEnrollStudent(this.state.reEnrollStudent);
  }

  renderReEnrollConfirmation() {
    return (
      <Confirm
        isOpen={this.state.confirmReEnroll}
        maxWidth="md"
        onCancel={() => {
          return this.setState({ confirmReEnroll: false, reEnrollStudent: null });
        }}
        onConfirm={() => this.handleReEnrollConfirmation()}
        cancelButton={localize({
          message: 'RosterManager.ReEnrollStudentModal.CancelButton',
        })}
        confirmButton={localize({
          message: 'RosterManager.ReEnrollStudentModal.ConfirmationButton',
        })}
        title={localize({
          message: 'RosterManager.ReEnrollStudentModal.Title',
        })}
      >
        <span>{localize({ message: 'RosterManager.ReEnrollStudentModal.Content1' })}</span>
        <ul>
          <li>{localize({ message: 'RosterManager.ReEnrollStudentModal.Content2' })}</li>
          <li>{localize({ message: 'RosterManager.ReEnrollStudentModal.Content3' })}</li>
          <li>{localize({ message: 'RosterManager.ReEnrollStudentModal.Content4' })}</li>
          <li>{localize({ message: 'RosterManager.ReEnrollStudentModal.Content5' })}</li>
          <li>{localize({ message: 'RosterManager.ReEnrollStudentModal.Content6' })}</li>
        </ul>
      </Confirm>
    );
  }

  renderSortingOptions() {
    const { course } = this.props;
    return (
      <div className="student-list__options" tabIndex={0}>
        <Search
          className="student-list__search"
          onSearch={(value) => this.setState({ search: value })}
          placeholder={localize({ message: 'RosterManager.Pending.Search.Placeholder' })}
          label={localize({ message: 'RosterManager.Pending.Search.Label' })}
        />
        <span className="student-list__count">
          <div>
            <TranslatedText
              i18nKey="RosterManager.StudentsTab.Enrolled.Count"
              values={{ count: course.students.length }}
            />
          </div>
        </span>
      </div>
    );
  }

  handleRemoveRequest(student: any) {
    this.setState({
      confirm: true,
      deleteStudent: student,
    });
  }

  handleReEnrollRequest(student: any) {
    this.setState({
      confirmReEnroll: true,
      reEnrollStudent: student,
    });
  }

  getStickyIndices() {
    // define header rows
    return [0];
  }

  handleSearchChange(students: any) {
    const re = new RegExp(_.escapeRegExp(this.state.search), 'i');
    const isMatch = (student: any) => {
      return re.test(student.user.profile.name) || re.test(student.user.email);
    };
    return _.filter(students, isMatch);
  }

  getListToRender() {
    const sortedStudentList = this.sortStudents(this.props.studentList);
    const filteredStudentList = this.handleSearchChange(sortedStudentList);
    // add empty item for header row
    return [{}, ...filteredStudentList];
  }

  getListHeight() {
    const NUM_OF_ROWS = 10;
    return NUM_OF_ROWS * ROW_HEIGHT;
  }

  render() {
    const listToRender = this.getListToRender();
    if (!this.state.loading) {
      if (this.props.studentList.length === 0) {
        return (
          <EmptyStudentListPlaceholder
            course={this.props.course}
            hasFirstSuccessfulSync={this.props.hasFirstSuccessfulSync}
          />
        );
      }
    }
    return (
      <div className="student-list-container">
        {isCourseConnected(this.props.course) && (
          <InlineInformation type="information" className="information-student-tab">
            {localize({ message: 'RosterManager.ConnectedCourse.StudentsTab.PreventMismatch' })}
          </InlineInformation>
        )}
        {this.renderDeleteConfirmation()}
        {this.renderReEnrollConfirmation()}
        {this.renderSortingOptions()}
        <div className="student-list">
          <LoaderOverlay color="white" isOpen={this.state.loading} />
          <List
            height={this.getListHeight()}
            width="100%"
            innerElementType={HeaderRow}
            itemCount={listToRender.length}
            studentList={listToRender}
            course={this.props.course}
            handleRemoveStudent={this.handleRemoveRequest.bind(this)}
            handleReEnrollStudent={this.handleReEnrollRequest.bind(this)}
            handleSortingSelection={this.handleSortingSelection.bind(this)}
            sortBy={this.state.sortBy}
            sortAscending={this.state.sortAscending}
            itemSize={ROW_HEIGHT}
            stickyIndices={this.getStickyIndices()}
          >
            {Row}
          </List>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
    studentList: getStudentListFromSelectedCourse(state),
    loadingBar: state.loadingBar,
  };
};

export default connect(mapStateToProps, {
  removeStudent,
  reEnrollStudentInCourse,
})(StudentList);
