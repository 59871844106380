import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  selectRubricTemplate,
  selectSelectedRubricTemplate,
  showRubricEdit,
} from 'redux/activityEdit';
import Select from 'components/core/form/SelectField';
import { selectRubrics } from 'selectors/rubric';
import { listRubrics } from 'actions/rubrics';
import { localize } from 'locales';

function Preview(props: any) {
  const { userRubrics, listRubrics, onSelectRubric } = props;

  useEffect(() => {
    if (userRubrics.length === 0) {
      listRubrics();
    }
  }, []);

  useEffect(() => {
    if (!props.rubric && props.selectedRubricId) {
      setRubricContent(props.selectedRubricId);
    }
  });

  useEffect(() => {
    if (!props.selectedRubricId && !props.rubric) {
      let rubricId = null;
      if (userRubrics && userRubrics.length > 0) {
        rubricId = userRubrics[0]._id;
      }
      props.selectRubricTemplate(rubricId);
      setRubricContent(rubricId);
    }
  }, [userRubrics.length]);

  const getSelectionOptions = () => {
    let options: any = [];
    if (userRubrics) {
      const generatedOptions = userRubrics.map((rubric: any, index: number) => {
        return {
          label: rubric.name,
          value: rubric._id,
          testid: `rubric-option-${rubric.name}`,
        };
      });
      options = options.concat(generatedOptions);
    }
    return options;
  };

  const setRubricContent = (rubricId: any) => {
    const template = userRubrics.find((rubric: any) => {
      return rubric._id === rubricId;
    });
    onSelectRubric(template);
  };

  const changeTemplates = (e: any) => {
    const rubricId = e.target.value;
    setRubricContent(rubricId);
    props.selectRubricTemplate(rubricId);
  };

  const getSelectedRubric = () => {
    return props.selectedRubricId;
  };

  return (
    <>
      <Select
        label={localize({ message: 'Rubric.SelectTemplate.Label' })}
        value={getSelectedRubric()}
        onChange={changeTemplates}
        placeholder={localize({ message: 'Rubric.SelectTemplate.Placeholder' })}
        options={getSelectionOptions()}
        testid="rubric-select"
        id="rubric-select"
      />
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userRubrics: selectRubrics(state),
    selectedRubricId: selectSelectedRubricTemplate(state),
  };
};

export default connect(mapStateToProps, {
  showRubricEdit,
  listRubrics,
  selectRubricTemplate,
})(Preview);
