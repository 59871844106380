import React from 'react';
import classNames from 'classnames';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import NoticeBoard from 'components/layout/NoticeBoard';
import { getNewKS } from 'utils/student';
import CalibrationGradeView from 'components/Assignment/Edit/Calibration/GradeView';
import { CreationView } from 'components/Creation/Create/View';
import Modal from 'components/modals/Components/Modal';
import ModalTitle from 'components/modals/Components/Header';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import { RubricView } from 'components/Rubric';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';
import { Activity, Group, Rubric, Student, Creation, User } from 'old-common/types.generated';

type OwnState = any;

type State = OwnState & typeof CalibrationCreationSelector.defaultProps;

type CalibrationCreationSelectorProps = {
  creations?: Creation[];
  assignment?: Activity;
  sourceRubric: Rubric;
  rubric: Rubric;
  users: User[];
  isGroupActivity: boolean;
  groups: Group[];
  onGradeSelect: (score: any) => void;
  students: Student[];
  existingScores: any[];
};

class CalibrationCreationSelector extends React.Component<CalibrationCreationSelectorProps, State> {
  static defaultProps = {
    creations: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedCreation: null,
      rubricModal: false,
      sortedCreations: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const sortedCreations = this.sortCreationList(this.props.creations);
    this.setState({ sortedCreations, selectedCreation: sortedCreations[0]._id });
  }

  sortCreationList(creationList: any) {
    const sortedList = creationList.sort((sub1: any, sub2: any) => {
      if (
        CreationScoreUtil.calcAvgCreationScore({
          creation: sub1,
          rubric: this.props.sourceRubric,
          startingScore: this.props.assignment.startingScore,
          lateCreationPenaltyPercentage: this.props.assignment.lateCreationPenaltyPercentage,
        }) <
        CreationScoreUtil.calcAvgCreationScore({
          creation: sub2,
          rubric: this.props.sourceRubric,
          startingScore: this.props.assignment.startingScore,
          lateCreationPenaltyPercentage: this.props.assignment.lateCreationPenaltyPercentage,
        })
      ) {
        return 1;
      }
      return -1;
    });
    return sortedList;
  }

  renderCreationScore = (creation: any) => {
    const avgScore = CreationScoreUtil.calcAvgCreationScore({
      creation,
      rubric: this.props.sourceRubric,
      startingScore: this.props.assignment.startingScore,
      lateCreationPenaltyPercentage: this.props.assignment.lateCreationPenaltyPercentage,
    });
    return <span>{`${avgScore}%`}</span>;
  };

  renderCreationList() {
    const { users, students, isGroupActivity, groups } = this.props;

    const UserName = ({ userName }: any) => {
      return <p style={{ margin: '0 10px' }}>{userName}</p>;
    };

    const getClasses = (creation: any) => {
      const classes = classNames('creation-list__row', {
        'creation-list__row--selected': this.state.selectedCreation == creation._id,
      });
      return classes;
    };

    return (
      <div className="creation-list">
        {this.state.sortedCreations.map((creation: any, index: any) => {
          return (
            <div
              onClick={() => {
                return this.setState({ selectedCreation: creation._id });
              }}
              key={creation.user}
              className={getClasses(creation)}
            >
              <div className="creation-list__item" data-testid={`past-creation-${index}`}>
                <AvatarDisplay
                  user={users[creation.user]}
                  kritikScore={getNewKS(students[creation.student])}
                  groupCard={isGroupActivity}
                  group={groups[creation.group]}
                  size="40px"
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <UserName
                    userName={
                      groups[creation.group]
                        ? groups[creation.group].name
                        : users[creation.user].profile.name
                    }
                  />
                  {this.renderCreationScore(creation)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  setNewGrade(score: any) {
    return this.props.onGradeSelect(score);
  }

  renderSelectedCreation() {
    if (!this.state.selectedCreation) {
      return null;
    }
    const selectedCreation = this.props.creations.filter((creation: any) => {
      return creation._id == this.state.selectedCreation;
    })[0];

    let marks: any = [];
    this.props.existingScores.forEach((existingScore: any) => {
      if (existingScore) {
        if (existingScore._id == selectedCreation._id) {
          marks = existingScore.marks;
        }
      }
    });

    return (
      <React.Fragment>
        <NoticeBoard
          attention
          title={localize({ message: 'CalibrationCreationSelector.CalibratingGradesNotice' })}
        >
          <TranslatedText i18nKey="CalibrationCreationSelector.CalibratingGradesDescription" />
        </NoticeBoard>
        <h3>
          <TranslatedText i18nKey="CalibrationCreationSelector.Creation" />
        </h3>
        <CreationView submission={selectedCreation} disableViewInstructorComment />
        <CalibrationGradeView
          assignment={this.props.assignment}
          showRubric={() => {
            return this.setState({ rubricModal: true });
          }}
          onGradeSelect={(score: any) => {
            return this.setNewGrade(score);
          }}
          creation={selectedCreation}
          rubric={this.props.rubric}
          marks={marks}
        />
      </React.Fragment>
    );
  }

  renderRubricModal() {
    return (
      <Modal maxWidth="lg" isFullWidth isOpen={this.state.rubricModal}>
        <ModalTitle title={localize({ message: 'CalibrationCreationSelector.ActivityRubric' })} />
        <ModalContent>
          <RubricView rubric={this.props.rubric} />
        </ModalContent>
        <ModalAction
          actions={[
            {
              label: localize({ message: 'Close' }),
              ariaLabel: localize({ message: 'Button.Label.CloseRubricModal' }),
              type: 'primary',
              onClick: () => {
                this.setState({ rubricModal: false });
              },
            },
          ]}
        />
      </Modal>
    );
  }

  render() {
    return (
      <div className="calibration-creation-selector">
        {this.renderRubricModal()}
        <div className="calibration-creation-selection-column-1">{this.renderCreationList()}</div>
        <div className="calibration-creation-selection-column-2">
          {this.renderSelectedCreation()}
        </div>
      </div>
    );
  }
}

export default CalibrationCreationSelector;
