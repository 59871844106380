import React from 'react';
import { connect } from 'react-redux';
import { createNewBasicTemplate } from 'redux/rubricManager/actions';
import Button from 'components/buttons/Button';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const CreateNewTemplateButton = (props: any) => {
  return (
    <Button
      type="hidden"
      onClick={props.createNewBasicTemplate}
      data-testid="create-rubric-template"
    >
      <TranslatedText i18nKey="RubricManager.SideMenu.CreateNewTemplate.ButtonText" />
    </Button>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { createNewBasicTemplate })(CreateNewTemplateButton);
