import { connect } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Filter from 'components/CreationList/Menu/Filter';
import SortBy from 'components/CreationList/Menu/SortBy';
import { getAssignment } from 'selectors/activity';
import {
  setTableListBy,
  setCreationTableFilter,
  setCurrentPage,
} from 'redux/creationTable/actions';
import { selectTableSettings } from 'redux/creationTable/select';
import { MenuItem } from 'components/CreationList/Menu/MiniMenu/Item';
import * as StatusUtils from '@kritik/utils/stage';
import getPaginatedResults from 'actions/pagination/actions';
import { getUpdatedPath } from 'components/CreationList/PaginationMenu/utils';
import { navigateToActivityPath } from 'actions/activity';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const MiniMenu = (props: any) => {
  const renderSortMenu = () => {
    return <SortBy />;
  };

  const handleFilterChange = (e: any) => {
    props.setCreationTableFilter(e.target.value);
    props.setCurrentPage(1);
    const newUrl = getUpdatedPath(window.location, { param: 'filterBy', value: e.target.value });
    props.navigateToActivityPath(newUrl);
    props.getPaginatedResults();
  };
  const renderFilterMenu = () => {
    const renderFilters =
      StatusUtils.isInCreationGracePeriod(props.activity) ||
      StatusUtils.isEvaluateOrLater(props.activity);
    return (
      renderFilters && (
        <Filter
          label={localize({ message: 'MiniMenu.FilterBy' })}
          onFilterChange={handleFilterChange}
        />
      )
    );
  };

  const renderNaviModal = () => {
    return (
      <ClickAwayListener
        onClickAway={props.onClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div className="creation-table-mini-menu">
          <h5>
            <TranslatedText i18nKey="MiniMenu.SortAndFilter" />
          </h5>
          <MenuItem>{renderSortMenu()}</MenuItem>
          <MenuItem>{renderFilterMenu()}</MenuItem>
        </div>
      </ClickAwayListener>
    );
  };

  return renderNaviModal();
};

const mapStateToProps = (state: any) => {
  return {
    tableSettings: selectTableSettings(state),
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {
  setTableListBy,
  setCreationTableFilter,
  getPaginatedResults,
  setCurrentPage,
  navigateToActivityPath,
})(MiniMenu);
