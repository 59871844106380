import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonContainer } from 'components/buttons';
import { selectSelectedRubricTemplate, showRubricEdit, showRubricView } from 'redux/activityEdit';
import { selectRubrics } from 'selectors/rubric';
import TemplateSelector from 'components/Rubric/TemplateSelector';
import FormField from 'components/core/form/Field';
import RubricPreview from 'components/Rubric/Preview';
import RubricDescription from 'components/ActivityEdit/Rubric/Preview/Description';
import RubricPassFail from 'components/ActivityEdit/Rubric/Preview/PassFail';
import CreateRubricAI from 'components/ActivityEdit/Rubric/Preview/CreateRubricAI';
import { localize } from 'locales';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import Spinner from 'components/Loaders/Spinner';

function Preview(props: any) {
  const isCreateRubricAIEnabled = useIsFeatureFlagEnabled('create_rubric_ai');
  const { rubric } = props;
  const [showGeneratingRubric, setShowGeneratingRubric] = useState(false); // show "generating rubric" screen
  const [showRubric, setShowRubric] = useState(true); // show rubric preview

  function changeTemplates(rubric: any) {
    props.onSelectRubric(rubric);
    if (props.handleUpdateRubricNotice) {
      props.handleUpdateRubricNotice();
    }
  }

  function setLoading(state: boolean) {
    setShowGeneratingRubric(state);
    setShowRubric(!state);
  }

  function handleSelectRubric(rubricId: any) {
    if (props.clearCalibrationCreationMarks) {
      props.clearCalibrationCreationMarks();
    }
    return changeTemplates(rubricId);
  }

  return (
    <>
      {props.canEditRubric && props.onSelectRubric && (
        <div className="select-or-generate-columns">
          <div className="left">
            <TemplateSelector
              label={localize({ message: 'Label.FormControl.RubricTemplate' })}
              rubric={rubric}
              onSelectRubric={handleSelectRubric}
            />
          </div>
          {isCreateRubricAIEnabled && <div className="center">{localize({ message: 'or' })}</div>}
          {isCreateRubricAIEnabled && (
            <div className="right">
              <CreateRubricAI
                form={props.form}
                onSelectRubric={handleSelectRubric}
                setLoading={setLoading}
              ></CreateRubricAI>
            </div>
          )}
        </div>
      )}
      {showGeneratingRubric && (
        <div className="rubric-preview-container">
          <div className="generating-rubric">
            <Spinner size="50px" />
            {localize({ message: 'Activity.CreateEdit.RubricSection.GenerateAI.Loading' })}
          </div>
        </div>
      )}
      {showRubric && rubric && (
        <FormField>
          <RubricDescription rubric={rubric} />
          <RubricPreview
            rubric={rubric}
            hideViewRubricButton
            canSelectRow={false}
            canViewMode={props.canViewMode}
            form={props.form}
          />
          <RubricPassFail rubric={rubric} isNumericGrading={props.isNumericGrading} />
          {props.canEditRubric && (
            <ButtonContainer>
              <Button
                type="secondary"
                onClick={props.showRubricEdit}
                data-testid="activity_edit-rubric"
              >
                {localize({ message: 'Rubric.Edit.Button.Text' })}{' '}
                <i className="fas fa-arrow-right"></i>
              </Button>
            </ButtonContainer>
          )}
          {!props.canEditRubric && (
            <Button
              type="secondary"
              onClick={props.showRubricView}
              data-testid="activity_view-rubric"
            >
              {localize({ message: 'Rubric.View.Button.Text' })}
            </Button>
          )}
        </FormField>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    userRubrics: selectRubrics(state),
    selectedRubricId: selectSelectedRubricTemplate(state),
  };
};

Preview.defaultProps = {
  canEditRubric: false,
  isNumericGrading: false,
  onSelectRubric: null,
  clearCalibrationCreationMarks: null,
};

export default connect(mapStateToProps, {
  showRubricEdit,
  showRubricView,
})(Preview);
