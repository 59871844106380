import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import {
  isProcessing,
  isGrading,
  isDraft,
  isCreate,
  isEvaluate,
  isFinalized,
  isFeedback,
  isInCreationGracePeriod,
  getActiveStatus,
  getNextStatus,
  isScheduled,
  isInEvaluationGracePeriod,
} from '@kritik/utils/stage';
import RAISE_DISPUTE from 'images/raise-dispute.svg';
import RESOLVE_DISPUTE from 'images/resolve-dispute.svg';
import RAISE_FLAG from 'images/raise-flag.svg';
import RESOLVE_FLAG from 'images/resolve-flag.svg';
import { isCreationOverdue } from '@kritik/utils/creation/status';
import { Activity, Creation, Student } from '@kritik/types.generated';
import { useUserRoleInCourse } from 'hooks/course';
import { isEvaluationOverdue } from '@kritik/utils/creation/evaluation';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const statusLabels = {
  OVERDUE: localize({ message: 'StatusLabels.Overdue' }),
  GRACE_PERIOD: localize({ message: 'StatusLabels.GracePeriod' }),
  GRADING: localize({ message: 'StatusLabels.Grading' }),
};

type ActivityWithCreation = Activity & {
  userAssignment: Creation;
};

const getActivityStatusType = (assignment: ActivityWithCreation, submission: Creation) => {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  if (isFinalized({ assignment })) {
    return 'finalized';
  }
  if (isDraft({ assignment }) && !isScheduled(assignment)) {
    return 'unavailable';
  }
  if (
    isStudentInCourse &&
    (isCreationOverdue(assignment, submission) || isInEvaluationGracePeriod(assignment))
  ) {
    return 'warning';
  }
  if (
    isInstructorInCourse &&
    (isInCreationGracePeriod(assignment) || isInEvaluationGracePeriod(assignment))
  ) {
    return 'awaiting';
  }
  if (isDraft({ assignment }) || isProcessing({ assignment })) {
    return 'pending';
  }
  if (
    isCreate({ assignment }) ||
    isEvaluate({ assignment }) ||
    isFeedback({ assignment }) ||
    isGrading({ assignment })
  ) {
    return 'action';
  }
  return 'unavailable';
};

const getLabel = (assignment: ActivityWithCreation, creation: Creation, student: Student) => {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  if (isDraft({ assignment })) {
    if (isScheduled(assignment)) {
      return getNextStatus(assignment).name;
    }
    return null;
  }

  if (
    isStudentInCourse &&
    (isCreationOverdue(assignment, creation) ||
      isEvaluationOverdue({ activity: assignment, creation: creation, student }))
  ) {
    return statusLabels.OVERDUE;
  }
  if (isInstructorInCourse && isInCreationGracePeriod(assignment)) {
    return statusLabels.GRACE_PERIOD;
  }
  if (isGrading({ assignment })) {
    return statusLabels.GRADING;
  }
  if (isProcessing({ assignment })) {
    return getNextStatus(assignment).name;
  }
  return getActiveStatus(assignment).name;
};

export const StatusLabel = ({ label, type }: { label: string; type: string }) => {
  const labelClasses = classNames('AssignmentStatusLabel', 'StatusLabel', {
    'finalized-status': type == 'finalized',
    'pending-status': type == 'pending',
    'action-status': type == 'action',
    unavailable: type == 'unavailable',
    'assignment-status-label--warning': type == 'warning',
    'assignment-status-label--awaiting': type == 'awaiting',
  });

  return (
    <div className={labelClasses} aria-live="polite">
      {label}
    </div>
  );
};

export const AssignmentDateLabel = ({
  assignment,
  date,
  submission,
  className,
}: {
  assignment: ActivityWithCreation;
  date: string;
  submission: Creation;
  className: string;
}) => {
  const type = getActivityStatusType(assignment, submission);
  const labelClasses = classNames(
    'AssignmentDateLabel',
    {
      'finalized-status': type == 'finalized',
      'pending-status': type == 'pending',
      'action-status': type == 'action',
      unavailable: type == 'unavailable',
      'assignment-date-label--warning': type == 'warning',
      'assignment-date-label--awaiting': type == 'awaiting',
    },
    className
  );

  return (
    <div className={labelClasses} aria-live="polite" data-testid="due-date">
      {date}
    </div>
  );
};

export const AssignmentStatusLabel = ({
  assignment,
  student,
  creation,
}: {
  assignment: ActivityWithCreation;
  creation?: Creation;
  student?: Student;
}) => {
  const type = getActivityStatusType(assignment, creation);
  const label = getLabel(assignment, creation, student);
  if (!label) {
    return null;
  }
  return <StatusLabel label={label} type={type} />;
};

export const GroupAssignmentLabel = ({
  studentId,
  groups,
  assignmentId,
}: {
  studentId: string;
  groups: { members: string[]; assignments: string[]; name: string; leader: string }[];
  assignmentId: string;
}) => {
  if (!groups) {
    return (
      <StatusLabel type="pending" label={localize({ message: 'StatusLabels.GroupActivity' })} />
    );
  }

  let _group: { members: string[]; assignments: string[]; name: string; leader: string };
  groups.forEach(
    (group: { members: string[]; assignments: string[]; name: string; leader: string }) => {
      if (group.members.includes(studentId) && group.assignments.includes(assignmentId)) {
        _group = group;
      }
    }
  );

  let label = '';
  if (_group) {
    label = _group.name;
    if (_group.leader == studentId) {
      label += localize({ message: 'StatusLabels.Leader' });
    }
  } else {
    label = localize({ message: 'StatusLabels.GroupActivity' });
  }

  return <StatusLabel type="pending" label={label} />;
};

export const DisputeLabel = ({ disputes }: { disputes?: number }) => {
  return (
    <div className="StatusLabel unresolved">
      <Tooltip title={localize({ message: 'StatusLabels.GradeDispute' })} placement="top">
        <img alt={localize({ message: 'StatusLabels.Disputes' })} src={RAISE_DISPUTE} />
      </Tooltip>
      {disputes && disputes}
    </div>
  );
};

export const DisputeResolvedLabel = ({ disputes }: { disputes?: number }) => {
  return (
    <div className="StatusLabel resolved">
      <Tooltip title={localize({ message: 'StatusLabels.ResolvedGradeDispute' })} placement="top">
        <img alt={localize({ message: 'StatusLabels.Disputes' })} src={RESOLVE_DISPUTE} />
      </Tooltip>
      {disputes && disputes}
    </div>
  );
};

export const RaisedFlagLabel = ({ flags }: { flags?: number }) => {
  return (
    <div className="StatusLabel unresolved">
      <Tooltip title={localize({ message: 'StatusLabels.Flagged' })} placement="top">
        <img alt={localize({ message: 'StatusLabels.Flags' })} src={RAISE_FLAG} />
      </Tooltip>
      {flags && flags}
    </div>
  );
};

export const ResolvedFlagLabel = ({ flags }: { flags?: number }) => {
  return (
    <div className="StatusLabel resolved">
      <Tooltip title={localize({ message: 'StatusLabels.Resolved' })} placement="top">
        <img alt={localize({ message: 'StatusLabels.Flags' })} src={RESOLVE_FLAG} />
      </Tooltip>
      {flags && flags}
    </div>
  );
};

export default {
  AssignmentDateLabel,
  AssignmentStatusLabel,
};
