import React from 'react';
import PdfFile from 'images/icons/fa/PdfFile';
import ImageFile from 'images/icons/fa/ImageFile';
import VideoFile from 'images/icons/fa/VideoFile';
import AudioFile from 'images/icons/fa/AudioFile';
import WordFile from 'images/icons/fa/WordFile';
import ExcelFile from 'images/icons/fa/ExcelFile';
import PowerpointFile from 'images/icons/fa/PowerpointFile';
import TextFile from 'images/icons/fa/TextFile';
import File from 'images/icons/fa/File';
import { localize } from 'locales';
import FileModal from 'components/FileModal';

const getIconFromFileName = (fileName: string) => {
  const fileExtension = fileName.toLowerCase().split('.').pop();
  switch (fileExtension) {
    case 'pdf':
      return PdfFile;
    case 'avif':
    case 'bmp':
    case 'gif':
    case 'heic':
    case 'heif':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'webp':
      return ImageFile;
    case 'avi':
    case 'flv':
    case 'mov':
    case 'mp4':
    case 'mpeg':
    case 'qt':
    case 'webm':
    case 'wmv':
      return VideoFile;
    case 'mid':
    case 'midi':
    case 'mp3':
    case 'wav':
    case 'weba':
      return AudioFile;
    case 'docx':
      return WordFile;
    case 'csv':
    case 'xls':
    case 'xlsx':
      return ExcelFile;
    case 'ppt':
    case 'pptx':
      return PowerpointFile;
    case 'txt':
      return TextFile;
    default:
      return File;
  }
};

type FileDisplayProps = {
  file: FileItem;
  removeFile: () => void;
  editable: boolean;
  loading: boolean;
  index: number;
  testid: string;
  showFileSize: boolean;
};
const FileDisplay = ({ file, removeFile, editable, loading, index, testid }: FileDisplayProps) => {
  const [fileModalItem, setFileModalItem] = React.useState<FileItem>();
  const Icon = getIconFromFileName(file.name);
  const linkProps = {
    'data-testid': `file-link-${index}`,
  };
  if (file.download) {
    (linkProps as any).download = file.name;
  }
  const isLocalFile = file?.url?.startsWith('blob');

  const unit = file.size >= 1_000_000 ? 'MB' : 'KB';
  const size =
    file.size >= 1_000_000 ? (file.size / 1_000_000).toFixed(1) : (file.size / 1_000).toFixed(0);

  return (
    <div className="file-container" data-testid={testid}>
      <div className="file-icon">
        <Icon />
      </div>
      <div className="file-info">
        <div className="file-name">
          {isLocalFile ? (
            <a {...linkProps} target="_blank" className="file-link" href={file.url}>
              {file.name}
            </a>
          ) : file.url ? (
            <span
              {...linkProps}
              className="file-link"
              onClick={() => {
                setFileModalItem(file);
              }}
            >
              {file.name}
            </span>
          ) : (
            <span>{file.name}</span>
          )}
        </div>
        <span className="file-size">
          {size} {unit}
        </span>
      </div>
      <div className="spacer"></div>
      {loading && <i className="fas fa-spinner fa-spin"></i>}
      {editable && (
        <div
          tabIndex={0}
          role="button"
          aria-label={localize({ message: 'AttachmentManager.Remove' })}
          className="delete-btn"
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            return removeFile();
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              return removeFile();
            }
          }}
        >
          <i className="fas fa-trash-alt" />
        </div>
      )}
      {fileModalItem && (
        <FileModal file={fileModalItem} onClose={() => setFileModalItem(undefined)} />
      )}
    </div>
  );
};

const FileList = ({
  files,
  showFileSize = false,
  onRemoveFile,
  loading = false,
  isGroupPresentationActivity,
}: {
  files: FileItem[];
  showFileSize?: boolean;
  onRemoveFile?: (files: FileItem[]) => void;
  loading?: boolean;
  isGroupPresentationActivity?: boolean;
}) => {
  if (!files || files.length === 0) {
    return null;
  }

  if (files.length === 0 && isGroupPresentationActivity) {
    return <div>{localize({ message: 'Activity.Presentation.StudentView.NoAttachment' })}</div>;
  }
  const removeFile = (fileId: any) => {
    const newList = [...files.slice(0, fileId), ...files.slice(fileId + 1)];
    onRemoveFile(newList);
  };

  const fileRows = files.map((file: FileItem, index: any) => {
    return (
      <FileDisplay
        testid={`creation-file-${index}`}
        key={file._id ? file._id : file.name}
        file={file}
        showFileSize={showFileSize}
        removeFile={() => {
          return removeFile(index);
        }}
        index={index}
        editable={Boolean(onRemoveFile)}
        loading={loading}
      />
    );
  });

  return <div className="AttachmentDisplay">{fileRows}</div>;
};

export default FileList;
