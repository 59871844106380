import DateInputField from 'components/core/input/Date';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { Field } from 'react-final-form';
import { RIGHT } from '../../../../images/sort-arrows';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

function DateInput({
  value,
  onChange,
  minDate,
  disabled,
  label,
  isRequired,
  error,
  id,
  ...props
}: any) {
  return (
    <FormField error={error}>
      <FormFieldLabel label={label} isRequired={isRequired} id={label} />
      <DateInputField
        value={value}
        disabled={disabled}
        minDate={minDate}
        onChange={onChange}
        id={label}
        aria-label={props['aria-label']}
      />
    </FormField>
  );
}

function CourseLength({ startDate, endDate, handleCourseLengthChange }: any) {
  const minEndDate = moment(startDate).add(1, 'day').toDate();
  return (
    <div className="course-length">
      <h2 className="new-header-2">
        <TranslatedText i18nKey="CourseLength.Title" />
      </h2>
      <p>
        <TranslatedText i18nKey="CourseLength.Description" />
      </p>
      <Grid container alignItems="stretch">
        <Grid item sm={12} md="auto">
          <Field name="startDate">
            {({ input, meta }) => {
              return (
                <DateInput
                  label={localize({ message: 'CourseLength.StartDate.Label' })}
                  aria-label={localize({ message: 'CourseDetails.Edit.StartDate.AriaLabel' })}
                  value={startDate}
                  onChange={(date: any) => handleCourseLengthChange(date, 'startDate')}
                  error={meta.error && meta.touched && meta.error}
                />
              );
            }}
          </Field>
        </Grid>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; only: ("xs" | "sm")[]; ... Remove this comment to see the full error message */}
        <Hidden only={['xs', 'sm']}>
          <Grid item xs={1}>
            <div className="arrow-right">
              <img src={RIGHT} aria-hidden="true" />
            </div>
          </Grid>
        </Hidden>
        <Grid item sm={12} md="auto">
          <Field name="endDate">
            {({ input, meta }) => {
              return (
                <DateInput
                  aria-label={localize({ message: 'CourseDetails.Edit.EndDate.AriaLabel' })}
                  label={localize({ message: 'CourseLength.EndDate.Label' })}
                  minDate={minEndDate}
                  value={endDate}
                  onChange={(date: any) => handleCourseLengthChange(date, 'endDate')}
                  error={meta.error && meta.touched && meta.error}
                />
              );
            }}
          </Field>
        </Grid>
      </Grid>
    </div>
  );
}

export default CourseLength;
