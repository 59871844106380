import { withRouter } from 'utils/withRouter';
import CollapsibleArea from 'components/layout/CollapsibleArea';
import { I18nKey, localize } from 'locales';
import { ALL_STUDENTS, EXCLUDE_CREATORS } from '@kritik/constants/activity';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';

const getButtonOptions = ({
  course,
  activity,
}: {
  course: any;
  activity: any;
}): { label: I18nKey; linkText: I18nKey; url: string } => {
  if (course.students.length === 0) {
    return {
      label: 'Activity.Presentation.Overview.StudentParticipation.NoStudentEnrolled',
      linkText: 'Activity.Presentation.Overview.StudentParticipation.LinkEnrollStudents',
      url: `/course/${course._id}/roster`,
    };
  }

  return {
    label: 'Activity.Presentation.Overview.StudentParticipation.NoStudentSelected',
    linkText: 'Activity.Presentation.Overview.StudentParticipation.LinkEditParticipation',
    url: `/course/${course._id}/assignment/${activity._id}/edit-participation`,
  };
};

const StudentParticipation = withRouter(({ activity, course, router }) => {
  const hasSelectedParticipationSettings =
    activity.participationSettings?.creatorStudents?.length > 0;

  const { label, linkText, url } = getButtonOptions({
    course,
    activity,
  });

  return (
    <CollapsibleArea title="Students">
      <div className="activity-overview-body__section-title participation-title">
        {localize({ message: 'Activity.Presentation.Overview.StudentParticipation.Table' })}
      </div>
      {!hasSelectedParticipationSettings ? (
        <div className="no-student-selected" data-testid="participation-no-student-selected">
          {localize({
            message: label,
          })}
          <a onClick={() => router.push(url)}>
            {localize({
              message: linkText,
            })}
          </a>
        </div>
      ) : (
        <StudentTable
          students={course.students}
          creatorStudents={activity.participationSettings.creatorStudents}
          evaluatorType={activity.participationSettings.evaluatorType}
          evaluatorStudents={activity.participationSettings.evaluatorStudents}
        />
      )}
    </CollapsibleArea>
  );
});

const StudentTable = ({ students, creatorStudents, evaluatorType, evaluatorStudents }) => {
  const evaluateAndFeedbackStageParticipationMessage =
    evaluatorType === ALL_STUDENTS
      ? localize({
          message: 'Activity.Presentation.Overview.StudentParticipation.Table.AllStudents',
        })
      : evaluatorType === EXCLUDE_CREATORS
        ? localize({
            message: 'Activity.Presentation.Overview.StudentParticipation.Table.ExcludeCreators',
          })
        : null;

  return (
    <div data-testid="student-participation-table">
      <div className="student-participation-table student-participation-table-header">
        <div>
          {localize({
            message: 'Activity.Presentation.Overview.StudentParticipation.Table.Create',
          })}
          <span>
            {localize({
              message: 'Activity.Presentation.Overview.StudentParticipation.Table.Presenters',
            })}
          </span>
        </div>
        <div>
          {localize({
            message: 'Activity.Presentation.Overview.StudentParticipation.Table.Evaluate',
          })}
        </div>
        <div>
          {localize({
            message: 'Activity.Presentation.Overview.StudentParticipation.Table.Feedback',
          })}
        </div>
      </div>
      <div className="student-participation-table">
        <div className="student-participation-table-participants">
          <StudentList allStudents={students} studentsToDisplay={creatorStudents} />
        </div>
        <div className="student-participation-table-participants">
          {evaluateAndFeedbackStageParticipationMessage || (
            <StudentList allStudents={students} studentsToDisplay={evaluatorStudents} />
          )}
        </div>
        <div className="student-participation-table-participants">
          {evaluateAndFeedbackStageParticipationMessage || (
            <StudentList allStudents={students} studentsToDisplay={evaluatorStudents} />
          )}
        </div>
      </div>
    </div>
  );
};

const StudentList = ({ allStudents, studentsToDisplay }) => {
  return studentsToDisplay.map((creatorStudentId, index) => {
    const student = allStudents.find((student) => student._id === creatorStudentId);

    if (!student) {
      return null;
    }

    return (
      <div
        className="student-participation-table-participant-name"
        data-testid={`student-participation-table-${index}`}
      >
        {student.user.profile.name}
      </div>
    );
  });
};

export default StudentParticipation;
