import * as creationStatus from '@kritik/utils/creation/status';
import StatusLabel from 'components/Creation/StatusLabel';
import DisputeIcon from 'images/disputes';
import { TranslatedText } from 'components/TranslatedText';

const Labels = (props: any) => {
  const ICON_STYLES = {
    width: '1em',
    height: '1em',
  };
  const STATUSES = {
    DANGER: 'danger',
    RESOLVED: 'resolved',
  };
  if (creationStatus.isDispute(props.creation)) {
    return (
      <StatusLabel
        label={<TranslatedText i18nKey="Creation.Dispute.Label.DisputedGrade" />}
        status={STATUSES.DANGER}
        icon={<DisputeIcon {...ICON_STYLES} />}
      />
    );
  }
  if (creationStatus.isDisputeResolved(props.creation)) {
    return (
      <StatusLabel
        label={<TranslatedText i18nKey="Creation.Dispute.Label.DisputeResolved" />}
        status={STATUSES.RESOLVED}
        icon={<DisputeIcon {...ICON_STYLES} />}
      />
    );
  }
  return null;
};

export default Labels;
