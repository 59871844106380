import { connect } from 'react-redux';
import { Component } from 'react';
import CreateAssignmentContainer from 'containers/CreateAssignment';
import Page from 'pages/Page';
import { localize } from 'locales';

type CreateAssignmentProps = {
  isEditingRubric: boolean;
};

class CreateAssignment extends Component<CreateAssignmentProps, any> {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return this.props.isEditingRubric
      ? localize({ message: 'Activity.EditRubric.PageTitle' })
      : localize({ message: 'Activity.Create.PageTitle' });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <CreateAssignmentContainer {...this.props} />
      </Page>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isEditingRubric: state.activityEdit.rubric.isRubricEditShow,
  };
}

export default connect(mapStateToProps, {})(CreateAssignment);
