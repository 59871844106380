import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { groupService } from 'services';
import Table from 'components/layout/SimpleTable';
import TableRow from 'components/layout/SimpleTable/Row';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableBody from 'components/layout/SimpleTable/Body';
import { navigateToActivityPage } from 'actions/activity';
import TitleCell from 'components/ActivityList/Cells/Title';
import WeightCell from 'components/ActivityList/Cells/Weight';
import ScheduleCell from 'components/ActivityList/Cells/Schedule';
import StatusCell from 'components/ActivityList/Cells/Status';
import MenuCell from 'components/ActivityList/Cells/Menu';
import NextActionCell from 'components/ActivityList/Cells/NextAction';
import { selectCurrentCourse } from 'selectors/course';
import * as FormatUtils from '@kritik/utils/format';
import { setCurrentPage, setTableType } from 'redux/creationTable/actions';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import { isPresentationActivity } from '@kritik/utils/activity';
import { TranslatedText } from 'components/TranslatedText';
import { EvaluationScoreUtil, GradeHistoryUtil, OverallScoreUtil } from '@kritik/utils/grade';
import { getAssignment } from 'selectors/activity';

type InstructorActivityListProps = {
  activityList: any;
  renderHeader: boolean;
  isFinalized: boolean;
  course: any;
  navigateToActivityPage: any;
  setCurrentPage: any;
  setTableType: any;
  state: any;
};

const InstructorActivityList = (props: InstructorActivityListProps) => {
  const [hoveredActivityId, setHoveredActivityId] = useState(null);
  const [courseHasGroupSets, setCourseHasGroupSets] = useState(false);

  useEffect(() => {
    const getCourseGroupSets = async () => {
      try {
        const response = await groupService().getAllGroupSets({ courseId: props.course._id });
        if (response.data.length) {
          setCourseHasGroupSets(true);
        }
      } catch (ignore) {}
    };
    void getCourseGroupSets();
  }, []);

  const renderScoreCell = (value: any, header: string) => {
    let display = value;
    if (typeof display === 'number') {
      display = FormatUtils.simplePercent(value);
    } else {
      display = '-';
    }
    return (
      <TableCell align="center" color="light" headers={header}>
        <span tabIndex={0}>{display}</span>
      </TableCell>
    );
  };

  const getOverallScore = (activityId: string) => {
    const gradeHistory = props.course.gradeHistories.find((g) => g.assignment === activityId);
    if (!Boolean(gradeHistory)) {
      return null;
    }
    const score = OverallScoreUtil.calculateOverallScore(gradeHistory, props.course);
    return score;
  };

  const getCreationScore = (activityId: string) => {
    const gradeHistory = props.course.gradeHistories.find((g) => g.assignment === activityId);
    if (!Boolean(gradeHistory)) {
      return null;
    }
    const score = GradeHistoryUtil.getCreationScoreFromGradeHistory(gradeHistory);
    return score;
  };

  const getEvaluateScore = (activityId: string) => {
    const gradeHistory = props.course.gradeHistories.find((g) => g.assignment === activityId);
    if (!Boolean(gradeHistory)) {
      return null;
    }
    const activity = getAssignment(props.state, activityId);
    const score = EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
      gradeHistory.grading,
      gradeHistory.writtenEvaluation,
      props.course,
      activity
    );
    return score;
  };

  const getFeedbackScore = (activityId: any) => {
    const gradeHistory = props.course.gradeHistories.find((g) => g.assignment === activityId);
    if (!Boolean(gradeHistory)) {
      return null;
    }
    return gradeHistory.feedback;
  };

  const redirectTo = (activity: any) => {
    props.setCurrentPage(1);
    props.setTableType(TABLE_TYPE_OPTIONS.PROGRESS);
    props.navigateToActivityPage({ courseId: activity.course, assignmentId: activity._id });
  };

  const renderTableHeader = () => {
    if (props.renderHeader) {
      return (
        <TableHead>
          <TableCell color="light" id="name">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Name" />
            </span>
          </TableCell>
          <TableCell color="light" id="overall">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Overall" />
            </span>
          </TableCell>
          <TableCell color="light" id="create">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Create" />
            </span>
          </TableCell>
          <TableCell color="light" id="evaluate">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Evaluate" />
            </span>
          </TableCell>
          <TableCell color="light" id="feedback">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Feedback" />
            </span>
          </TableCell>
          <TableCell color="light" id="weight">
            <span style={{ marginRight: '8px' }}>
              <TranslatedText i18nKey="ActivityList.Header.Weight" />
            </span>
          </TableCell>
          <TableCell color="light" id="completed">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Completed" />
            </span>
          </TableCell>
          <TableCell minWidth="220px" align="left" id="issues">
            <span tabIndex={0}>
              <TranslatedText i18nKey="ActivityList.Header.Issues" />
            </span>
          </TableCell>
          <TableCell />
        </TableHead>
      );
    }
  };

  const renderOptionalCells = (activity: any) => {
    if (props.isFinalized) {
      return (
        <React.Fragment>
          {renderScoreCell(getOverallScore(activity._id), 'overall')}
          {renderScoreCell(getCreationScore(activity._id), 'create')}
          {renderScoreCell(getEvaluateScore(activity._id), 'evaluate')}
          {renderScoreCell(getFeedbackScore(activity._id), 'feedback')}
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <Table aria-labelledby="tableCaption">
      <caption id="tableCaption" className="visually-hidden">
        <TranslatedText i18nKey="Activity.List.Unscheduled" />
      </caption>
      {renderTableHeader()}
      {!props.isFinalized && (
        <thead className="visually-hidden">
          <tr>
            <th scope="col">
              <TranslatedText i18nKey="ActivityList.VisuallyHidden.ActivityName" />
            </th>
            <th scope="col">
              <TranslatedText i18nKey="ActivityList.VisuallyHidden.ActivityPoints" />
            </th>
            <th scope="col">
              <TranslatedText i18nKey="ActivityList.VisuallyHidden.ActivityScheduleButton" />
            </th>
            <th scope="col">
              <TranslatedText i18nKey="ActivityList.VisuallyHidden.ActivityOptionMenu" />
            </th>
          </tr>
        </thead>
      )}
      <TableBody>
        {props.activityList.map((activity: any, index: number) => {
          return (
            <TableRow
              key={`unscheduled-activity-${activity._id}`}
              isSelectable
              onSelectRow={() => {
                redirectTo(activity);
              }}
              onMouseOver={() => {
                setHoveredActivityId(activity._id);
              }}
              onMouseLeave={() => {
                setHoveredActivityId(null);
              }}
              testid={`activity-row-${index}`}
            >
              <TitleCell activity={activity} headers="name" testid={`activity-title-${index}`} />
              {renderOptionalCells(activity)}

              <TableCell align="right" whiteSpace="nowrap" color="light" headers="weight">
                <WeightCell activity={activity} />
              </TableCell>
              <TableCell
                minWidth="220px"
                whiteSpace="nowrap"
                align="left"
                height="50px"
                color="light"
                testid={`activity-schedule-button-${index}`}
              >
                {isPresentationActivity(activity) ? (
                  <span tabIndex={0}>
                    <NextActionCell
                      isRowHovered={hoveredActivityId === activity._id}
                      activity={activity}
                      course={props.course}
                      courseHasGroupSets={courseHasGroupSets}
                    />
                  </span>
                ) : (
                  <span tabIndex={0}>
                    <ScheduleCell
                      isRowHovered={hoveredActivityId === activity._id}
                      activity={activity}
                    />
                  </span>
                )}
              </TableCell>
              <TableCell>
                <StatusCell activity={activity} />
              </TableCell>
              <TableCell width="50px">
                <MenuCell activity={activity} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state: any) => {
  return {
    course: selectCurrentCourse(state),
    state,
  };
};

InstructorActivityList.defaultProps = {
  renderHeader: true,
};

export default connect(mapStateToProps, {
  navigateToActivityPage,
  setCurrentPage,
  setTableType,
})(InstructorActivityList);
