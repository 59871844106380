import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import Confirm from 'components/modals/ConfirmModal';
import { getTotalWeightOfActivityList, isPresentationActivity } from '@kritik/utils/activity';
import * as statusUtil from '@kritik/utils/stage';
import {
  deleteAssignment,
  deleteFinalizedActivity,
  getActivityStatsByCourse,
  navigateToDuplicateActivity,
  getAssignmentSubmissions,
} from 'actions/activity';
import { getGroups } from 'actions/courses';
import ActivityTutorial from 'components/tutorial/Banner/ActivityTutorial';
import { getCourse } from 'selectors/course';
import { getGroupList } from 'selectors/group';
import { getStudentFromUser } from 'selectors/student';
import { getCourseSubmissions } from 'selectors/creation';
import ActivityListDashboard from 'components/Assignment/ActivityListDashboard';
import ActivityCardList from 'components/ActivityCard/List';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import SpotlightProvider, { SpotlightContext } from 'components/Spotlight/context';
import SpotlightList from 'components/Spotlight/List';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import ActivityList from 'components/ActivityList';
import { localize } from 'locales';
import { activitySortOrderType } from '@kritik/constants/sortOrder';
import SortAmountUp from 'images/icons/fa/SortAmountUp';
import SortAmountDown from 'images/icons/fa/SortAmountDown';
import { UserRoleInCourse } from 'app-types';
import { withUserRole } from 'utils/withUserRole';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Button } from 'components/buttons';
import { NotConnectedWarning } from './NotConnectedWarning';
import { isCourseConnected } from 'utils/lms';
import LmsCoursesModal from 'components/LmsGradeSync/LmsCoursesModal';
import { Spotlight } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';

const mapStateToProps = (state: any) => {
  return {
    loadingBar: state.loadingBar,
    user: state.user,
    entities: state.entities,
    state,
    assignmentList: state.entities.assignments,
    course: getCourse(state),
    groups: getGroupList(state),
    student: getStudentFromUser(state, state.selected.courseId),
    courseSubmissions: getCourseSubmissions(state),
  };
};

type AssignmentListState = any;

type AssignmentListProps = {
  getGroups: any;
  getAssignmentSubmissions: any;
  deleteAssignment: any;
  deleteFinalizedActivity: any;
  getActivityStatsByCourse: any;
  navigateToDuplicateActivity: any;
  user: any;
  router: any;
  loadingBar: any;
  entities: any;
  assignmentList: any;
  course: any;
  groups: any;
  student: any;
  courseSubmissions: any;
  userRole: UserRoleInCourse;
  assignments: any[];
};

class AssignmentList extends Component<AssignmentListProps, AssignmentListState> {
  constructor(props: AssignmentListProps) {
    super(props);
    this.state = {
      confirm: false,
      loading: true,
      sortByType: 'Due Date',
      isAscending: true,
      connectCourseModalOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.userRole.isStudentInCourse) {
      this.props.getGroups({ courseId: this.props.router.params.courseId });
    }
    if (this.props.userRole.isInstructorInCourse) {
      this.props.getActivityStatsByCourse({
        courseId: this.props.router.params.courseId,
      });
    }

    this.props.assignments.forEach((activity) => {
      if (isPresentationActivity(activity)) {
        this.props.getAssignmentSubmissions({ assignmentId: activity._id });
      }
    });
  }

  componentDidUpdate(prevProps: {}, prevState: AssignmentListState) {
    if (this.props.userRole.isStudentInCourse) {
      if ((prevProps as any).assignments.length !== this.props.assignments.length) {
        this.props.getGroups({ courseId: this.props.router.params.courseId });
        this.props.assignments.forEach((activity) => {
          if (isPresentationActivity(activity)) {
            this.props.getAssignmentSubmissions({ assignmentId: activity._id });
          }
        });
      }
    }
    if (prevState.loading === true && this.props.loadingBar.default === 0) {
      this.setState({ loading: false });
    } else if (prevState.loading === false && this.props.loadingBar.default !== 0) {
      this.setState({ loading: true });
    }
  }

  sortOptions = [
    {
      label: localize({ message: 'ActivitySortOrderType.DueDate' }),
      value: activitySortOrderType.DueDate,
      testid: 'sort-by-due-date',
    },
    {
      label: localize({ message: 'ActivitySortOrderType.CreatedDate' }),
      value: activitySortOrderType.CreatedDate,
      testid: 'sort-by-created-date',
    },
    {
      label: localize({ message: 'ActivitySortOrderType.ActivityStage' }),
      value: activitySortOrderType.ActivityStage,
      testid: 'sort-by-activity-stage',
    },
    {
      label: localize({ message: 'ActivitySortOrderType.Alphabetical' }),
      value: activitySortOrderType.Alphabetical,
      testid: 'sort-by-alphabetical-order',
    },
  ];

  toggleSortOrder = () => {
    this.setState({ isAscending: !this.state.isAscending });
  };

  openConnectCourseModal = () => {
    this.setState({ connectCourseModalOpen: true });
  };

  handleSortTypeChange = (e: any) => {
    const sortType = e.target.value;
    if (sortType !== this.state.sortByType) {
      this.setState({ sortByType: sortType });
    }
  };

  handleDelete(id: any) {
    const deleteParams = { id, courseId: this.props.router.params.courseId };

    if (this.props.entities.assignments[id].status == 'Finalized') {
      this.props.deleteFinalizedActivity(deleteParams);
    } else {
      this.props.deleteAssignment(deleteParams);
    }
  }

  redirectTo(assignment: any) {
    this.props.router.push(`/course/${assignment.course}/assignment/${assignment._id}`);
  }

  renderLoadingState() {
    return <LoaderOverlay isOpen label={localize({ message: 'Course.Activities.Loading' })} />;
  }

  renderSpotlightedContent() {
    return (
      <SpotlightProvider courseId={this.props.course._id}>
        <SpotlightContext.Consumer>
          {(value: Spotlight[]) => {
            if (!value || !value.length) {
              return null;
            }
            return (
              <>
                <h2 className="new-header-2">
                  <TranslatedText i18nKey="ActivityOverview.SpotlightedContent" />
                </h2>
                <div role="generic">
                  <SpotlightList spotlights={value.filter((s) => s.status === 'published')} />
                </div>
              </>
            );
          }}
        </SpotlightContext.Consumer>
      </SpotlightProvider>
    );
  }

  renderNotScheduledActivities = (activityList: any) => {
    if (activityList.length === 0) {
      return null;
    }
    activityList.sort((a, b) => {
      const createDateA = statusUtil.getActiveStatus(a).createdAt;
      const createDateB = statusUtil.getActiveStatus(b).createdAt;
      return createDateA > createDateB ? -1 : 1;
    });
    return (
      <div className="activity-list__unscheduled-list" data-testid="unscheduled-activities-list">
        <h2 className="new-header-2">
          <TranslatedText i18nKey="Course.UnscheduledActivities" />
        </h2>
        <ActivityList activityList={activityList} renderHeader={false} />
      </div>
    );
  };

  renderCompletedActivities = (pastActivities: any, text: any) => {
    if (pastActivities.length === 0) {
      return null;
    }
    pastActivities.sort((a, b) => {
      const completedDateA = statusUtil.getFinalizedStage(a).startDate;
      const completedDateB = statusUtil.getFinalizedStage(b).startDate;
      return completedDateA > completedDateB ? -1 : 1;
    });
    return (
      <div
        className="activity-list__completed-activities"
        role="contentinfo"
        aria-label={localize({ message: 'Course.CompletedActivities' })}
        data-testid="completed-activities-section"
      >
        <h2 className="new-header-2" role="heading">
          {text}
        </h2>
        <ActivityList activityList={pastActivities} isFinalized />
      </div>
    );
  };

  renderCurrentActivities = (currentActivities: any, sortByType, isAscending: boolean) => {
    if (currentActivities.length === 0) {
      return null;
    }
    return (
      <div className="activity-list__card-list">
        <ActivityCardList
          activityList={currentActivities}
          sortByType={sortByType}
          isAscending={isAscending}
        />
      </div>
    );
  };

  render() {
    const { assignments } = this.props;

    if (this.state.loading) {
      return <div className="activity-list__loader">{this.renderLoadingState()}</div>;
    }

    if (this.state.loading === false && !assignments.length) {
      return (
        <>
          <ActivityTutorial className="activity-list__tutorial" />
          <Card>
            <CardContent style={{ paddingBottom: '16px' }}>
              <Typography align="center">{localize({ message: 'NoActivities' })}</Typography>
            </CardContent>
          </Card>
        </>
      );
    }

    const { currentAssignments, pastAssignments, notScheduledAssignments } = assignments.reduce(
      (acc: any, assignment: any) => {
        if (!statusUtil.isFinalized({ assignment })) {
          if (statusUtil.isScheduled(assignment)) {
            acc.currentAssignments.push(assignment);
          } else {
            acc.notScheduledAssignments.push(assignment);
          }
        } else {
          acc.pastAssignments.push(assignment);
        }
        return acc;
      },
      { pastAssignments: [], currentAssignments: [], notScheduledAssignments: [] }
    );

    const totalFinalizedActivityWeight = getTotalWeightOfActivityList(pastAssignments);
    const shouldRenderInstructorDashboard =
      this.props.course &&
      this.props.course.gradeHistories &&
      this.props.course.gradeHistories.length > 0 &&
      totalFinalizedActivityWeight > 0;
    const renderInstructorView = (
      <>
        {this.state.connectCourseModalOpen && (
          <LmsCoursesModal
            course={this.props.course}
            close={() => {
              this.setState({ connectCourseModalOpen: false });
            }}
            open
            authUser={this.props.user.authUser}
          />
        )}
        {this.props.course.hasInstitutionEdlinkIntegrations &&
          !isCourseConnected(this.props.course) && (
            <NotConnectedWarning openConnectCourseModal={this.openConnectCourseModal} />
          )}
        {currentAssignments?.length > 0 && (
          <div className="activity-sortby-container">
            <div className="activity-sortby-container__children">
              <h2 className="new-header-2">
                <TranslatedText i18nKey="Course.CurrentActivities" />
              </h2>
            </div>
            <div className="activity-sortby-container__children last-item">
              <span id="sort-by-label" className="padding-right-9">
                <TranslatedText i18nKey="Course.Activity.SortBy" />
              </span>
              <ButtonWithSelect
                className="padding-right-9"
                value={this.state.sortByType}
                onChange={this.handleSortTypeChange}
                options={this.sortOptions}
                type="secondary"
                title={this.state.sortByType}
                testid="activity-sort-by-button"
                aria-labelledby="sort-by-label"
              />
              {this.state.isAscending ? (
                <Button
                  type="secondary"
                  onClick={this.toggleSortOrder}
                  className="sortIcon"
                  aria-label={localize({ message: 'Course.Activity.SortAscending' })}
                >
                  <SortAmountUp />
                </Button>
              ) : (
                <Button
                  type="secondary"
                  onClick={this.toggleSortOrder}
                  className="sortIcon"
                  aria-label={localize({ message: 'Course.Activity.SortDescending' })}
                >
                  <SortAmountDown />
                </Button>
              )}
            </div>
          </div>
        )}
        <ActivityTutorial className="activity-list__tutorial" />
        {this.renderCurrentActivities(
          currentAssignments,
          this.state.sortByType,
          this.state.isAscending
        )}
        {this.renderNotScheduledActivities(notScheduledAssignments)}
        {this.renderSpotlightedContent()}
        {shouldRenderInstructorDashboard && (
          <ActivityListDashboard
            assignments={this.props.entities.assignments}
            submissions={this.props.courseSubmissions}
            student={Object.values(this.props.entities.students).filter((student) => {
              // @ts-expect-error TS(2339) FIXME: Property 'course' does not exist on type 'unknown'... Remove this comment to see the full error message
              return student.course == this.props.course._id;
            })}
          />
        )}
        {this.renderCompletedActivities(
          pastAssignments,
          localize({ message: 'Course.CompletedActivities' })
        )}
      </>
    );

    const shouldRenderStudentDasboard =
      this.props.student &&
      this.props.student.gradeHistories.length > 0 &&
      totalFinalizedActivityWeight > 0;

    const renderStudentView = this.props.student ? (
      <>
        <h2 className="new-header-2">
          <TranslatedText i18nKey="Course.CurrentActivities" />
        </h2>
        <ActivityTutorial className="activity-list__tutorial" />
        {this.renderCurrentActivities(
          currentAssignments,
          this.state.sortByType,
          this.state.isAscending
        )}
        {this.renderSpotlightedContent()}
        {shouldRenderStudentDasboard && (
          <ActivityListDashboard
            assignments={Object.values(this.props.entities.assignments)}
            pastAssignments={pastAssignments}
            student={this.props.student}
          />
        )}
        {this.renderCompletedActivities(
          pastAssignments,
          localize({ message: 'Course.PastActivities' })
        )}
      </>
    ) : null;

    const renderList = this.props.userRole.isInstructorInCourse
      ? renderInstructorView
      : renderStudentView;

    return (
      <>
        {renderList}
        <Confirm
          isOpen={this.state.confirm}
          onCancel={() => {
            return this.setState({ confirm: false });
          }}
          onConfirm={() => {
            this.setState({ confirm: false });
            this.handleDelete(this.state.deleting);
          }}
          cancelButton="No"
          confirmButton="Yes"
          title={localize({ message: 'Activity.Delete.Confirm.Title' })}
          description={`{localize({ message: 'Activity.Delete.Confirm.Description' }}
            ${
              this.state.deleting &&
              this.props.entities.assignments[this.state.deleting].status === 'Finalized'
                ? localize({ message: 'Activity.Delete.Confirm.FinalizedWarning' })
                : ''
            }
          `}
        />
      </>
    );
  }
}

export default withUserRole(
  withRouter(
    connect(mapStateToProps, {
      deleteAssignment,
      deleteFinalizedActivity,
      getGroups,
      getActivityStatsByCourse,
      navigateToDuplicateActivity,
      getAssignmentSubmissions,
    })(AssignmentList)
  )
);
