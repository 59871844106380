import React from 'react';
import Confirm from 'components/modals/ConfirmModal';
import ElipsesMenu from 'components/General/ElipsesMenu';
import { handleRemoveCollaborator, useGetCachedCourse } from 'hooks/course';
import { useParams } from 'react-router';
import { useUserRoleInCourse } from 'hooks/course';
import { isCourseConnected } from 'utils/lms';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

function CollaboratorInvitationMenu({ collaboratorEmail, handleReinviteCollaborator }: any) {
  const { courseId } = useParams();
  const course = useGetCachedCourse(courseId);
  const isCourseLMSConnected = isCourseConnected(course);
  const { isInstructorInCourse } = useUserRoleInCourse();
  const handleRemoveCollaboratorMutation = handleRemoveCollaborator(courseId);

  if (!isInstructorInCourse) {
    return null;
  }
  const [isCancelInviteConfirming, setIsCancelInviteConfirming] = React.useState(false);

  const handleCancelInviteConfirm = () => {
    setIsCancelInviteConfirming(true);
  };

  const handleReinvite = async () => {
    await handleReinviteCollaborator({ collaboratorEmail });
  };
  const OPTIONS = [
    {
      label: localize({ message: 'CollaboratorInvitationMenu.ReInvite' }),
      action: handleReinvite,
      testid: 're-invite-collaborator',
    },
    !isCourseLMSConnected && {
      label: localize({ message: 'CollaboratorInvitationMenu.CancelInvite' }),
      action: handleCancelInviteConfirm,
    },
  ].filter(Boolean);

  return (
    <div>
      <ElipsesMenu
        options={OPTIONS}
        testid={`invite-menu-${collaboratorEmail}`}
        labelI18nKey="RosterManager.CollaboratorsTab.MoreOptionsMenu.Label"
      />
      <Confirm
        isOpen={isCancelInviteConfirming}
        onCancel={() => {
          setIsCancelInviteConfirming(false);
        }}
        onConfirm={() => {
          setIsCancelInviteConfirming(false);
          handleRemoveCollaboratorMutation.mutate({ courseId, collaboratorEmail });
        }}
        cancelButton={localize({ message: 'CollaboratorInvitationMenu.No' })}
        confirmButton={localize({ message: 'CollaboratorInvitationMenu.Yes' })}
        title={<TranslatedText i18nKey="CollaboratorInvitationMenu.CancelInviteConfirmTitle" />}
      />
    </div>
  );
}

export default CollaboratorInvitationMenu;
