import * as studentUtil from 'utils/student';
import { simplePercent } from '@kritik/utils/format';
import {
  getFeedbackStage,
  isFinalized,
  studentParticipatesInStageFromInstructorView,
} from '@kritik/utils/stage';
import * as GradeHistoryUtils from '@kritik/utils/gradeHistory';
import { isPresentationActivity, isGroupPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales';

const FeedbackScoreCell = (props: any) => {
  const { activity, student, isGroupItem } = props;

  const feedbackStage = getFeedbackStage(activity);

  if (isGroupPresentationActivity(activity) && isGroupItem) {
    return localize({ message: 'Activity.StudentTable.Cell.NA' });
  }
  if (
    isPresentationActivity(activity) &&
    !studentParticipatesInStageFromInstructorView({ stage: feedbackStage, studentId: student._id })
  ) {
    return localize({ message: 'Activity.StudentTable.Cell.NA' });
  }

  const gradeHistory = GradeHistoryUtils.getStudentActivityGradeHistory(
    student.gradeHistories,
    activity._id
  );

  if (!gradeHistory && !isFinalized({ assignment: activity })) {
    return '-';
  }
  let { feedbackScore } = props;
  if (!feedbackScore && feedbackScore !== 0) {
    feedbackScore = studentUtil.getFeedbackScoreFromHistory(student, activity._id);
  }

  return simplePercent(feedbackScore);
};

export default FeedbackScoreCell;
