import React from 'react';
import classNames from 'classnames';
import Button from 'components/buttons/Button';
import CloseIcon from 'images/icons/fa/Close';
import { localize } from 'locales';

const Banner = (props: any) => {
  const renderCloseButton = () => {
    if (!props.onClose) {
      return null;
    }
    return (
      <Button
        className="banner__close-button"
        onClick={props.onClose}
        type="hidden"
        label={localize({ message: 'Button.Label.CloseBanner' })}
      >
        <CloseIcon />
      </Button>
    );
  };
  const renderIconList = () => {
    return <div className="banner__image">{props.image}</div>;
  };

  const renderStatus = () => {
    if (!props.status) {
      return null;
    }
    return <span className="banner__status"> {props.status} </span>;
  };

  const renderTitle = () => {
    if (!props.title) {
      return null;
    }
    return (
      <div className="banner__header">
        <span className="banner__title">{props.title}</span>
        {renderStatus()}
      </div>
    );
  };

  const renderDescription = () => {
    if (!props.description) {
      return null;
    }
    return <div className="banner__description">{props.description}</div>;
  };
  const renderContent = () => {
    if (!props.children) {
      return null;
    }
    return <div className="banner__content">{props.children}</div>;
  };

  const classes = classNames('banner', props.className);
  return (
    <div className={classes} tabIndex={0}>
      {renderCloseButton()}
      {renderIconList()}
      <div className="banner__body">
        {renderTitle()}
        {renderDescription()}
        {renderContent()}
      </div>
    </div>
  );
};

Banner.defaultProps = {
  className: '',
  title: null,
  description: null,
  children: null,
  onClose: null,
};

export default Banner;
