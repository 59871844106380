import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import SORT_ARROWS from 'images/sort-arrows';
import { selectSortedColumn } from 'redux/creationTable/select';
import ViewType from 'components/CreationList/Header/ViewType';
import { TABLE_HEADERS } from 'components/CreationList/constant';

const SIZES = {
  MEDIUM: 'md',
  LARGE: 'lg',
  SMALL: 'sm',
};

const Cell = (props: any) => {
  const { column, onSelect, sortedColumn } = props;
  const cellClasses = classNames('creation-table-header-cell', {
    'creation-table-header-cell--active': column.isActive,
    'creation-table-header-cell--sortable': column.isSortable,
    md: props.column.width === SIZES.MEDIUM,
    lg: props.column.width === SIZES.LARGE,
    sm: props.column.width === SIZES.SMALL,
    'creation-table-header-cell-menu': column.type === 'menu',
  });
  const style = {
    textAlign: column.align,
    justifyContent: column.align,
  };

  const renderInfoLabel = () => {
    if (!column.info) {
      return null;
    }
    return (
      <Tooltip title={column.info} placement="top" className="creation-table-header-cell__info">
        <InfoIcon />
      </Tooltip>
    );
  };

  const renderViewType = () => {
    if (column.header === TABLE_HEADERS.EVALUATE || column.header === TABLE_HEADERS.FEEDBACK) {
      return <ViewType column={column} />;
    }
    return null;
  };

  const renderSortIcon = () => {
    if (sortedColumn && sortedColumn.header === column.header) {
      let arrowIcon;
      if (sortedColumn.sorting.isAscending) {
        arrowIcon = <img src={SORT_ARROWS.ASCENDING} />;
      } else {
        arrowIcon = <img src={SORT_ARROWS.DESCENDING} />;
      }
      return <div className="creation-table-header-cell__sorting">{arrowIcon}</div>;
    }
    return null;
  };
  const handleSelect = () => {
    if (column.isSortable && typeof onSelect === 'function') {
      onSelect();
    }
  };

  return (
    <th
      scope="col"
      className={cellClasses}
      onClick={() => {
        return handleSelect();
      }}
      data-testid={column.testid}
      aria-sort={
        !sortedColumn?.sorting
          ? 'none'
          : sortedColumn?.sorting.isAscending
            ? 'ascending'
            : 'descending'
      }
    >
      <div className="creation-table__cell-content" style={style}>
        <div className="creation-table-header-cell__header">
          <button className="creation-table-header-cell__label-wrapper">
            <div className="creation-table-header-cell__label">{column.header}</div>
            {renderInfoLabel()}
            {renderSortIcon()}
          </button>
          {renderViewType()}
        </div>
      </div>
    </th>
  );
};

const mapStateToProps = (state: any) => {
  return {
    sortedColumn: selectSortedColumn(state),
  };
};

export default connect(mapStateToProps)(Cell);
