import NoticeBoard from 'components/layout/NoticeBoard';
import Access from 'components/Access';
import { Button, ButtonContainer } from 'components/buttons';
import HtmlContent from 'components/General/HtmlContent';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import momentTZ from 'moment-timezone';
import { useAcceptLateEvaluation, useRejectLateEvaluation } from 'hooks/evaluation';
import { Activity, Evaluation, Creation } from 'old-common/types.generated';
import { displayTimeBetweenDates } from '@kritik/utils/format';
import { TranslatedText } from 'components/TranslatedText';

export const LateReason = ({ reason }: { reason: string }) => {
  return (
    <>
      <div className="late-submission-boards__reason">
        <TranslatedText i18nKey="LateEvaluationBoard.Reason" />
      </div>
      <HtmlContent content={reason} />
    </>
  );
};

export function LateEvaluationBoard({
  evaluation,
  activity,
  evaluatedCreation,
}: {
  evaluation: Evaluation;
  activity: Activity;
  evaluatedCreation: Creation;
}) {
  if (!evaluation.lateEvaluation) {
    return null;
  }
  if (Boolean(evaluation.deletedAt)) {
    return null;
  }

  switch (evaluation.lateEvaluation?.status) {
    case 'Pending':
      return (
        <PendingBoard
          evaluation={evaluation}
          activity={activity}
          evaluatedCreation={evaluatedCreation}
        />
      );
    case 'Accepted':
      return <ApprovedBoard evaluation={evaluation} evaluatedCreation={evaluatedCreation} />;
    case 'Rejected':
      return <RejectedBoard evaluation={evaluation} evaluatedCreation={evaluatedCreation} />;
    default:
      return null;
  }
}

export function PendingBoard({
  evaluation,
  activity,
  evaluatedCreation,
}: {
  evaluation: Evaluation;
  activity: Activity;
  evaluatedCreation: Creation;
}) {
  const acceptLateEvaluationMutation = useAcceptLateEvaluation();
  const rejectLateEvaluationMutation = useRejectLateEvaluation();
  const dueDate = ActivityStatusUtil.getEndOfEvaluationPeriod(activity);
  const past = displayTimeBetweenDates(dueDate, evaluation.createdAt);
  return (
    <NoticeBoard
      type="caution"
      title={
        <TranslatedText i18nKey="LateEvaluationBoard.EvaluationWasSubmitted" values={{ past }} />
      }
      data-testid="pending-late-evaluation"
    >
      <div>
        <TranslatedText
          i18nKey="LateEvaluationBoard.SubmittedOn"
          values={{ date: momentTZ(evaluation.createdAt).format('MMM DD yyyy @ hh:mm') }}
        />
      </div>
      <LateReason reason={evaluation.lateEvaluation?.reason || ''} />
      <Access
        instructor={
          <ButtonContainer className="late-submission-boards__buttons">
            <Button
              type="secondary"
              disabled={
                acceptLateEvaluationMutation.isLoading || rejectLateEvaluationMutation.isLoading
              }
              onClick={() =>
                acceptLateEvaluationMutation.mutate({
                  evaluationId: evaluation._id,
                  creationId: evaluatedCreation._id,
                })
              }
              data-testid="accept-late-evaluation-btn"
            >
              <TranslatedText i18nKey="LateEvaluationBoard.Btn.Accept" />
            </Button>
            <Button
              type="secondary"
              disabled={
                acceptLateEvaluationMutation.isLoading || rejectLateEvaluationMutation.isLoading
              }
              onClick={() =>
                rejectLateEvaluationMutation.mutate({
                  evaluationId: evaluation._id,
                  creationId: evaluatedCreation._id,
                })
              }
              data-testid="reject-late-evaluation-btn"
            >
              <TranslatedText i18nKey="LateEvaluationBoard.Btn.Reject" />
            </Button>
          </ButtonContainer>
        }
      />
      <div style={{ marginTop: '20px' }}>
        <TranslatedText i18nKey="LateEvaluationBoard.ScoreNotice" />
      </div>
    </NoticeBoard>
  );
}

export function ApprovedBoard({
  evaluation,
  evaluatedCreation,
}: {
  evaluation: Evaluation;
  evaluatedCreation: Creation;
}) {
  const rejectLateEvaluationMutation = useRejectLateEvaluation();
  return (
    <NoticeBoard
      type="success"
      title={<TranslatedText i18nKey="LateEvaluationBoard.EvaluationAccepted" />}
      data-testid="approved-late-evaluation"
    >
      <div>
        <TranslatedText
          i18nKey="LateEvaluationBoard.SubmittedOn"
          values={{ date: momentTZ(evaluation.createdAt).format('MMM DD yyyy @ hh:mm') }}
        />
      </div>
      <div>
        <TranslatedText
          i18nKey="LateEvaluationBoard.AcceptedOn"
          values={{
            date: momentTZ(evaluation.lateEvaluation.resolvedDate).format('MMM DD yyyy @ hh:mm'),
          }}
        />
      </div>
      <LateReason reason={evaluation.lateEvaluation?.reason || ''} />
      <Access
        instructor={
          <ButtonContainer className="late-submission-boards__buttons">
            <Button
              type="secondary"
              disabled={rejectLateEvaluationMutation.isLoading}
              onClick={() => {
                rejectLateEvaluationMutation.mutate({
                  evaluationId: evaluation._id,
                  creationId: evaluatedCreation._id,
                });
              }}
              data-testid="reject-late-evaluation-btn"
            >
              <TranslatedText i18nKey="LateEvaluationBoard.Btn2.Reject" />
            </Button>
          </ButtonContainer>
        }
      />
      <div style={{ marginTop: '20px' }}>
        <TranslatedText i18nKey="LateEvaluationBoard.ScoreNotice" />
      </div>
    </NoticeBoard>
  );
}

export function RejectedBoard({
  evaluation,
  evaluatedCreation,
}: {
  evaluation: Evaluation;
  evaluatedCreation: Creation;
}) {
  const acceptLateEvaluationMutation = useAcceptLateEvaluation();
  return (
    <NoticeBoard
      type="danger"
      title={<TranslatedText i18nKey="LateEvaluationBoard.EvaluationRejected" />}
      data-testid="rejected-late-evaluation"
    >
      <div>
        <TranslatedText
          i18nKey="LateEvaluationBoard.SubmittedOn"
          values={{ date: momentTZ(evaluation.createdAt).format('MMM DD yyyy @ hh:mm') }}
        />
      </div>
      <div>
        <TranslatedText
          i18nKey="LateEvaluationBoard.RejectedOn"
          values={{
            date: momentTZ(evaluation.lateEvaluation.resolvedDate).format('MMM DD yyyy @ hh:mm'),
          }}
        />
      </div>
      <LateReason reason={evaluation.lateEvaluation?.reason || ''} />
      <Access
        instructor={
          <ButtonContainer className="late-submission-boards__buttons">
            <Button
              type="secondary"
              disabled={acceptLateEvaluationMutation.isLoading}
              onClick={() =>
                acceptLateEvaluationMutation.mutate({
                  evaluationId: evaluation._id,
                  creationId: evaluatedCreation._id,
                })
              }
              data-testid="accept-late-evaluation-btn"
            >
              <TranslatedText i18nKey="LateEvaluationBoard.Btn2.Accept" />
            </Button>
          </ButtonContainer>
        }
      />
      <div style={{ marginTop: '20px' }}>
        <TranslatedText i18nKey="LateEvaluationBoard.ScoreNotice" />
      </div>
    </NoticeBoard>
  );
}
