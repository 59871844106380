import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { FORM_ERROR } from 'final-form';
import { selectCourse, selectAssignment } from 'actions/select';
import { getAssignment } from 'selectors/activity';
import TypeSelection from 'components/ActivityEdit/TypeSelection';
import { TYPE_OPTIONS } from 'components/ActivityEdit/constants';
import { PageHeader } from 'components/layout';
import PeerReviewDetails from 'components/ActivityEdit/PeerReview';
import CalibrationDetails from 'components/ActivityEdit/Calibration';
import CalibrationCreationSelector from 'components/ActivityEdit/Calibration/Selector';
import GroupDetails from 'components/ActivityEdit/Group';
import PresentationDetails from 'components/ActivityEdit/Presentation';
import FullScreenModal from 'components/modals/FullScreenModal/index';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import {
  selectIsRubricEditShow,
  selectIsRubricViewShow,
  selectIsSelectingCreation,
  resetActivityEditor,
} from 'redux/activityEdit';
import * as activityUtil from '@kritik/utils/activity';
import {
  navigateToActivityPage,
  updateAssignment,
  navigateToEditActivityParticipation,
} from 'actions/activity';
import Confirm from 'components/modals/ConfirmModal';
import OnLeaveModal from 'components/modals/OnLeaveModal';
import PageContainer from 'components/layout/PageContainer';
import Rubric from './Rubric';
import { useCreateAssignment } from 'hooks/activity';
import { selectIsEditingTemplate } from 'redux/rubricManager/selectors';
import { useUserRoleInCourse } from 'hooks/course';
import { usePrevious } from 'hooks/usePrevious';
import { AuthUser } from 'app-types';
import { trackEvent } from 'utils/userEvents';
import { isScheduled } from '@kritik/utils/stage';
import { Activity } from '@kritik/types.generated';

const Section = ({ children }: any) => {
  return <div className="activity-edit__section"> {children} </div>;
};

type Props = {
  title: string;
  isEdit: boolean;
  isDuplicating: boolean;
  onEdit: () => void;
  onCancel: () => void;
  authUser: AuthUser;
  isSelectingCreation?: () => void;
  courseId?: string;
  activity?: Activity;
  resetActivityEditor?: () => void;
  updateAssignment?: (data: any) => void;
  navigateToEditActivityParticipation?: ({
    courseId,
    activityId,
  }: {
    courseId: string;
    activityId: string;
  }) => void;
  navigateToActivityPage?: ({
    courseId,
    assignmentId,
  }: {
    courseId: string;
    assignmentId: string;
  }) => void;
  isRubricEditShow?: boolean;
  isRubricViewShow?: boolean;
  isEditingTemplate?: boolean;
};
const ActivityEdit = (props: Props) => {
  const createAssignmentMutation = useCreateAssignment({ authUser: props.authUser });
  const { isStudentInCourse } = useUserRoleInCourse();

  const prevIsSelectingCreation = usePrevious(props.isSelectingCreation);

  const innerRef = useRef(null);
  const formRef = useRef();
  const [activityType, setActivityType] = useState(ACTIVITY_TYPES.PEER);
  const [changeTypeConfirm, setChangeTypeConfirm] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Use a dedicated state to subscribe the multitopic change outside react final form, otherwise
  // parent component won't re-render due to using react ref to access form state outside
  const [, setIsMultipleTopics] = useState(props.activity?.isMultipleTopics);
  const [activityInstructions, setActivityInstructions] = useState(null);
  const [activityObjective, setActivityObjective] = useState(null);
  const [errMessage, setErrMessage] = useState(null);

  useEffect(() => {
    if (props.activity) {
      if (activityUtil.isPeerActivity(props.activity)) {
        setActivityType(ACTIVITY_TYPES.PEER);
      } else if (activityUtil.isCalibrationActivity(props.activity)) {
        setActivityType(ACTIVITY_TYPES.CALIBRATION);
      } else if (activityUtil.isGroupAssignment(props.activity)) {
        setActivityType(ACTIVITY_TYPES.GROUP);
      } else if (activityUtil.isWithinGroupActivity(props.activity)) {
        setActivityType(ACTIVITY_TYPES.WITHIN_GROUP);
      } else if (activityUtil.isGroupPresentationActivity(props.activity)) {
        setActivityType(ACTIVITY_TYPES.GROUP_PRESENTATION);
      } else if (activityUtil.isIndividualPresentationActivity(props.activity)) {
        setActivityType(ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION);
      }
    }
    return () => {
      props.resetActivityEditor();
    };
  }, []);

  useEffect(() => {
    if (!props.isSelectingCreation && prevIsSelectingCreation) {
      innerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [props.isSelectingCreation]);

  const changeActivityType = (targetType: any) => {
    setActivityType(targetType);
    setChangeTypeConfirm(null);
  };

  const handleChangeTypeRequest = (targetType: any) => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const formState = formRef.current.getState();
    if (formState.dirty) {
      setActivityInstructions(formState.values.instructions);
      setActivityObjective(formState.values.objective);
      setChangeTypeConfirm(targetType);
    } else {
      changeActivityType(targetType);
    }
  };

  function TypeSelectionSection() {
    const state = (formRef.current as any)?.getState();

    let typesEnabled = Object.values(TYPE_OPTIONS);

    if (state?.values.isMultipleTopics) {
      typesEnabled = typesEnabled.filter((type) => {
        return type === TYPE_OPTIONS.PEER;
      });
    }

    if (props.activity) {
      if (isScheduled(props.activity)) {
        return null;
      }
    }

    return (
      <TypeSelection
        onChange={(targetType: any) => {
          return handleChangeTypeRequest(targetType);
        }}
        typesEnabled={typesEnabled}
        activityType={activityType}
        isDuplicating={props.isDuplicating}
        isEdit={props.isEdit}
      />
    );
  }

  const updateRecord = async ({
    data,
    formSettings,
    redirectTo,
  }: {
    data: any;
    formSettings: any;
    redirectTo: string;
  }) => {
    setErrMessage(null);
    setIsSubmitting(true);
    const dataToUpdate = { ...data, settings: { ...formSettings } };

    if (props.isDuplicating) {
      delete data.rubric._id;
      delete data._id;

      return createAssignmentMutation.mutate(
        { ...dataToUpdate },
        {
          onError: (error: any) => {
            setErrMessage(error.response?.data?.errors?.message);
          },
        }
      );
    }

    if (props.isEdit) {
      try {
        await props.updateAssignment({
          ...dataToUpdate,
          _id: props.activity._id,
        });
        if (dataToUpdate.startingScore > 0) {
          trackEvent('Activity has a Starting Score', props.authUser, {
            activityId: props.activity._id,
            activityName: dataToUpdate.title,
            startingScore: dataToUpdate.startingScore,
          });
        }
        if (redirectTo === 'edit-participation') {
          props.navigateToEditActivityParticipation({
            courseId: props.courseId,
            activityId: props.activity._id,
          });
        } else {
          props.navigateToActivityPage({
            courseId: props.courseId,
            assignmentId: props.activity._id,
          });
        }
      } catch (e) {
        setErrMessage(e.response?.data?.errors?.message);
      }
    } else {
      return createAssignmentMutation.mutate(
        { ...dataToUpdate, redirectTo },
        {
          onError: (error: any) => {
            setErrMessage(error.response?.data?.errors?.message);
          },
        }
      );
    }
  };

  const onSubmit = async ({ data, formSettings, redirectTo }: any) => {
    try {
      data.course = props.courseId;
      data.rubric.course = props.courseId;

      const criteria = data.rubric.criteria;
      data.rubric.criteria = criteria.map((c: any) => {
        return {
          ...c,
          weight: Number(c.weight),
        };
      });

      if (!data.activityType) {
        data.activityType = activityType;
      }

      return await updateRecord({ data, formSettings, redirectTo });
    } catch (error) {
      return setFormError(error.errors.message);
    }
  };

  const setFormError = (errMessage?: string) => {
    let message = errMessage;
    if (!message) {
      message = 'Something went wrong, please try again later.';
    }
    return { [FORM_ERROR]: message };
  };

  const renderDetailSection = () => {
    switch (activityType) {
      case ACTIVITY_TYPES.PEER: {
        const onChange = (values: any) => {
          setIsMultipleTopics(values.isMultipleTopics);
        };
        return (
          <PeerReviewDetails
            activityInstructions={activityInstructions}
            activityObjective={activityObjective}
            isEdit={props.isEdit}
            isDuplicating={props.isDuplicating}
            onSubmit={onSubmit}
            formRef={formRef}
            onCancel={props.onCancel}
            onChange={onChange}
            errMessage={errMessage}
          />
        );
      }
      case ACTIVITY_TYPES.CALIBRATION:
        return (
          <CalibrationDetails
            isEdit={props.isEdit}
            isDuplicating={props.isDuplicating}
            onCancel={props.onCancel}
            onSubmit={onSubmit}
            formRef={formRef}
            canViewMode={!isStudentInCourse}
            innerRef={innerRef}
          />
        );
      case ACTIVITY_TYPES.GROUP:
      case ACTIVITY_TYPES.WITHIN_GROUP:
        return (
          <GroupDetails
            activityInstructions={activityInstructions}
            activityObjective={activityObjective}
            isEdit={props.isEdit}
            isDuplicating={props.isDuplicating}
            onEdit={props.onEdit}
            onCancel={props.onCancel}
            onSubmit={onSubmit}
            formRef={formRef}
            errMessage={errMessage}
          />
        );
      case ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION:
      case ACTIVITY_TYPES.GROUP_PRESENTATION:
        return (
          <PresentationDetails
            isEdit={props.isEdit}
            isDuplicating={props.isDuplicating}
            onEdit={props.onEdit}
            onCancel={props.onCancel}
            onSubmit={onSubmit}
            formRef={formRef}
          />
        );
      default:
        return null;
    }
  };

  const shouldRenderMainForm = () => {
    return !props.isRubricEditShow && !props.isRubricViewShow && !props.isSelectingCreation;
  };

  if ((props.isEdit || props.isDuplicating) && !props.activity) {
    return null;
  }

  return (
    <React.Fragment>
      <PageContainer style={{ display: shouldRenderMainForm() ? 'block' : 'none' }}>
        {props.title ? <PageHeader title={props.title} /> : null}
        <Section>
          <TypeSelectionSection />
        </Section>
        <Section>{renderDetailSection()}</Section>
      </PageContainer>
      {props.isRubricEditShow && (
        <PageContainer>
          <Rubric formRef={formRef} isEdit={props.isEdit} isEditing isSubmitting={isSubmitting} />
        </PageContainer>
      )}
      <FullScreenModal open={props.isRubricViewShow}>
        <PageContainer>
          <Rubric formRef={formRef} />
        </PageContainer>
      </FullScreenModal>
      {props.isSelectingCreation && (
        <PageContainer>
          <CalibrationCreationSelector formRef={formRef} />
        </PageContainer>
      )}
      <Confirm
        isOpen={changeTypeConfirm}
        onCancel={() => {
          return setChangeTypeConfirm(null);
        }}
        onConfirm={() => {
          return changeActivityType(changeTypeConfirm);
        }}
        cancelButton="No"
        confirmButton="Yes"
        title="Are you sure you want to change the activity type?"
        description="You will lose any changes you have made, except objective and instruction."
        testid="change-activity-type"
      />
      {!props.isRubricEditShow && !isSubmitting && !props.isEditingTemplate ? (
        <OnLeaveModal formRef={formRef} />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    courseId: state.selected.courseId,
    isRubricEditShow: selectIsRubricEditShow(state),
    isRubricViewShow: selectIsRubricViewShow(state),
    isSelectingCreation: selectIsSelectingCreation(state),
    isEditingTemplate: selectIsEditingTemplate(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    selectCourse,
    selectAssignment,
    updateAssignment,
    navigateToActivityPage,
    navigateToEditActivityParticipation,
    resetActivityEditor,
  })(ActivityEdit)
);
