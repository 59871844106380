import React, { useEffect, useState, useRef } from 'react';
import { Trash, PlusCircle, MinusCircle, Wrench } from 'images/icons/fa';
import PopperMenu from '../PopperMenu';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const OverlayMenu = (props: any) => {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(ref.current);
  }, [props.rightCoordinate, props.rowHeight, props.topCoordinate]);

  return (
    <>
      <div
        ref={ref}
        className="rubric-select-overlay"
        style={{
          right: props.rightCoordinate,
          height: props.rowHeight,
          top: props.topCoordinate,
        }}
      />
      <PopperMenu
        anchorEl={anchorEl}
        placement="right-start"
        options={[
          {
            label: <TranslatedText i18nKey="Rubric.EdgeButtons.AddHigherLevel" />,
            icon: <PlusCircle />,
            action: props.handleAddLevel,
            testid: 'add-higher-level',
          },
          {
            label: <TranslatedText i18nKey="Rubric.EdgeButtons.RemoveHighestLevel" />,
            icon: <MinusCircle />,
            action: props.handleRemoveLevel,
            testid: 'remove-highest-level',
          },
          {
            label: localize({ message: 'Rubric.Mode.Toggle' }),
            icon: <Wrench />,
            action: props.handleToggleModeScoring,
            testid: 'toggle-mode-scoring',
          },
          {
            label: <TranslatedText i18nKey="Rubric.EdgeButtons.RemoveCriteria" />,
            icon: <Trash />,
            action: props.handleRemoveCriteria,
            testid: 'remove-criteria',
          },
        ]}
      />
    </>
  );
};

OverlayMenu.defaultProps = {
  rightCoordinate: '',
  rowHeight: '',
  topCoordinate: '',
};

export default OverlayMenu;
