import { Field, useForm } from 'react-final-form';
import FormFieldSelector from 'components/core/form/SelectField';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { getNextTerms } from '@kritik/utils/course';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const NONE = localize({ message: 'CourseTermField.None' });

export const CourseTermField = () => {
  /*
  Next lines make sure that 
  the term set initially appears in the dropdown
  if course is updated later in time
*/
  const form = useForm();
  const { initialValues } = form.getState();
  const initialTerm = initialValues?.term || null;
  const nextTerms = getNextTerms();
  let options = [NONE];
  if (initialTerm && !nextTerms.includes(initialTerm)) {
    options.push(initialTerm);
  }
  options = options.concat(...nextTerms);

  return (
    <Field name={COURSE_FIELD_NAMES.TERM} type="text" style={{ marginTop: 0 }}>
      {({ input, meta }) => {
        return (
          <FormFieldSelector
            label={<TranslatedText i18nKey="CourseDetails.CourseInfo.Title.Term" />}
            options={options.map((option) => {
              return {
                label: option,
                value: option === NONE ? null : option,
              };
            })}
            value={input.value}
            onChange={input.onChange}
            onBlur={input.onBlur}
            error={meta.error && meta.touched && meta.error}
            placeholder={
              <TranslatedText i18nKey="CourseDetails.CourseInfo.Title.Term.Field.Placeholder" />
            }
            id="select-term"
          />
        );
      }}
    </Field>
  );
};
