import { useState } from 'react';
import { connect } from 'react-redux';
import { countryCodesOptions } from 'components/Setting/countrycodes';
import Button from 'components/buttons/Button';
import * as GeneralUtils from '@kritik/utils/general';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import InlineInformation from 'components/layout/InlineInformation';
import { updateAccountInformation } from 'actions/users';
import UpdateEmailNotice from 'components/Setting/UpdateEmailNotice';
import { getErrorMessageFromResponse } from 'utils/error';
import { Card, CardContent, CardActions } from '@material-ui/core';
import AutoCompleteField from 'components/core/form/AutoCompleteField';
import TextField from 'components/core/form/TextField';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';
import { useAuthUserFromRedux } from 'hooks/user';
import Select from 'components/core/input/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SupportedLanguage } from 'app-types';

type AccountSettingProps = {
  updateAccountInformation: (data: any) => Promise<void>;
  onSuccess: () => void;
};

function AccountSetting(props: AccountSettingProps) {
  const authUser = useAuthUserFromRedux();
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(authUser?.phoneNumber || '');
  const [countryCode, setCountryCode] = useState('');
  const [email, setEmail] = useState(authUser?.email);
  const [preferredLanguage, setPreferredLanguage] = useState(authUser.preferredLanguage);

  function updatePhoneNumber(countryCode: any, phoneNumber: any) {
    setPhoneNumber(phoneNumber);
    setCountryCode(countryCode);
    setError(false);
    setSuccess(false);
  }

  function updateEmail(email: any) {
    setEmail(email);
    setError(false);
    setSuccess(false);
  }

  function phoneNumberChanged() {
    const newNumber = countryCode + phoneNumber;
    return newNumber !== authUser?.phoneNumber && !success;
  }

  function preferredLanguageChanged() {
    return preferredLanguage !== authUser?.preferredLanguage;
  }

  function emailChanged() {
    return email !== authUser?.email && !success;
  }

  function hasUpdate() {
    return emailChanged() || phoneNumberChanged() || preferredLanguageChanged();
  }

  function handleSave() {
    const dataToSubmit = { userId: authUser.id };
    if (phoneNumberChanged()) {
      const _countryCode = GeneralUtils.sanitizeCountryCode(countryCode);
      if (
        phoneNumber.length > 0 &&
        !GeneralUtils.isValidE164PhoneNumber(_countryCode + phoneNumber)
      ) {
        setError(localize({ message: 'WrongPhoneNumberFormat' }));
        return;
      } else {
        (dataToSubmit as any).phoneNumber = phoneNumber === '' ? '' : _countryCode + phoneNumber;
      }
    }
    if (emailChanged()) {
      if (GeneralUtils.isEmail(email)) {
        (dataToSubmit as any).email = email;
      } else {
        setError('Please enter a valid email');
        return;
      }
    }
    if (preferredLanguageChanged()) {
      (dataToSubmit as any).preferredLanguage = preferredLanguage;
    }
    setSubmitting(true);
    props
      .updateAccountInformation(dataToSubmit)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        props.onSuccess();
      })
      .catch((err: any) => {
        setSubmitting(false);
        setSuccess(false);
        setError(
          getErrorMessageFromResponse(err?.errors?.message) ||
            'Something went wrong. Please try again later.'
        );
      });
  }

  function resetFields() {
    setSuccess(false);
    setError(null);
    setPhoneNumber(authUser?.phoneNumber || '');
    setCountryCode('');
    setEmail(authUser.email);
    setPreferredLanguage(authUser?.preferredLanguage);
  }

  return (
    <div className="account-settings__wrapper">
      <Card>
        <CardContent>
          <div className="account-settings__login">
            <TranslatedText i18nKey="AccountSetting.Login" />
          </div>
          <UpdateEmailNotice />
          <div className="email-input">
            <TextField
              id="email"
              label={localize({ message: 'Email' })}
              value={email}
              onChange={(e) => updateEmail(e.target.value)}
              style={{ width: '100%' }}
              disabled
              data-testid="update-email"
            />
          </div>
          <div className="account-settings__header">
            <TranslatedText i18nKey="AccountSetting.MobilePhoneNumber" />
          </div>
          <div className="phone-number-input">
            <AutoCompleteField
              label={localize({ message: 'AccountSettings.CountryCode' })}
              id="countryCode"
              options={countryCodesOptions}
              onChange={(v) => setCountryCode(v.value)}
              value={countryCode}
              placeholder={localize({ message: 'AccountSettings.CountryCode.Placeholder' })}
              testid="country-code-dropdown"
            />
            <TextField
              id="phoneNumber"
              label={localize({ message: 'AccountSettings.PhoneNumber' })}
              testid="phone-number-input"
              placeholder={localize({ message: 'AccountSettings.PhoneNumber.Placeholder' })}
              value={phoneNumber}
              onChange={(e) => updatePhoneNumber(countryCode, e.target.value)}
              classNames="phone-number-input__input"
              style={{ width: '100%' }}
            />
          </div>
          <div className="account-settings__header">
            <TranslatedText i18nKey="AccountSettings.LanguageSettings.Header" />
          </div>
          <div>
            <Select
              label={localize({ message: 'AccountSettings.LanguageSettings.Label' })}
              onChange={(v) => setPreferredLanguage(v.target.value as SupportedLanguage)}
              value={preferredLanguage}
              testid="language-settings-dropdown"
            >
              <MenuItem value="en">
                <TranslatedText i18nKey="Language.English" />
              </MenuItem>
              <MenuItem value="fr">
                <TranslatedText i18nKey="Language.French" />
              </MenuItem>
            </Select>
          </div>
        </CardContent>
        <CardActions>
          <FormSubmitButtons
            className="account-settings__buttons"
            errors={error && <InlineInformation type="danger">{error}</InlineInformation>}
          >
            <Button
              type="primary"
              onClick={handleSave}
              disabled={!hasUpdate()}
              loading={submitting}
              testid="update-account-settings"
              inputType="submit"
            >
              <TranslatedText i18nKey="Update" />
            </Button>
            <Button
              type="secondary"
              onClick={resetFields}
              disabled={!hasUpdate() || submitting}
              inputType="reset"
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          </FormSubmitButtons>
        </CardActions>
      </Card>
    </div>
  );
}

export default connect(null, { updateAccountInformation })(AccountSetting);
