import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import FormTitle from 'components/core/form/Title';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormContainer from 'components/core/form/Container';
import FormSection from 'components/core/form/Section';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import { Form, Field } from 'react-final-form';
import { ACTIVITY_GOALS, ACTIVITY_TEMPLATES } from 'components/ActivityEdit/Details/constants';
import AttachmentManager from 'components/layout/AttachmentManager';
import RubricPreview from 'components/ActivityEdit/Rubric/Preview';
import TitleField from 'components/ActivityEdit/Details/Fields/Title';
import NotesField from 'components/ActivityEdit/Details/Fields/Notes';
import { FIELD_NAME as TITLE } from 'components/ActivityEdit/Details/Fields/Title/constants';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import {
  HAS_EVALUATOR_NOTES,
  EVALUATOR_NOTES_FILES,
  FIELD_NAME as EVALUATOR_NOTES,
} from 'components/ActivityEdit/Details/Fields/Notes/constants';
import {
  FIELD_NAME as RUBRIC,
  FIELD_LABEL as RUBRIC_LABEL,
} from 'components/ActivityEdit/Details/Rubric/constants';
import ObjectiveField from 'components/ActivityEdit/Details/Fields/Objective';
import InstructionsField from 'components/ActivityEdit/Details/Fields/Instructions';
import { Button } from 'components/buttons';
import AdvancedOptions from 'components/ActivityEdit/AdvancedOptions';
import ErrorMsg from 'components/ActivityEdit/ErrorMsg';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { getInitialAdvOptionValues } from 'components/ActivityEdit/AdvancedOptions/utils';
import { selectRubrics } from 'selectors/rubric';
import { isScheduled, isDraft, isCreate } from '@kritik/utils/stage';
import {
  isMultipleTopics,
  getActivityTopics,
  hasEvaluatorNotes,
  getEvaluatorNotes,
  getEvaluatorNotesFiles,
  isNumericGrading,
  acceptsLateSubmissions,
  autoAcceptsLateSubmissions,
} from '@kritik/utils/activity';
import { FIELD_NAME as NUM_EVALS_TO_ASSIGN } from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign/constants';
import {
  ACCEPT_LATE_CREATIONS,
  AUTO_ACCEPT_LATE_CREATIONS,
  ACCEPT_LATE_EVALUATIONS,
  AUTO_ACCEPT_LATE_EVALUATIONS,
} from 'components/LateCreation/constants';
import { FIELD_NAME as WEIGHT } from 'components/ActivityEdit/AdvancedOptions/Fields/Weight/constants';
import {
  FIELD_NAME as TOPICS,
  IS_MULTIPLE_TOPICS,
  NUMBER_OF_TOPICS,
} from 'components/ActivityEdit/AdvancedOptions/Fields/Topics/constants';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from 'components/ActivityEdit/AdvancedOptions/Fields/MissedCreation/constants';

import localUtil from 'components/ActivityEdit/util';
import { useFetchCourse, useUserRoleInCourse } from 'hooks/course';
import { withRouter } from 'utils/withRouter';
import { InlineInformation } from 'components/layout';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

function getIndividualActivityDataFromForm(values: any) {
  return {
    activityType: values.activityType,
    files: values.files,
    [TITLE]: values[TITLE],
    [OBJECTIVE]: values[OBJECTIVE],
    [INSTRUCTIONS]: values[INSTRUCTIONS],
    numericGrading: values.isNumericGrading,
    [NUM_EVALS_TO_ASSIGN]: values[NUM_EVALS_TO_ASSIGN],
    isGroupActivity: false,
    rubric: values[RUBRIC],
    [WEIGHT]: values[WEIGHT],
    fileExtensionsAllowed: localUtil.getFileExtentionsAllowed(values),
    [TOPICS]: values[TOPICS],
    [IS_MULTIPLE_TOPICS]: values[IS_MULTIPLE_TOPICS],
    [NUMBER_OF_TOPICS]: values[NUMBER_OF_TOPICS],
    [HAS_EVALUATOR_NOTES]: values[HAS_EVALUATOR_NOTES],
    [EVALUATOR_NOTES]: values[EVALUATOR_NOTES],
    [ACCEPT_LATE_CREATIONS]: values[ACCEPT_LATE_CREATIONS],
    [AUTO_ACCEPT_LATE_CREATIONS]: values[AUTO_ACCEPT_LATE_CREATIONS],
    [ACCEPT_LATE_EVALUATIONS]: values[ACCEPT_LATE_EVALUATIONS],
    [AUTO_ACCEPT_LATE_EVALUATIONS]: values[AUTO_ACCEPT_LATE_EVALUATIONS],
    [EVALUATOR_NOTES_FILES]: values[EVALUATOR_NOTES_FILES],
    [removeStudentFromActivityAfterMissedCreation]:
      values[removeStudentFromActivityAfterMissedCreation],
    startingScore: values.startingScore,
    lateCreationPenaltyPercentage: values.lateCreationPenaltyPercentage,
    enableSimilarityReport: values.enableSimilarityReport,
  };
}

const PeerReview = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();

  const onSelectRubric = (rubric: any) => {
    props.form.mutators.setValue(RUBRIC, rubric);
  };

  const canEditRubric = () => {
    if (props.values.settings.isDuplicating) {
      return true;
    }
    const { activity } = props;
    const isValidStatus =
      !isScheduled(activity) ||
      isDraft({ assignment: activity }) ||
      isCreate({ assignment: activity });

    return isValidStatus;
  };

  return (
    <FormContainer>
      <FormTitle
        label={localize({ message: 'ActivityEdit.PeerReview.ActivityDetails' })}
        size="xl"
      />
      <TitleField />
      <ObjectiveField />
      <InstructionsField />
      <Field name="files">
        {({ input }) => {
          return (
            <FormField>
              <FormFieldLabel label={localize({ message: 'ActivityEdit.PeerReview.Resources' })} />
              <AttachmentManager
                label={localize({ message: 'ActivityEdit.PeerReview.UploadActivityFiles' })}
                onFileChange={input.onChange}
                fileList={input.value}
              />
            </FormField>
          );
        }}
      </Field>
      <FormSection>
        <FormTitle
          label={localize({ message: 'ActivityEdit.PeerReview.EvaluatorNotes' })}
          size="xl"
        />
        <NotesField />
      </FormSection>
      <FormSection>
        <FormTitle label={RUBRIC_LABEL} size="xl" />
        <RubricPreview
          rubric={props.values[RUBRIC]}
          canEditRubric={canEditRubric()}
          isNumericGrading={props.values.isNumericGrading}
          onSelectRubric={onSelectRubric}
          canViewMode={!isStudentInCourse}
          form={props.form}
        />
      </FormSection>
    </FormContainer>
  );
};

const FormWrapper = (props: any) => {
  const { data: course } = useFetchCourse(props.router.params.courseId);

  const getInitialFormValues = () => {
    let values = {};
    if (props.isEdit || props.isDuplicating) {
      values = {
        activityType: ACTIVITY_TYPES.PEER,
        [TITLE]: props.activity.title,
        [RUBRIC]: props.activity.rubric,
        userRubrics: props.userRubrics,
        [OBJECTIVE]: props.activityObjective || props.activity.objective,
        [INSTRUCTIONS]: props.activityInstructions || props.activity.instructions,
        files: props.activity.files,
        isNumericGrading: isNumericGrading(props.activity),
        [IS_MULTIPLE_TOPICS]: isMultipleTopics(props.activity),
        [NUMBER_OF_TOPICS]: props.activity.topics.length,
        [ACCEPT_LATE_CREATIONS]: acceptsLateSubmissions(props.activity),
        [AUTO_ACCEPT_LATE_CREATIONS]: autoAcceptsLateSubmissions(props.activity),
        [ACCEPT_LATE_EVALUATIONS]: Boolean(props.activity.acceptLateEvaluations),
        [AUTO_ACCEPT_LATE_EVALUATIONS]: Boolean(props.activity.autoAcceptLateEvaluations),
        [TOPICS]: getActivityTopics(props.activity),
        [HAS_EVALUATOR_NOTES]: hasEvaluatorNotes(props.activity),
        [EVALUATOR_NOTES]: getEvaluatorNotes(props.activity),
        [EVALUATOR_NOTES_FILES]: getEvaluatorNotesFiles(props.activity),
        [removeStudentFromActivityAfterMissedCreation]:
          props.activity.removeStudentFromActivityAfterMissedCreation,
        enableSimilarityReport: props.activity.enableSimilarityReport,
      };
    } else {
      const activityGoal = ACTIVITY_GOALS[0].value;
      const template = ACTIVITY_TEMPLATES[activityGoal];
      values = {
        activityType: ACTIVITY_TYPES.PEER,
        [TITLE]: '',
        [RUBRIC]: null,
        userRubrics: props.userRubrics,
        [OBJECTIVE]: props.activityObjective || template[OBJECTIVE],
        [INSTRUCTIONS]: props.activityInstructions || template[INSTRUCTIONS],
        files: [],
        isNumericGrading: true,
        [HAS_EVALUATOR_NOTES]: false,
        [EVALUATOR_NOTES_FILES]: [],
        [removeStudentFromActivityAfterMissedCreation]: false,
        enableSimilarityReport: course.enableSimilarityReport,
      };
    }
    return {
      ...getInitialAdvOptionValues(props.activity, course),
      ...values,
      settings: {
        isDuplicating: props.isDuplicating,
        isEdit: props.isEdit,
        minimumWordCountForEvaluations: props.activity
          ? props.activity.settings?.minimumWordCountForEvaluations
          : course.courseSettings?.minimumWordCountForEvaluations,
      },
    };
  };

  const handleOnSubmit = async (values: any) => {
    const formSettings = localUtil.getFormSettings(values);
    const data = getIndividualActivityDataFromForm(values);

    return await props.onSubmit({
      data,
      formSettings,
    });
  };

  const renderButtons = ({ submitting }: any) => {
    return (
      <FormSubmitButtons errors={<ErrorMsg />}>
        <Button type="primary" inputType="submit" loading={submitting} data-testid="save-activity">
          <TranslatedText i18nKey="ActivityEdit.PeerReview.SaveActivity" />
        </Button>
        <Button type="secondary" onClick={props.onCancel}>
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </FormSubmitButtons>
    );
  };
  const [formValues, setFormValues] = useState(getInitialFormValues());

  useEffect(() => {
    setFormValues(getInitialFormValues());
  }, [props.activityId]);

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={formValues}
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => {
            return value;
          });
        },
      }}
      render={({ handleSubmit, form, submitting, values }) => {
        props.formRef.current = form;
        props.onChange(values);

        return (
          <form onSubmit={handleSubmit}>
            <PeerReview form={form} values={values} activity={props.activity} />
            <AdvancedOptions
              course={course}
              form={form}
              values={values}
              activity={props.activity}
            />
            {props.errMessage ? (
              <InlineInformation type="danger">{props.errMessage}</InlineInformation>
            ) : null}
            {renderButtons({ submitting })}
          </form>
        );
      }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    userRubrics: selectRubrics(state),
    activityId: state.selected.assignmentId,
  };
};

export default withRouter(connect(mapStateToProps)(FormWrapper));
