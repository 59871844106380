import React from 'react';
import ErrorContainer from 'containers/Error';
import Page from 'pages/Page';
import { localize } from 'locales';

function Error() {
  return (
    <Page title={localize({ message: 'Error.PageTitle' })} meta={[]} link={[]} script={[]}>
      <ErrorContainer />
    </Page>
  );
}

export default Error;
