import React from 'react';
import * as statusUtil from '@kritik/utils/stage';
import { getNumEvaluationsReceived } from '@kritik/utils/creation/evaluation';
import StatusLabels from 'components/CreationList/StatusLabels';
import { TranslatedText } from 'components/TranslatedText';

const EvaluationCell = (props: any) => {
  if (
    !statusUtil.isEvaluate({ assignment: props.activity }) &&
    !statusUtil.isFeedbackOrLater({ assignment: props.activity })
  ) {
    return (
      <StatusLabels
        label={<TranslatedText i18nKey="EvaluationsReceived.NotStarted" />}
        status="unavailable"
      />
    );
  }
  const numEvalsReceived = getNumEvaluationsReceived(props.creation);
  return <>{numEvalsReceived}</>;
};

export default EvaluationCell;
