import * as modalTypes from 'types/modal';
import { localize } from 'locales';

const openFinalizedConfirmModal = ({ children, onConfirm }: any) => {
  return {
    type: modalTypes.OPEN_CONFIRM_MODAL,
    payload: {
      title: localize({ message: 'Activity.ActionItems.StillOutstandingIssues' }),
      description: localize({ message: 'Activity.ActionItems.RecommendResolveIssues' }),
      children,
      confirmButton: localize({ message: 'Activity.ActionItems.Button.MarkAsComplete' }),
      cancelButton: localize({ message: 'Cancel' }),
      onConfirm,
    },
  };
};

export default {
  openFinalizedConfirmModal,
};
