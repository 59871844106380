import TutorialBanner from 'components/tutorial/Banner';
import Badge from './Icon';
import { localize } from 'locales';

export const COPY = {
  image: <Badge />,
  title: localize({ message: 'CreationTutorial.Title' }),
  description: localize({ message: 'CreationTutorial.Description' }),
  link: {
    target: 'https://youtu.be/AhqecxXD6E0',
    type: localize({ message: 'CreationTutorial.Link.Type' }),
    length: localize({ message: 'CreationTutorial.Link.Length' }),
  },
};

const Banner = (props: any) => {
  return (
    <TutorialBanner
      className={props.className}
      image={COPY.image}
      title={COPY.title}
      description={COPY.description}
      link={COPY.link}
    />
  );
};

Banner.defaultProps = {
  className: '',
};

export default Banner;
