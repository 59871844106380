import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRosterSyncChanges, selectRosterSyncResult, selectRosterSyncStatus } from 'redux/edlink';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { closeGlobalModal } from 'actions/modals';
import Dialog from '@material-ui/core/Dialog';
import UpdatedCourseRoster from 'components/RosterManager/SyncRosterPopup/UpdatedCourseRoster';
import RosterSyncError from 'components/RosterManager/SyncRosterPopup/Error';
import PricingPlanConfirmation from 'components/RosterManager/SyncRosterPopup/PricingPlanConfirmation';
import lmsServices from 'services/lms';
import NoticeBoard from 'components/layout/NoticeBoard';
import EmailList from 'components/RosterManager/SyncRosterPopup/EmailList';
import { trackEvent } from 'utils/userEvents';
import { lmsSyncRosterMutation } from 'hooks/lms';
import { withRouter } from 'utils/withRouter';
import { getErrorMessageFromResponse } from 'utils/error';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

export const SYNC_ROSTER_MODAL_ID = 'sync-roster-modal';

const SyncRosterModal = ({
  course,
  getRosterSyncChanges,
  rosterSyncData,
  rosterSyncStatus,
  modalStatus,
  closeGlobalModal,
  onRosterSync,
  authUser,
  router,
}: any) => {
  const [isPricingConfirmed, setIsPricingConfirmed] = useState(course.price === 0);
  const [hasFirstSuccessfulSync, setHasFirstSuccessfulSync] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const lmsSyncRoster = lmsSyncRosterMutation(course._id);

  useEffect(() => {
    const getCourseFirstSuccessfulSync = async () => {
      setLoading(true);
      try {
        const response = await lmsServices().getCourseFirstSuccessfulSync({ courseId: course._id });
        if (response.status === 200) {
          setHasFirstSuccessfulSync(response.data?.hasSuccessfulSync);
          setLoading(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    };

    void getCourseFirstSuccessfulSync();
  }, []);

  useEffect(() => {
    if (isOpen()) {
      setError(null);
      getRosterSyncChanges({ courseId: course._id });
    }
  }, [modalStatus]);

  function togglePricingConfirmation() {
    setIsPricingConfirmed(!isPricingConfirmed);
  }

  function proceedWithRosterSync() {
    lmsSyncRoster.mutate(
      { courseId: course._id, apply: true },
      {
        onSuccess: (data) => {
          if (data?.message) {
            setError(data.message);
            return;
          }

          trackEvent('Synced Course Roster', authUser, {
            courseId: course._id,
          });

          onRosterSync();
          closeGlobalModal();
          router.push(`/course/${course._id}/roster?tab=pending`);
        },
        onError: (error: any) => {
          setError(getErrorMessageFromResponse(error));
        },
      }
    );
  }

  function hasChanges() {
    if (!rosterSyncData) {
      return false;
    }
    return (
      Boolean(rosterSyncData.studentsAdded.length) ||
      Boolean(rosterSyncData.studentsRemoved.length) ||
      Boolean(rosterSyncData.studentsUpdated.length) ||
      Boolean(rosterSyncData.collaboratorsAdded.length) ||
      Boolean(rosterSyncData.collaboratorsRemoved.length) ||
      Boolean(rosterSyncData.collaboratorsUpdated.length)
    );
  }

  function isOpen() {
    return modalStatus.options.props.id === SYNC_ROSTER_MODAL_ID;
  }

  function cancel() {
    closeGlobalModal();
  }

  function transitionStates() {
    if (rosterSyncStatus === 'pending') {
      return null;
    } else if (rosterSyncStatus === 'fail' || error) {
      return <RosterSyncError cancel={() => cancel()} error={error} />;
    }
    return null;
  }

  const canShowContent = rosterSyncStatus !== 'pending' && rosterSyncStatus !== 'fail' && !error;

  const blockSyncRoster =
    !loading &&
    !hasFirstSuccessfulSync &&
    (rosterSyncData?.studentsRemoved.length > 0 || rosterSyncData?.collaboratorsRemoved.length > 0);

  const hasAddedStudents = Boolean(rosterSyncData?.studentsAdded.length);
  const shouldCheckPriceConfirmation = !isPricingConfirmed && hasAddedStudents;
  const disableSyncButton = blockSyncRoster || !hasChanges() || shouldCheckPriceConfirmation;

  return (
    <Dialog
      className="main-modal"
      aria-labelledby="simple-dialog-title"
      open={Boolean(isOpen())}
      maxWidth="sm"
      fullWidth
      data-testid="sync-roster-modal"
    >
      <div className="sync-roster-modal">
        <div className="sync-roster-modal__title">
          {blockSyncRoster ? (
            <TranslatedText i18nKey="RosterManager.SyncRosterModal.CannotSyncRoster" />
          ) : (
            <TranslatedText i18nKey="RosterManager.SyncRosterModal.ConfirmSyncingRoster" />
          )}
        </div>
        {canShowContent ? (
          <div className="sync-roster-modal__confirm">
            {rosterSyncData && (
              <ModalContent
                rosterSyncData={rosterSyncData}
                blockSyncRoster={blockSyncRoster}
                course={course}
                togglePricingConfirmation={togglePricingConfirmation}
                isPricingConfirmed={isPricingConfirmed}
              />
            )}
            <ButtonContainer>
              <Button
                type="primary"
                unavailable={disableSyncButton}
                disabled={disableSyncButton}
                onClick={() => proceedWithRosterSync()}
                testid="sync-roster-proceed"
                loading={lmsSyncRoster.isLoading}
              >
                <TranslatedText i18nKey="RosterManager.SyncRosterModal.SyncRoster" />
              </Button>
              <Button type="secondary" onClick={cancel}>
                <TranslatedText i18nKey="RosterManager.SyncRosterModal.Cancel" />
              </Button>
            </ButtonContainer>
          </div>
        ) : (
          transitionStates()
        )}
      </div>
    </Dialog>
  );
};

const ModalContent = ({
  rosterSyncData,
  blockSyncRoster,
  course,
  togglePricingConfirmation,
  isPricingConfirmed,
}: any) => {
  if (blockSyncRoster) {
    return (
      <>
        <NoticeBoard
          type="caution"
          title={localize({ message: 'RosterManager.SyncRosterModal.MismatchTitle' })}
        >
          <EmailList
            added={[]}
            removed={[...rosterSyncData.studentsRemoved, ...rosterSyncData.collaboratorsRemoved]}
          />
        </NoticeBoard>
        <div className="sync-roster-modal__description">
          <p>
            <TranslatedText i18nKey="RosterManager.SyncRosterModal.MismatchDescription" />
          </p>
          <b>
            <TranslatedText i18nKey="RosterManager.SyncRosterModal.ToProceed" />
          </b>
          <ul>
            <li>
              <TranslatedText i18nKey="RosterManager.SyncRosterModal.ConfirmRemoval" />
            </li>
            <li>
              <TranslatedText i18nKey="RosterManager.SyncRosterModal.UpdateEmails" />
            </li>
          </ul>
          <TranslatedText i18nKey="RosterManager.SyncRosterModal.TryAgain" />
        </div>
      </>
    );
  }

  return (
    <>
      <UpdatedCourseRoster rosterChanges={rosterSyncData} />
      {rosterSyncData.message && (
        <NoticeBoard
          type="caution"
          title={
            rosterSyncData.message === 'UNABLE_TO_REMOVE_STUDENTS'
              ? localize({ message: 'RosterManager.SyncRosterModal.CannotRemoveStudents.Title' })
              : undefined
          }
        >
          {rosterSyncData.message === 'UNABLE_TO_REMOVE_STUDENTS'
            ? localize({ message: 'RosterManager.SyncRosterModal.CannotRemoveStudents.Content' })
            : rosterSyncData.message}
        </NoticeBoard>
      )}
      <div className="sync-roster-modal__description">
        <p>{localize({ message: 'RosterManager.SyncRosterModal.ConfirmSync.Info1' })}</p>
        <ul>
          <li>{localize({ message: 'RosterManager.SyncRosterModal.ConfirmSync.Info2' })}</li>
          <li>{localize({ message: 'RosterManager.SyncRosterModal.ConfirmSync.Info3' })}</li>
        </ul>
        {localize({ message: 'RosterManager.SyncRosterModal.ConfirmSync.Info4' })}
      </div>
      <PricingPlanConfirmation
        course={course}
        studentsAdded={rosterSyncData?.studentsAdded?.length || 0}
        onChange={togglePricingConfirmation}
        isChecked={isPricingConfirmed}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    modalStatus: state.modal,
    rosterSyncStatus: selectRosterSyncStatus(state),
    rosterSyncData: selectRosterSyncResult(state),
    authUser: state.user.authUser,
  };
};

export default withRouter(
  connect(mapStateToProps, { getRosterSyncChanges, closeGlobalModal })(SyncRosterModal)
);
