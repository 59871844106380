import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Button } from 'components/buttons';
import { localize } from 'locales';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowLeft from 'images/leftArrow.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import { useState } from 'react';
import { useGetActivityValidRevertStage, useRevertActivityMutation } from 'hooks/activity';
import { activeStages, translateStageName } from '@kritik/utils/stage';
import { InlineInformation } from 'components/layout';
import { RevertInformation } from './RevertActivityInformation/RevertActivityInformation';
import { enqueueSnackbar } from 'notistack';
import { StageRevertingNotice } from './RevertActivityInformation/StageRevertingNotice';
import { BetaBadge } from 'components/Badges';
import { trackEvent } from 'utils/userEvents';
import { useAuthUserFromRedux } from 'hooks/user';
import { Activity, Course } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';

type RevertActivityModalProps = {
  activity: Activity;
  course: Course;
  onClose: () => void;
  open: boolean;
};

const RevertActivityModal = ({ course, activity, open, onClose }: RevertActivityModalProps) => {
  const authUser = useAuthUserFromRedux();
  const activityId = activity._id;

  const revertActivityMutation = useRevertActivityMutation(activityId);
  const [error, setError] = useState<string | null>(null);
  const [finished, setFinished] = useState(false);

  const _onClose = () => {
    onClose();
    setTimeout(() => {
      setFinished(false);
    }, 1000);
  };
  const { data, isLoading } = useGetActivityValidRevertStage(activityId);

  if (isLoading) {
    return null;
  }

  const errorMessage = data && data.error ? data?.error : null;
  const stages = data ? data : null;

  const handleConfirmRevert = () => {
    const { previousStage } = stages;

    revertActivityMutation.mutate(
      { activityId: activityId, revertTo: previousStage },
      {
        onSuccess: () => {
          _onClose();

          trackEvent('Reverted a Stage', authUser, {
            instructorEmail: authUser.email,
            courseId: course._id,
            courseName: course.title,
            activityId,
            activityName: activity.title,
            activityStage: previousStage,
          });

          enqueueSnackbar(
            localize({
              message:
                previousStage === activeStages.DRAFT
                  ? 'Activity.Revert.To.Draft.Success.Message'
                  : 'Activity.Revert.Success.Message',
            }),
            {
              title: localize({
                message: 'Activity.Revert.Success.Title',
                options: { stageName: previousStage },
              }),
              variant: 'success',
              testid: 'revert-activity-success',
            }
          );
        },
        onError: ({ response }) => {
          enqueueSnackbar(
            localize({
              message: 'Activity.Revert.Error.Message',
            }),
            {
              title: localize({
                message: 'Activity.Revert.Error.Title',
                options: { stageName: previousStage },
              }),
              variant: 'error',
              testid: 'revert-activity-error',
            }
          );

          const errorMessage = response?.data?.errors.message;
          setError(errorMessage);
        },
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={_onClose}
      aria-labelledby="revert-activity-dialog-title"
      aria-describedby="revert-activity-description"
      className="revert-activity-modal"
    >
      {!finished && (
        <div className="revert-activity-modal-container">
          <DialogTitle className="revert-dialog-title" data-testid="revert-activity-title">
            <span>{localize({ message: 'Activity.Details.Action.Revert' })}</span>
            <BetaBadge />
          </DialogTitle>
          <DialogContent className="revert-activity-modal-content">
            <div className="revert-modal-content_container">
              <div className="revert-activity-modal-content_stages-container">
                <div className="stage-to" data-testid={`stage-to-${data.previousStage}`}>
                  {translateStageName({ stageName: data.previousStage, activity })}
                </div>
                <div className="arrow-pointer-container">
                  <div className="arrow-icon">
                    <img src={ArrowLeft} alt="Arrow Left" />
                  </div>

                  {!data.canRevert ? (
                    <div className="times-circle-icon" data-testid="cannot-revert">
                      <CancelIcon />
                    </div>
                  ) : (
                    <div className="check-circle-icon" data-testid="can-revert">
                      <CheckCircleIcon />
                    </div>
                  )}
                </div>
                <div className="stage-from" data-testid={`stage-from-${data.activeStage}`}>
                  {translateStageName({ stageName: data.activeStage, activity })}
                </div>
              </div>

              {errorMessage?.includes('cannot be reverted') && (
                <RevertInformation activity={activity} />
              )}

              <div className="revert-modal-content_stages-copy">
                <StageRevertingNotice
                  canRevert={data.canRevert}
                  activeStage={data.activeStage}
                  validRevertStage={data.previousStage}
                  activity={activity}
                />
              </div>
            </div>

            {error && (
              <InlineInformation type="danger" style={{ marginTop: '20px' }}>
                {error}
              </InlineInformation>
            )}
          </DialogContent>
          <DialogActions className="revert-activity-modal-container_button-container">
            <Button
              type="primary"
              inputType="submit"
              data-testid="revert-activity-confirm"
              disabled={!data.canRevert}
              onClick={() => handleConfirmRevert()}
            >
              <TranslatedText i18nKey="Activity.Revert.ConfirmButton" />
            </Button>
            <Button
              onClick={_onClose}
              type="secondary"
              autoFocus
              data-testid="cancel-create-course"
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default RevertActivityModal;
