import { useState } from 'react';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import MuiButton from '@material-ui/core/Button';
import NewTabButton from 'components/buttons/NewTab';
import QuestionCircle from 'images/icons/fa/QuestionCircle';
import Typography from 'components/core/Typography';
import { localize } from 'locales';

const InfoPopup = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const togglePopover = (e: any) => {
    if (isCancelling) {
      return setIsCancelling(false);
    }
    setIsExpanded(true);
    return setAnchorEl(e.currentTarget);
  };

  const handleClickAway = (e: any) => {
    if (anchorEl && (anchorEl as any).contains(e.target)) {
      setIsCancelling(true);
    }
    setIsExpanded(false);
    setAnchorEl(null);
  };

  const classes = classNames('popup-info__icon', {
    'popup-info__icon--active': !!anchorEl,
  });

  const popoverContentClasses = classNames('info-popup', props.popoverContentClass);

  const renderMedia = () => {
    if (!props.media) {
      return null;
    }
    return <div> {props.media} </div>;
  };

  const renderTitle = () => {
    if (!props.title) {
      return null;
    }
    return (
      <div className="info-popup__title">
        <Typography fontSize="18px" weight="700">
          {props.title}
        </Typography>
      </div>
    );
  };

  const renderDescription = () => {
    if (!props.description) {
      return null;
    }
    return (
      <div className="info-popup__description">
        <Typography fontSize="16px" color="light" weight="400">
          {props.description}
        </Typography>
      </div>
    );
  };

  const DisplayAdditionalInfo = () => {
    if (!props.additionalInfo) {
      return null;
    }
    return (
      <Typography fontSize="16px" color="light" weight="400" fontStyle="italic">
        {props.additionalInfo}
      </Typography>
    );
  };

  function onClickLearnMore() {
    window.open(props.learnMoreUrl);
  }

  const renderAction = () => {
    if (!props.learnMoreUrl) {
      return null;
    }
    return (
      <div className="info-popup__action">
        <NewTabButton
          onClick={onClickLearnMore}
          label={props.learnMoreLabel ?? localize({ message: 'LearnMore' })}
          title={localize({
            message: 'Title.Text.ScoreCalculation',
            options: { section: props.title },
          })}
        />
      </div>
    );
  };

  return (
    <>
      <MuiButton
        variant="text"
        onClick={togglePopover}
        style={{ minWidth: '0px' }}
        className={props.btnClassName}
        data-testid={props.testid}
        aria-label={
          props.title
            ? `${props.title} ${localize({ message: 'Button.InfoPopup' })}`
            : localize({ message: 'Button.InfoPopup' })
        }
        aria-expanded={isExpanded}
        aria-haspopup="dialog"
      >
        {props.buttonContent ? props.buttonContent : <QuestionCircle className={classes} />}
      </MuiButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!anchorEl}
        onClose={() => {
          setIsExpanded(false);
          setAnchorEl(null);
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener
          onClickAway={handleClickAway}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <div
            data-testid="info-popup"
            className={popoverContentClasses}
            onBlur={() => {
              setIsExpanded(false);
              setAnchorEl(null);
            }}
          >
            {renderMedia()}
            {renderTitle()}
            {renderDescription()}
            <DisplayAdditionalInfo />
            {renderAction()}
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

InfoPopup.defaultProps = {
  media: null,
  title: null,
  description: null,
  learnMoreUrl: null,
  learnMoreLabel: null,
  additionalInfo: null,
};

export default InfoPopup;
