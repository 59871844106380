import { getActivityWeight, isCalibrationActivity } from '@kritik/utils/activity';
import InlineInformation from 'components/layout/InlineInformation';
import { MIN_WEIGHT } from '@kritik/constants/activity';
import { useUserRoleInCourse } from 'hooks/course';
import { TranslatedText } from 'components/TranslatedText';

const WeightWarning = (props: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();

  if (isInstructorInCourse) {
    if (isCalibrationActivity(props.activity)) {
      return (
        <InlineInformation type="information">
          <TranslatedText i18nKey="Assignment.Weight.Warning.CalibrationInstructor" />
        </InlineInformation>
      );
    }
    if (getActivityWeight(props.activity) === MIN_WEIGHT) {
      return (
        <InlineInformation type="information">
          <TranslatedText i18nKey="Assignment.Weight.Warning.NoWeightInstructor" />
        </InlineInformation>
      );
    }
  } else {
    if (isCalibrationActivity(props.activity)) {
      return (
        <InlineInformation type="information">
          <TranslatedText i18nKey="Assignment.Weight.Warning.CalibrationStudent" />
        </InlineInformation>
      );
    }
    if (getActivityWeight(props.activity) === MIN_WEIGHT) {
      return (
        <InlineInformation type="information">
          <TranslatedText i18nKey="Assignment.Weight.Warning.NoWeightStudent" />
        </InlineInformation>
      );
    }
  }
  return null;
};

export default WeightWarning;
