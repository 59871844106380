import { connect } from 'react-redux';
import ElipsesMenu from 'components/General/ElipsesMenu';
import { removeStudentInvite } from 'redux/studentInvite';
import { removeStudentInviteMutation } from 'hooks/course';
import { localize } from 'locales';

const Menu = (props: any) => {
  const removeStudentInvite = removeStudentInviteMutation(props.courseId);
  const removeInvite = () => {
    removeStudentInvite.mutate({ courseId: props.courseId, studentEmail: props.studentEmail });
  };

  const OPTIONS = [
    {
      testid: `cancel-invite-${props.studentEmail}`,
      label: localize({ message: 'RosterManager.Pending.Menu.CancelInvite' }),
      action: removeInvite,
    },
  ];
  return (
    <ElipsesMenu
      testid="cancel-invite"
      options={OPTIONS}
      labelI18nKey="RosterManager.StudentsTab.MoreOptionsMenu.Label"
    />
  );
};

export default connect(null, { removeStudentInvite })(Menu);
