import React, { useState } from 'react';
import { connect } from 'react-redux';
import Table from 'components/layout/SimpleTable';
import TableRow from 'components/layout/SimpleTable/Row';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableBody from 'components/layout/SimpleTable/Body';
import { navigateToActivityPage } from 'actions/activity';
import TitleCell from 'components/ActivityList/Cells/Title';
import WeightCell from 'components/ActivityList/Cells/Weight';
import ScheduleCell from 'components/ActivityList/Cells/Schedule';
import StatusCell from 'components/ActivityList/Cells/Status';
import StudentSelectors from 'selectors/student';
import { TranslatedText } from 'components/TranslatedText';

const StudentActivityList = (props: any) => {
  const [hoveredActivityId, setHoveredActivityId] = useState(null);
  const redirectTo = (activity: any) => {
    props.navigateToActivityPage({ courseId: activity.course, assignmentId: activity._id });
  };

  const renderTableHeader = () => {
    if (props.renderHeader) {
      return (
        <TableHead>
          <TableCell color="light">
            <span tabIndex={0}>
              <TranslatedText i18nKey="StudentActivityList.Name" />
            </span>
          </TableCell>
          <TableCell color="light" align="right">
            <span style={{ marginRight: '18px' }} tabIndex={0}>
              <TranslatedText i18nKey="StudentActivityList.Weight" />
            </span>
          </TableCell>
          <TableCell color="light" align="left">
            <span tabIndex={0}>
              <TranslatedText i18nKey="StudentActivityList.Completed" />
            </span>
          </TableCell>
          <TableCell minWidth="220px">
            <span tabIndex={0}>
              <TranslatedText i18nKey="StudentActivityList.Issues" />
            </span>
          </TableCell>
        </TableHead>
      );
    }
  };

  return (
    <Table>
      {renderTableHeader()}
      <TableBody>
        {props.activityList.map((activity: any) => {
          const creation = props.activities[activity._id].userAssignment;
          return (
            <TableRow
              key={`unscheduled-activity-${activity._id}`}
              isSelectable
              onSelectRow={() => {
                redirectTo(activity);
              }}
              onMouseOver={() => {
                setHoveredActivityId(activity._id);
              }}
              onMouseLeave={() => {
                setHoveredActivityId(null);
              }}
            >
              <TitleCell activity={activity} />
              <TableCell align="right" whiteSpace="nowrap" color="light">
                <WeightCell activity={activity} />
              </TableCell>
              <TableCell whiteSpace="nowrap" align="left" color="light">
                <span tabIndex={0}>
                  <ScheduleCell
                    isRowHovered={hoveredActivityId === activity._id}
                    activity={activity}
                  />
                </span>
              </TableCell>
              <TableCell>
                <StatusCell activity={activity} creation={creation} student={props.student} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

StudentActivityList.defaultProps = {
  renderHeader: true,
};

const mapStateToProps = (state: any) => {
  return {
    activities: state.entities.assignments,
    student: StudentSelectors.getStudentFromUser(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, { navigateToActivityPage })(StudentActivityList);
