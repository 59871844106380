import React from 'react';
import StatusLabel from 'components/Creation/StatusLabel';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { TranslatedText } from 'components/TranslatedText';

export const MissedLabel = (props: any) => {
  if (ActivityStatusUtil.isEvaluateOrLater(props.activity)) {
    if (CreationStatusUtil.wasCreationMissed(props.creation)) {
      return (
        <StatusLabel status="danger" label={<TranslatedText i18nKey="StatusLabels.Missed" />} />
      );
    }
    if (CreationStatusUtil.isCreationEmpty(props.creation)) {
      return (
        <StatusLabel status="danger" label={<TranslatedText i18nKey="StatusLabels.Overdue" />} />
      );
    }
  }
  return null;
};

export default MissedLabel;
