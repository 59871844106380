import React from 'react';
import classNames from 'classnames';
import { getGracePeriodLengthInMinutes } from '@kritik/utils/stage';
import { timeDisplayFromMinutes } from '@kritik/utils/format';
import { TranslatedText } from 'components/TranslatedText';

type Props = {
  gracePeriodStatus: any;
  state: any;
  testid?: string;
};
function GracePeriodDisplay({ gracePeriodStatus, state, testid }: Props) {
  const lengthInMinutes = getGracePeriodLengthInMinutes(gracePeriodStatus);

  const generateText = () => {
    if (lengthInMinutes === 2 * 60) {
      return <TranslatedText i18nKey="GracePeriodSelect.TwoHoursGrace" />;
    }
    if (lengthInMinutes === 12 * 60) {
      return <TranslatedText i18nKey="GracePeriodSelect.TwelveHoursGrace" />;
    }
    if (lengthInMinutes === 24 * 60) {
      return <TranslatedText i18nKey="GracePeriodSelect.OneDayGrace" />;
    }
    const message = timeDisplayFromMinutes(lengthInMinutes);
    return <TranslatedText i18nKey="GracePeriodSelect.MessageGrace" values={{ message }} />;
  };

  const statusClass = state;
  const containerClassNames = classNames('grace-period-display-container', statusClass);
  return (
    <div className={containerClassNames}>
      <div className="grace-period-display-text">
        <span data-testid={testid}>{generateText()}</span>
      </div>
    </div>
  );
}

GracePeriodDisplay.defaultProps = {
  state: 'inactive',
};

export default GracePeriodDisplay;
