import { LATE_STATUS } from '@kritik/utils/creation/lateCreation';
import Modal from 'components/modals/Components/Modal';
import ModalTitle from 'components/modals/Components/Header';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const RejectionWarningModal = ({
  isRejectingApprovedCreation,
  resolveLateCreation,
  cancelRejection,
}: any) => {
  return (
    <Modal isOpen={isRejectingApprovedCreation}>
      <ModalTitle title={localize({ message: 'RejectionWarningModal.Title' })} />
      <ModalContent>
        <div className="late-submission-modal__text">
          <TranslatedText i18nKey="RejectionWarningModal.Content" />
        </div>
      </ModalContent>
      <ModalAction
        actions={[
          {
            label: localize({ message: 'RejectionWarningModal.Action.Reject' }),
            type: 'secondary',
            onClick: () => {
              resolveLateCreation(LATE_STATUS.REJECTED, null);
            },
          },
          {
            label: localize({ message: 'Cancel' }),
            type: 'primary',
            onClick: () => {
              cancelRejection();
            },
          },
        ]}
      />
    </Modal>
  );
};

export default RejectionWarningModal;
