import { useState, useEffect } from 'react';
import PageHeader from 'components/layout/PageHeader';
import Rubric from 'components/Rubric/Component';
import Menu from 'components/RubricManager/Overview/Menu';
import SectionHeader from 'components/layout/SectionHeader';
import { deleteRubric, copyRubricTemplate } from 'actions/rubrics';
import { connect } from 'react-redux';
import RubricName from 'components/RubricManager/Overview/RubricName';
import { ConfirmModal } from 'components/modals';
import { userSelector } from 'selectors';
import * as rubricUtils from '@kritik/utils/rubric';
import { resetAsync } from 'actions/async';
import {
  selectRubric,
  selectDeleteRubricState,
  selectCopyRubricState,
} from 'redux/rubricManager/selectors';
import rubricManagerTypes from 'redux/rubricManager/types';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const RubricOverview = (props: any) => {
  if (!props.rubric) {
    return null;
  }

  const isDefaultRubric = rubricUtils.isDefaultRubric(props.rubric);

  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

  const handleRubricDelete = () => {
    setDisplayDeleteModal(false);
    props.deleteRubric(props.rubric);
  };

  useEffect(() => {
    return function cleanUp() {
      resetAsync(rubricManagerTypes.DELETE_RUBRIC);
      resetAsync(rubricManagerTypes.COPY_RUBRIC);
    };
  }, []);

  const displayDeleteRubricModal = () => {
    return (
      <ConfirmModal
        isOpen={displayDeleteModal && !props.deleteRubricState.busy}
        title={<TranslatedText i18nKey="RubricOverview.DeleteModal.Title" />}
        testid="delete-rubric_modal"
        description={localize({
          message: 'RubricOverview.DeleteModal.Description',
          options: { name: props.rubric.name },
        })}
        cancelButton={<TranslatedText i18nKey="Cancel" />}
        confirmButton={<TranslatedText i18nKey="RubricOverview.DeleteModal.ConfirmButton" />}
        isDangerous
        onConfirm={handleRubricDelete}
        onCancel={() => {
          setDisplayDeleteModal(false);
        }}
        importantInfo={<TranslatedText i18nKey="RubricOverview.DeleteModal.ImportantInfo" />}
      />
    );
  };

  const handleRubricCopy = () => {
    props.copyRubricTemplate(props.rubric, props.user);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div className="rubric-manager__header">
        <PageHeader
          title={<TranslatedText i18nKey="RubricOverview.PageHeader" />}
          action={props.backAction}
        />
      </div>
      <div className="rubric-manager__separator" />
      <div className="rubric-manager__content">
        {displayDeleteModal && displayDeleteRubricModal()}
        <SectionHeader
          key={props.rubric._id}
          title={<RubricName rubric={props.rubric} key={props.rubric.name} />}
          buttonMenu={
            <Menu
              onDeleteRubric={() => {
                setDisplayDeleteModal(true);
              }}
              onCopyRubric={() => {
                handleRubricCopy();
              }}
              isDefaultRubric={isDefaultRubric}
              disabled={props.copyRubricState.busy || props.deleteRubricState.busy}
              coreAction={props.coreAction}
              rubric={props.rubric}
            />
          }
          info={props.rubric.description}
        />
        <Rubric rubric={props.rubric} isEditable={false} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    rubric: selectRubric(state),
    user: userSelector.selectAuthUser(state),
    deleteRubricState: selectDeleteRubricState(state),
    copyRubricState: selectCopyRubricState(state),
  };
};

RubricOverview.defaultProps = {
  backAction: null,
  coreAction: null,
};

export default connect(mapStateToProps, { deleteRubric, copyRubricTemplate })(RubricOverview);
