import React from 'react';
import { Icon } from '@material-ui/core';
import classNames from 'classnames';
import { localize } from 'locales';

const TYPES = {
  INFORMATION: 'information',
  ATTENTION: 'attention',
  CAUTION: 'caution',
  DANGER: 'danger',
  SUCCESS: 'success',
  PENDING: 'pending',
};

const NoticeBoard = ({
  children,
  closeable,
  inline,
  title,
  type,
  onClose,
  testid,
  ...other
}: any) => {
  const containerClasses = classNames('noticeboard', {
    inline,
    'noticeboard-information': type === TYPES.INFORMATION,
    'noticeboard-attention': type === TYPES.ATTENTION,
    'noticeboard-caution': type === TYPES.CAUTION || type === TYPES.PENDING,
    'noticeboard-danger': type === TYPES.DANGER,
    'noticeboard-success': type === TYPES.SUCCESS,
  });

  const labelClasses = classNames('noticeboard-label', {
    information: type === TYPES.INFORMATION,
    attention: type === TYPES.ATTENTION,
    caution: type === TYPES.CAUTION || type === TYPES.PENDING,
    danger: type === TYPES.DANGER,
    success: type === TYPES.SUCCESS,
  });

  const iconClasses = classNames('noticeboard-icon', 'fa', {
    'fa-info-circle': type === TYPES.ATTENTION || type === TYPES.INFORMATION,
    'fa-exclamation-circle': type === TYPES.DANGER,
    'fa-exclamation-triangle': type === TYPES.CAUTION,
    'fa-check-circle': type === TYPES.SUCCESS,
    'fa-clock': type === TYPES.PENDING,
  });

  const mediaClasses = classNames('noticeboard-media', {
    information: type === TYPES.INFORMATION,
    attention: type === TYPES.ATTENTION,
    caution: type === TYPES.CAUTION || type === TYPES.PENDING,
    danger: type === TYPES.DANGER,
    success: type === TYPES.SUCCESS,
  });

  const bodyClasses = classNames('noticeboard-body', {
    information: type === TYPES.INFORMATION,
    attention: type === TYPES.ATTENTION,
    caution: type === TYPES.CAUTION || type === TYPES.PENDING,
    danger: type === TYPES.DANGER,
    success: type === TYPES.SUCCESS,
  });

  const renderCloseButton = () => {
    if (!closeable || !onClose) {
      return null;
    }
    return (
      <button
        className="noticeboard__close-button"
        aria-hidden={false}
        aria-label={localize({ message: 'Close' })}
        onClick={onClose}
      >
        <i className="fas fa-times" />
      </button>
    );
  };

  if (inline) {
    return (
      <div className={containerClasses} data-testid={testid} {...other}>
        <div className={mediaClasses}>
          <Icon className={iconClasses} />
        </div>
        <div className="noticeboard-info">
          <div className={`${children ? bodyClasses : 'hidden'}`}>{children}</div>
        </div>
      </div>
    );
  }
  return (
    <div className={containerClasses} data-testid={testid} {...other}>
      <div className={mediaClasses}>
        <i className={iconClasses} />
      </div>
      <div className="noticeboard-info">
        <div className={labelClasses}>
          {title}
          {renderCloseButton()}
        </div>
        <div className={`${children ? bodyClasses : 'hidden'}`}>{children}</div>
      </div>
    </div>
  );
};

NoticeBoard.defaultProps = {
  type: TYPES.INFORMATION,
};

export default NoticeBoard;
