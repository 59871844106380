import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DueDateDisplay from 'components/Assignment/DueDateDisplay';
import { AssignmentStatusLabel } from 'components/AssignmentLabels/StatusLabels';
import {
  getNextDate,
  isCreate,
  isEvaluate,
  isFeedback,
  isGrading,
  isFinalized,
  isInCreationGracePeriod,
  isEvaluateOrLater,
} from '@kritik/utils/stage';
import { getActivityStatsByCourse } from 'actions/activity';
import { exactDate } from '@kritik/utils/format';
import { isCreationOverdue } from '@kritik/utils/creation/status';
import { PanelDisplay } from 'components/layout';
import { getAssignment, selectActivityStats } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import {
  CreateStage,
  EvaluateState,
  FeedbackState,
} from 'components/ActivityCard/Footer/StatusProgress';
import { useUserRoleInCourse } from 'hooks/course';
import { isEvaluationOverdue } from '@kritik/utils/creation/evaluation';
import { getStudentFromUser } from 'selectors/student';
import { getMySubmission } from 'selectors/creation';
import { FinalizedStatus } from './FinalizedStatus';
import { ActionItemsBox } from './ActionItemsBox';
import { ConfirmModal } from 'components/modals';
import { TranslatedText } from 'components/TranslatedText';
import { useNavigate } from 'react-router';
import { isCalibrationActivity } from '@kritik/utils/activity';

const mapStateToProps = (state: any) => {
  return {
    assignmentId: state.selected.assignmentId,
    assignment: getAssignment(state, state.selected.assignmentId),
    user: state.user,
    student: getStudentFromUser(state, state.selected.courseId),
    creation: getMySubmission(state, state.selected.assignmentId),
  };
};

const AssignmentStatusPanel = ({ assignment, user, student, creation }: any) => {
  const [showSyncGradesModal, setShowSyncGradesModal] = useState(false);
  const navigate = useNavigate();

  const displayExactDate = () => {
    const date = getNextDate(assignment);
    return date ? exactDate(date) : null;
  };

  if (!assignment) {
    return null;
  }

  return (
    <PanelDisplay>
      <div className="activity-status-panel__header">
        <OverdueLabel user={user} assignment={assignment} student={student} creation={creation} />
        {!isGrading({ assignment }) && (
          <div className="activity-status-panel__header-title">
            <DueDateDisplay assignment={assignment} />
          </div>
        )}
        <div className="activity-status-panel__header-description">{displayExactDate()}</div>
      </div>
      <InfoSection
        user={user}
        assignment={assignment}
        setShowSyncGradesModal={setShowSyncGradesModal}
      />
      <ConfirmModal
        isOpen={showSyncGradesModal}
        title={<TranslatedText i18nKey="GradingStatus.SyncGradesModal.Title" />}
        description={<TranslatedText i18nKey="GradingStatus.SyncGradesModal.Description" />}
        onConfirm={() => navigate(`/course/${assignment.course}/lms-grade-sync?syncBy=activity`)}
        confirmButton={<TranslatedText i18nKey="GradingStatus.SyncGradesModal.Confirm" />}
        onCancel={() => setShowSyncGradesModal(false)}
        cancelButton={<TranslatedText i18nKey="Cancel" />}
      />
    </PanelDisplay>
  );
};

const OverdueLabel = ({ assignment, student, creation }: any) => {
  if (
    isCreationOverdue(assignment, creation) ||
    isEvaluationOverdue({ activity: assignment, student, creation })
  ) {
    return (
      <div className="activity-status-panel__header-label">
        <AssignmentStatusLabel assignment={assignment} student={student} creation={creation} />
      </div>
    );
  }
  return null;
};

const InfoSection = ({ user, assignment, setShowSyncGradesModal }: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (isInstructorInCourse) {
    return (
      <>
        <div className="activity-status-panel__active-info">
          <ConnectedStageProgress user={user} assignment={assignment} />
        </div>
        {isEvaluateOrLater(assignment) && (
          <>
            {isFinalized({ assignment }) && <FinalizedStatus activity={assignment} />}
            {!isCalibrationActivity(assignment) && (
              <ActionItemsBox
                activity={assignment}
                setShowSyncGradesModal={setShowSyncGradesModal}
              />
            )}
          </>
        )}
      </>
    );
  }
  return null;
};

const StageProgress = ({ assignment, activityStats, course, getActivityStatsByCourse }: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  useEffect(() => {
    if (isInstructorInCourse) {
      getActivityStatsByCourse({ courseId: course._id });
    }
  }, []);

  if (!isInstructorInCourse) {
    return null;
  }
  const stats = activityStats[assignment._id];
  if (isCreate({ assignment }) || isInCreationGracePeriod(assignment)) {
    return (
      <CreateStage
        activityStats={stats}
        activity={assignment}
        isInstructor={isInstructorInCourse}
      />
    );
  }
  if (isEvaluate({ assignment })) {
    return (
      <EvaluateState
        activityStats={stats}
        activity={assignment}
        isInstructor={isInstructorInCourse}
      />
    );
  }
  if (isFeedback({ assignment })) {
    return (
      <FeedbackState
        activityStats={stats}
        activity={assignment}
        isInstructor={isInstructorInCourse}
      />
    );
  }
  return null;
};

const ConnectedStageProgress = connect(
  (state: any) => {
    return {
      course: getCourse(state),
      activityStats: selectActivityStats(state),
    };
  },
  { getActivityStatsByCourse }
)(StageProgress);

export default connect(mapStateToProps)(AssignmentStatusPanel);
