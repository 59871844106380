import React, { useState } from 'react';
import { EvaluationPending } from 'components/Creation/Evaluate';
import StudentContent from 'components/layout/StudentContent';
import TabMenu, {
  statuses,
  statuses as tabMenuStatuses,
  TabMenuUtils,
} from 'components/layout/TabMenu';
import { EvaluationWithFeedback } from 'components/Creation';
import * as StudentUtil from 'utils/student';
import * as EvaluationUtil from '@kritik/utils/creation/evaluation';
import * as ActivityStatusUtils from '@kritik/utils/stage';
import InfoLabel from 'components/layout/InfoLabel';
import { Activity, Creation, User, Student } from '@kritik/types.generated';
import { TranslatedText } from 'components/TranslatedText';
import AiEvaluationTab from './AiEvaluationTab';
import { localize } from 'locales';

const text = {
  title__evaluation_view: 'Peer Evaluation',
};

type TeacherTabContentProps = {
  creation: Creation;
  evaluationIndex: number;
  activity: Activity;
  reviewer: any;
};
function TeacherTabContent(props: TeacherTabContentProps) {
  const { creation, evaluationIndex, activity, reviewer } = props;
  const hasAiScores = creation.aiScores.length > 0;

  if (evaluationIndex === 0 && hasAiScores) {
    return <AiEvaluationTab aiScores={creation.aiScores} rubric={activity.rubric} />;
  }

  if (!reviewer) {
    return null;
  }

  const evaluation = EvaluationUtil.getEvaluationByStudentId(creation.scores, reviewer._id);

  const renderLabel = () => {
    if (ActivityStatusUtils.isFeedbackOrLater({ assignment: activity })) {
      return (
        <InfoLabel label={localize({ message: 'InstructorView.Label.Missed' })} type="danger" />
      );
    }

    return (
      <InfoLabel label={localize({ message: 'InstructorView.Label.Pending' })} type="pending" />
    );
  };

  if (!evaluation) {
    return (
      <StudentContent user={reviewer.user} score={StudentUtil.getNewKS(reviewer)}>
        <div className="evaluation-header">
          <h3 className="header-3">
            <TranslatedText
              i18nKey="InstructorView.EvaluationTitle"
              values={{ index: (evaluationIndex + 1).toString() }}
            />
          </h3>
          {renderLabel()}
        </div>
        <EvaluationPending rubric={activity.rubric} submission={creation} />
      </StudentContent>
    );
  }

  return (
    <div key={evaluation._id}>
      <EvaluationWithFeedback
        title={localize({
          message: 'InstructorView.PeerEvaluationTitle',
          options: { index: (evaluationIndex + 1).toString() },
        })}
        submission={creation}
        evaluation={evaluation}
        assignment={activity}
      />
    </div>
  );
}

type TeacherTabMenuProps = {
  setEvaluationIndex: (index: number) => void;
  evaluationIndex: number;
  reviewers: any[];
  evaluations: any[];
  activity: Activity;
  creation: Creation & { user: User } & { student: Student };
};
function TeacherTabMenu(props: TeacherTabMenuProps) {
  const { setEvaluationIndex, evaluationIndex, reviewers, evaluations, activity, creation } = props;

  const labels = [];
  const hasAiScores = creation.aiScores.length > 0;
  const numEvalTabsToRender = hasAiScores
    ? creation.reviewers.length + 1
    : creation.reviewers.length;

  type GetTabTitleArgs = {
    isSelfEval: boolean;
    index: number;
  };
  function getTabTitle({ isSelfEval, index }: GetTabTitleArgs) {
    if (isSelfEval) {
      return <TranslatedText i18nKey="Activity.WithinGroup.Evaluations.SelfEvaluation" />;
    }
    return localize({ message: 'InstructorView.EvaluationTab', options: { index } });
  }

  const getEvaluationTabStatus = (reviewer: any) => {
    if (!reviewer) {
      return tabMenuStatuses.CAUTION;
    }
    const evaluation = EvaluationUtil.getEvaluationByStudentId(evaluations, reviewer._id);
    return TabMenuUtils.getEvaluationTabStatus({
      evaluation,
      activity,
    });
  };

  for (let i = 0; i < numEvalTabsToRender; i++) {
    if (i === 0 && hasAiScores) {
      labels.push({
        id: i,
        title: localize({ message: 'AIEvaluationTab.Title' }),
        status: statuses.AIEVALUATION,
      });
      continue;
    }
    const reviewer = reviewers[i];
    const status = getEvaluationTabStatus(reviewer);
    const isSelfEval = reviewer?._id === creation.student?._id;
    labels.push({
      id: i,
      title: getTabTitle({ isSelfEval, index: hasAiScores ? i : i + 1 }),
      status,
    });
  }

  return <TabMenu labels={labels} onSelect={setEvaluationIndex} activeLabel={evaluationIndex} />;
}

type InstructorViewProps = {
  activity: Activity;
  creation: Creation & { user: User } & { student: Student };
};
const InstructorView = (props: InstructorViewProps) => {
  const [evaluationIndex, setEvaluationIndex] = useState(0);
  const { activity, creation } = props;

  const reviewers = creation.aiScores.length > 0 ? [{}, ...creation.reviewers] : creation.reviewers;

  const reviewer = reviewers[evaluationIndex];

  return (
    <React.Fragment>
      <TeacherTabMenu
        setEvaluationIndex={setEvaluationIndex}
        evaluationIndex={evaluationIndex}
        activity={activity}
        reviewers={reviewers}
        evaluations={creation.scores}
        creation={creation}
      />
      <TeacherTabContent
        evaluationIndex={evaluationIndex}
        creation={creation}
        activity={activity}
        reviewer={reviewer}
      />
    </React.Fragment>
  );
};

export default InstructorView;
