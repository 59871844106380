import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createEvaluationFlag } from 'actions/activity';
import userSelector from 'selectors/user';
import { EVAL_FLAGS } from '@kritik/utils/creation/status';
import * as evaluationUtil from '@kritik/utils/creation/evaluation';
import { Button } from 'components/buttons';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import { localize } from 'locales';

const Flagged = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const flagComment = () => {
    if (isSubmitting) {
    } else {
      setIsSubmitting(true);
      props
        .createEvaluationFlag({
          submissionId: props.creation._id,
          scoreId: props.evaluation._id,
          flag: EVAL_FLAGS.NO_FLAG,
        })
        .then(() => {
          setIsSubmitting(false);
        });
    }
  };

  const renderFlagMenu = () => {
    if (evaluationUtil.isUserEvaluationOwner(props.user, props.evaluation)) {
      return null;
    }
    return (
      <Button
        type="secondary"
        onClick={() => {
          return flagComment();
        }}
        testid="undo-report"
        disabled={EvaluationScoreUtil.isEvaluationRemoved(props.evaluation)}
      >
        <i className="far fa-flag" /> {localize({ message: 'Creation.Evaluation.View.UndoReport' })}
      </Button>
    );
  };

  return <React.Fragment>{renderFlagMenu()}</React.Fragment>;
};

const mapStateToProps = (state: any) => {
  return {
    user: userSelector.selectAuthUser(state),
  };
};

export default connect(mapStateToProps, {
  createEvaluationFlag,
})(Flagged);
