import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NoLmsCourseConnected from 'components/RosterManager/SyncRosterPopup/NoLmsCourseConnected';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { navigateToCourseDetailsEditPage } from 'actions/courses';
import { SYNC_ROSTER_MODAL_ID } from 'components/RosterManager/SyncRosterPopup/SyncRosterModal';
import { openGlobalModal } from 'actions/modals';
import { withRouter } from 'utils/withRouter';
import SyncRosterModal from 'components/RosterManager/SyncRosterPopup/SyncRosterModal';
import { exactDate } from '@kritik/utils/format';
import lmsServices from 'services/lms';
import { InlineInformation } from 'components/layout';
import AutoSyncToggle from 'components/RosterManager/SyncRosterPopup/AutoSyncToggle';
import { getCourseFirstSuccessfulSync } from 'services/course';
import Spinner from 'components/Loaders/Spinner';
import { useParams } from 'react-router';
import { useFetchCourse } from 'hooks/course';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const SyncRosterPopup = ({
  usingGradeSync,
  openGlobalModal,
  onRosterSync,
  navigateToCourseDetailsEditPage,
  user,
}: any) => {
  const { courseId } = useParams();
  const { data: course } = useFetchCourse(courseId);

  return (
    <>
      <SyncRosterModal course={course} onRosterSync={onRosterSync} />
      <div className="sync-roster-popup">
        {usingGradeSync ? (
          <SyncRosterContent
            course={course}
            openGlobalModal={openGlobalModal}
            navigateToCourseDetailsEditPage={navigateToCourseDetailsEditPage}
            user={user}
          />
        ) : (
          <NoLmsCourseConnected />
        )}
      </div>
    </>
  );
};

type SyncRosterContentProps = {
  course: any;
  openGlobalModal: ({}) => void;
  navigateToCourseDetailsEditPage: (courseId: string, query: string) => void;
  user: any;
};

const SyncRosterContent = ({
  course,
  user,
  openGlobalModal,
  navigateToCourseDetailsEditPage,
}: SyncRosterContentProps) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSyncStatus, setLastSyncStatus] = useState(null);
  const [lastSuccessfulSyncDate, setLastSuccessfulSyncDate] = useState(null);
  const [hasSuccessfulFirstSync, setHasSuccessfulFirstSync] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const { isInstructorInCourse } = useUserRoleInCourse();

  useEffect(() => {
    const getCourseLastSync = async () => {
      try {
        const response = await lmsServices().getCourseLastSync({
          courseId: course._id,
        });

        if (response.status === 200) {
          setLastSyncStatus(response.data);
          if (response.data?.status === 'success') {
            setLastSuccessfulSyncDate(response.data.createdAt);
          } else {
            setLastSuccessfulSyncDate(response.data?.lastSuccessfulSyncDate || null);
          }
        }
      } catch (ignore) {
        setLastSyncStatus(null);
      }
    };

    const getCourseCurrentSyncStatus = async () => {
      try {
        const response = await lmsServices().getCourseCurrentSyncStatus({ courseId: course._id });
        if (response.status === 200) {
          if (response.data.syncing) {
            setIsSyncing(true);
          } else {
            await getCourseLastSync();
          }
          setIsLoading(false);
        }
      } catch (ignore) {
        setLastSyncStatus(null);
        setIsLoading(false);
      }
    };

    const getCourseFirstSuccessSync = async () => {
      const data = await getCourseFirstSuccessfulSync({ course });
      setHasSuccessfulFirstSync(data?.hasSuccessfulSync);
    };

    void getCourseCurrentSyncStatus();
    void getCourseFirstSuccessSync();
  }, []);

  function navigateToCourseSettings() {
    navigateToCourseDetailsEditPage(course._id, '?scroll-to-lms=true');
  }

  function openSyncRosterModal() {
    openGlobalModal({ props: { id: SYNC_ROSTER_MODAL_ID } });
  }

  const connectedCourseName = course.lms?.connectedCourseName;

  if (!connectedCourseName) {
    return <NoLmsCourseConnected />;
  }

  return (
    <>
      <div className="sync-roster-popup__title">{connectedCourseName}</div>
      {loading ? null : (
        <>
          {lastSuccessfulSyncDate && (
            <LastSuccessfulSyncInformation lastSuccessfulSyncDate={lastSuccessfulSyncDate} />
          )}
          {isSyncing ? (
            <SyncingRoster />
          ) : (
            <SyncStatus
              lastSyncStatus={lastSyncStatus}
              lastSuccessfulSyncDate={lastSuccessfulSyncDate}
            />
          )}
        </>
      )}
      {hasSuccessfulFirstSync && <AutoSyncToggle />}

      <ButtonContainer>
        <Button
          type="primary"
          onClick={() => openSyncRosterModal()}
          chevron="right"
          unavailable={loading || isSyncing}
          disabled={loading || isSyncing}
          testid="sync-roster-next"
        >
          <TranslatedText i18nKey="RosterManager.SyncRosterPopup.SyncRosterButton" />
        </Button>
        {isInstructorInCourse && (
          <Button type="secondary" onClick={() => navigateToCourseSettings()}>
            <TranslatedText i18nKey="RosterManager.SyncRosterPopup.LmsSettings" />
          </Button>
        )}
      </ButtonContainer>
    </>
  );
};

const LastSuccessfulSyncInformation = ({ lastSuccessfulSyncDate }: any) => {
  return (
    <div className="last-successful-sync">
      <TranslatedText
        i18nKey="RosterManager.SyncRosterPopup.LastSuccessfulSync"
        values={{ date: exactDate(lastSuccessfulSyncDate) }}
      />
    </div>
  );
};

const SyncStatus = ({ lastSyncStatus, lastSuccessfulSyncDate }: any) => {
  switch (lastSyncStatus?.status) {
    case 'fail':
      return (
        <InlineInformation type="danger">
          <div className="inline-info__label">
            <TranslatedText i18nKey="RosterManager.SyncRosterPopup.SyncIncomplete" />
          </div>
          <TranslatedText i18nKey="RosterManager.SyncRosterPopup.SyncIncompleteMessage" />
        </InlineInformation>
      );
    case 'success':
      const addedStudentsCount = lastSyncStatus.studentsAdded?.length || 0;
      const addedCollaboratorsCount = lastSyncStatus.collaboratorsAdded?.length || 0;
      return (
        <InlineInformation type="information">
          <div className="inline-info__label">
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.RosterUpdated"
              values={{ date: exactDate(lastSuccessfulSyncDate) }}
            />
          </div>
          <div>
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.StudentsAdded"
              values={{ count: addedStudentsCount }}
            />
          </div>
          <div>
            <TranslatedText
              i18nKey="RosterManager.SyncRosterPopup.CollaboratorsAdded"
              values={{ count: addedCollaboratorsCount }}
            />
          </div>
          <br />
        </InlineInformation>
      );
    default:
      return (
        <div>
          <p>
            <TranslatedText i18nKey="RosterManager.SyncRosterPopup.NeverSynced" />
          </p>
          <p>
            <TranslatedText i18nKey="RosterManager.SyncRosterPopup.ClickNextToSync" />
          </p>
        </div>
      );
  }
};

const SyncingRoster = () => {
  return (
    <div className="roster-sync-spinner-container">
      <Spinner size="20px" className="roster-sync-spinner" />
      <TranslatedText i18nKey="RosterManager.SyncRosterPopup.SyncingInProgress" />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    modalStatus: state.modal,
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, { navigateToCourseDetailsEditPage, openGlobalModal })(SyncRosterPopup)
);
