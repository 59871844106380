import { groupSchema, assignedGroupSchema, studentSchema } from 'schemas';
import { denormalize } from 'normalizr';
import * as _ from 'lodash-es';
import studentSelector from 'selectors/student';

const extendSchema = (schema: any, extension: any) => {
  const extendedSchema = _.cloneDeep(schema);
  extendedSchema.define(extension);
  return extendedSchema;
};

export const getGroup = (groupId: any, entities: any, withMembers?: any) => {
  const { groups } = entities;

  if (_.isEmpty(groups)) {
    return null;
  }

  const schema = withMembers
    ? extendSchema(groupSchema, {
        members: [studentSchema],
      })
    : groupSchema;

  const group = denormalize(groupId, schema, entities);
  if (group?.members) {
    group.members = group.members.sort((a, b) => {
      if (typeof a === 'string') {
        return a;
      }
      return a.user.profile.name.localeCompare(b.user.profile.name, undefined, {
        sensitivity: 'base',
        ignorePunctuation: true,
      });
    });
  }
  return group;
};

export const getGroupList = (state: any, { withMembers }: any = {}) => {
  const { groups } = state.entities;

  if (_.isEmpty(groups)) {
    return [];
  }

  const schema = withMembers
    ? extendSchema(groupSchema, {
        members: [studentSchema],
      })
    : groupSchema;

  const groupIds = Object.keys(groups).filter((id) => {
    if (groups[id].course == state.selected.courseId) {
      return id;
    }
  });

  const groupList = denormalize(groupIds, [schema], state.entities);
  return groupList
    .sort((a, b) => {
      if (a.name !== b.name) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
          ignorePunctuation: true,
        });
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    })
    .map((group) => ({
      ...group,
      members: group.members.sort((a, b) => {
        if (typeof a === 'string') {
          return a;
        }
        return a.user.profile.name.localeCompare(b.user.profile.name, undefined, {
          sensitivity: 'base',
          ignorePunctuation: true,
        });
      }),
    }));
};
export const getGroupListPerAssignment = (state: any, assignmentId: any) => {
  const _assignmentId = assignmentId || state.selected.assignmentId;
  const { groups } = state.entities;

  if (_.isEmpty(groups)) {
    return [];
  }

  const groupIds = Object.keys(groups).filter((id) => {
    const _group = groups[id];
    if (_group.assignments && _group.assignments.includes(_assignmentId)) {
      return id;
    }
  });

  return denormalize(groupIds, [groupSchema], state.entities);
};

export const getGroupSet = (groupSetId: any, entities: any) => {
  const groupSets = entities.assignedGroups;

  if (_.isEmpty(groupSets)) {
    return null;
  }

  return denormalize(groupSetId, assignedGroupSchema, entities);
};

export const getGroupSetList = (state: any) => {
  const groupSets = state.entities.assignedGroups;

  if (_.isEmpty(groupSets)) {
    return [];
  }

  const groupSetIds = Object.keys(groupSets).filter((id) => {
    if (
      groupSets[id].course == state.selected.courseId &&
      !groupSets[id].isInstance &&
      !groupSets[id].deleted
    ) {
      return id;
    }
  });

  const list = denormalize(groupSetIds, [assignedGroupSchema], state.entities);

  return list.sort((a: any, b: any) => {
    return a.createdAt < b.createdAt ? 1 : -1;
  });
};

export const getAllGroupSetList = (state: any) => {
  const groupSets = state.entities.assignedGroups;

  if (_.isEmpty(groupSets)) {
    return [];
  }

  const groupSetIds = Object.keys(groupSets).filter((id) => {
    if (groupSets[id].course == state.selected.courseId && !groupSets[id].deleted) {
      return id;
    }
  });

  const list = denormalize(groupSetIds, [assignedGroupSchema], state.entities);

  return list.sort((a: any, b: any) => {
    return a.createdAt < b.createdAt ? 1 : -1;
  });
};

export const getGroupOfStudent = (state: any, activityId: any, studentId?: any) => {
  let _studentId = studentId;
  if (!_studentId) {
    const student = studentSelector.getStudentFromUser(state, state.selected.courseId);
    if (!student) {
      return null;
    }
    _studentId = student._id;
  }

  const { groups } = state.entities;
  const groupId = Object.values(groups).find((group) => {
    if (
      (group as any).members.includes(_studentId) &&
      (group as any).assignments.includes(activityId)
    ) {
      return group;
    }
  });
  return getGroup(groupId, state.entities);
};

export default {
  getGroup,
  getGroupList,
  getGroupListPerAssignment,
  getGroupOfStudent,
};
