import { connect } from 'react-redux';
import { FILTERS } from 'components/CreationList/constant';
import { setCreationTableFilter } from 'redux/creationTable/actions';
import { selectCreationTableFilter, selectCreationTableViewType } from 'redux/creationTable/select';
import Selection from 'components/CreationList/Menu/Select';
import { isCalibrationActivity } from '@kritik/utils/activity';
import { selectCurrentActivity } from 'selectors/activity';
import { navigateToActivityPage } from 'actions/activity';
import * as StatusUtils from '@kritik/utils/stage';
import { TranslatedText } from 'components/TranslatedText';

const options = {
  SHOW_ALL: {
    label: <TranslatedText i18nKey="Filter.Options.ShowAll" />,
    value: FILTERS.SHOW_ALL,
    testid: 'show-all',
  },
  MISSED_CREATIONS: {
    label: <TranslatedText i18nKey="Filter.Options.MissedCreation" />,
    value: FILTERS.MISSED_CREATIONS,
    testid: 'filter-by-missed-creation',
  },
  LATE_CREATIONS: {
    label: <TranslatedText i18nKey="Filter.Options.LateCreation" />,
    value: FILTERS.LATE_CREATIONS,
    testid: 'filter-by-late-creation',
  },
  OVERDUE_CREATIONS: {
    label: <TranslatedText i18nKey="Filter.Options.OverdueCreation" />,
    value: FILTERS.OVERDUE_CREATIONS,
    testid: 'filter-by-overdue-creation',
  },
  DISPUTES: {
    label: <TranslatedText i18nKey="Filter.Options.Disputes" />,
    value: FILTERS.DISPUTES,
    testid: 'filter-by-disputes',
  },
  FLAGS: {
    label: <TranslatedText i18nKey="Filter.Options.Flags" />,
    value: FILTERS.FLAGS,
    testid: 'filter-by-flags',
  },
};

function getOptions(activity: any) {
  const _options = { ...options };
  if (isCalibrationActivity(activity)) {
    delete _options.MISSED_CREATIONS;
    delete _options.LATE_CREATIONS;
  } else if (!StatusUtils.isEvaluateOrLater(activity)) {
    delete options.LATE_CREATIONS;
  }
  return _options;
}

const getValue = (filter: any) => {
  const selectedOption = options[filter];
  return selectedOption ? selectedOption.value : selectedOption;
};

function Filter(props: any) {
  const { filter, label, activity, disabled, id } = props;

  return (
    <Selection
      label={label}
      isLoading={false}
      value={getValue(filter)}
      onChange={props.onFilterChange}
      options={Object.values(getOptions(activity))}
      disabled={disabled}
      testid={props.testid}
    />
  );
}

const mapStateToProps = (state: any) => {
  return {
    filter: selectCreationTableFilter(state),
    activity: selectCurrentActivity(state),
    viewType: selectCreationTableViewType(state),
    courseId: state.selected.courseId,
    assignmentId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setCreationTableFilter,
  navigateToActivityPage,
})(Filter);
