import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Button } from 'components/buttons';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';
import { useNavigate, useParams } from 'react-router';
import { useResolveCreationSimilarityReport } from 'hooks/plagiarism';
import { useRevalidator } from 'react-router';
export function ResolveCreationSimilarityReport() {
  const [note, setNote] = useState('');
  const resolveCreationSimilarityReport = useResolveCreationSimilarityReport();
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const { creationId, assignmentId, courseId } = useParams();
  return (
    <Dialog open={true} data-testid="resolve-similarity-dialog">
      <DialogTitle>
        <TranslatedText i18nKey="CreationSimilarityReport.ConfirmationDialog.Title" />
      </DialogTitle>
      <DialogContent>
        <TranslatedText i18nKey="CreationSimilarityReport.ConfirmationDialog.Content" />
      </DialogContent>
      <DialogContent>
        <TranslatedText i18nKey="CreationSimilarityReport.ConfirmationDialog.InternalNotes" />
        <textarea
          style={{
            width: '100%',
            height: '100px',
            padding: '5px',
            marginTop: '5px',
            marginBottom: '5px',
          }}
          placeholder={localize({
            message: 'CreationSimilarityReport.ConfirmationDialog.InternalNotes.Placeholder',
          })}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          data-testid="similarity-internal-notes-input"
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="secondary"
          onClick={() =>
            navigate(
              `/course/${courseId}/assignment/${assignmentId}/reports/similarity/${creationId}`
            )
          }
        >
          <TranslatedText i18nKey="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => {
            resolveCreationSimilarityReport.mutate(
              { creationId, note },
              {
                onSuccess: () => {
                  navigate(
                    `/course/${courseId}/assignment/${assignmentId}/reports/similarity/${creationId}`
                  );
                  revalidator.revalidate();
                },
              }
            );
          }}
          testid="confirm-resolve-similarity-button"
        >
          <TranslatedText i18nKey="CreationSimilarityReport.Actions.Resolve" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
