import NoCourseContainer from 'containers/NoCourse';
import Page from 'pages/Page';
import { localize } from 'locales';

function NoCourse() {
  return (
    <Page title={localize({ message: 'NoCourse' })} meta={[]} link={[]} script={[]}>
      <NoCourseContainer />
    </Page>
  );
}

export default NoCourse;
