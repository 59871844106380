import { useState } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';
import { Popper } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getCourse } from 'selectors/course';
import { Button, ButtonContainer } from 'components/buttons';
import Radio from 'components/core/form/RadioField';
import { MAX_WEIGHT, MIN_WEIGHT } from '@kritik/constants/activity';
import FormNumberInput from 'components/core/form/NumberField';
import { updateActivityWeight } from 'actions/activity';
import * as courseUtil from '@kritik/utils/course';
import * as activityUtil from '@kritik/utils/activity';
import InlineInformation from 'components/layout/InlineInformation';
import { RADIO_VALUES, CALIBRATION_OPTIONS } from './constants';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const ActivityWeightMenu = (props: any) => {
  const isCalibrationActivity = () => {
    return activityUtil.isCalibrationActivity(props.activity);
  };

  const getActivityWeight = () => {
    return props.activity.weight !== null ? props.activity.weight : getDefaultWeight();
  };

  const getDefaultWeight = () => {
    return courseUtil.getDefaultActivityWeight(props.course);
  };

  const getDefaultRadio = () => {
    if (isCalibrationActivity() || props.activity.weight === getDefaultWeight()) {
      return RADIO_VALUES.DEFAULT;
    }
    return RADIO_VALUES.CUSTOM;
  };

  const [radioOption, setRadioOption] = useState(getDefaultRadio());
  const [weight, setWeight] = useState(getActivityWeight());

  const renderInfoBanner = () => {
    if (isCalibrationActivity()) {
      return (
        <InlineInformation type="information">
          <TranslatedText i18nKey="ActivityWeightMenu.CalibrationActivitiesInfo" />
        </InlineInformation>
      );
    }
    const defaultWeight = getDefaultWeight();
    return (
      <div className="weight-edit-popup__info-description">
        <TranslatedText
          i18nKey="ActivityWeightMenu.DefaultWeightInfo"
          values={{ defaultWeight: defaultWeight.toString(), s: defaultWeight === 1 ? '' : 's' }}
        />{' '}
        <a
          role="link"
          tabIndex={0}
          onClick={() => window.open(`/course/${props.course._id}`, '_blank')}
          className="underlined-link"
        >
          <TranslatedText i18nKey="Activity.Edit.CourseSettings" />
        </a>
        .
      </div>
    );
  };

  const getDescription = () => {
    return (
      <div className="weight-edit-popup__info">
        <div className="weight-edit-popup__info-title">
          <TranslatedText i18nKey="ActivityWeightMenu.SetGradingWeight" />
        </div>
        {renderInfoBanner()}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonContainer>
        <Button
          type="primary"
          onClick={() => {
            return onSubmit();
          }}
          disabled={isCalibrationActivity()}
          unavailable={isCalibrationActivity()}
          label={localize({ message: 'Button.Label.SaveWeight' })}
        >
          <TranslatedText i18nKey="Save" />
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            return onCancel();
          }}
        >
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </ButtonContainer>
    );
  };

  const changeRadioSelection = (e: any) => {
    const radioValue = e.target.value;
    setRadioOption(radioValue);
    if (radioValue === RADIO_VALUES.DEFAULT) {
      setWeight(getDefaultWeight());
    } else {
      setWeight(getActivityWeight());
    }
  };

  const getRadioOptions = () => {
    if (isCalibrationActivity()) {
      return CALIBRATION_OPTIONS;
    }
    return [
      {
        value: RADIO_VALUES.DEFAULT,
        label: localize({
          message: 'Activity.Weight.Default',
          options: { count: getDefaultWeight(), weight: getDefaultWeight() },
        }),
      },
      { value: RADIO_VALUES.CUSTOM, label: localize({ message: 'Activity.Weight.Custom' }) },
    ];
  };

  const renderRadioButtons = () => {
    return (
      <div className="weight-edit-popup__radio-btns">
        <Radio
          name="Grading-weight-editor"
          value={radioOption}
          onChange={(e: any) => {
            return changeRadioSelection(e);
          }}
          options={getRadioOptions()}
        />
        {renderWeightInput()}
      </div>
    );
  };

  const handleWeightChange = (e: any) => {
    let weight = e.target.value ? e.target.value : 0;
    weight = Math.max(weight, MIN_WEIGHT);
    weight = Math.min(weight, MAX_WEIGHT);
    weight = _.round(weight, 2);
    setWeight(weight);
  };

  const renderWeightInput = () => {
    if (radioOption !== RADIO_VALUES.CUSTOM) {
      return null;
    }
    return (
      <div className="weight-edit-popup__input">
        <FormNumberInput
          name="weight"
          value={weight}
          onChange={(e: any) => {
            return handleWeightChange(e);
          }}
          max={MAX_WEIGHT}
          min={MIN_WEIGHT}
        />
        <span className="weight-edit-popup__input-label">
          <TranslatedText i18nKey="Activity.Weight.Points" values={{ count: weight }} />
        </span>
      </div>
    );
  };

  const onSubmit = () => {
    props.updateActivityWeight({ activityId: props.activity._id, weight }).then(() => {
      onCancel();
    });
  };

  const onCancel = () => {
    setTimeout(() => {
      props.onCancel();
    });
  };

  return (
    <Popper
      className="weight-edit-popup"
      open={props.isOpen || false}
      placement="bottom-start"
      anchorEl={props.anchorEl}
      disablePortal={true}
      modifiers={{
        offset: {
          enabled: true,
          offset: '0, 10',
        },
      }}
      transition
    >
      <ClickAwayListener
        onClickAway={props.onCancel}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div>
          {getDescription()}
          {renderRadioButtons()}
          {renderButtons()}
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
  };
};

ActivityWeightMenu.defaultProps = {
  isOpen: false,
};

export default connect(mapStateToProps, {
  updateActivityWeight,
})(ActivityWeightMenu);
