import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import NoticeBoard from 'components/layout/NoticeBoard';
import { DisplayBox } from 'components/layout';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import * as ActivityUtil from '@kritik/utils/activity';
import InlineInformation from 'components/layout/InlineInformation';
import { getSubmissions } from 'selectors/creation';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const FeedbackSummary = ({ assignment, submission, student, creations }: any) => {
  const isGroupAssignment =
    ActivityUtil.isGroupAssignment(assignment) ||
    ActivityUtil.isGroupPresentationActivity(assignment);

  const getFeedbackScore = () => {
    if (isGroupAssignment || ActivityUtil.isIndividualPresentationActivity(assignment)) {
      return FeedbackScoreUtil.calcScore({
        creation: submission,
        activity: assignment,
        studentId: student._id,
        creations,
      });
    }
    return FeedbackScoreUtil.calcScore({
      creation: submission,
      activity: assignment,
      studentId: submission.student._id,
    });
  };

  const renderInlineScoreNotice = () => {
    if (isGroupAssignment) {
      return (
        <InlineInformation>
          <TranslatedText i18nKey="FeedbackSummary.GroupAssignment.ScoreNotice" />
        </InlineInformation>
      );
    }
    return null;
  };
  return (
    <DisplayBox>
      <ScoreItem
        title={localize({ message: 'FeedbackSummary.ScoreItem.Title' })}
        description={localize({ message: 'FeedbackSummary.ScoreItem.Description' })}
        value={getFeedbackScore()}
        size="lg"
      />
      {FeedbackScoreUtil.isFeedbackScoredByTeacher(submission) && (
        <NoticeBoard
          type="success"
          title={localize({ message: 'FeedbackSummary.TeacherScored.Title' })}
        >
          <TranslatedText i18nKey="FeedbackSummary.TeacherScored.Description" />
        </NoticeBoard>
      )}
      {renderInlineScoreNotice()}
    </DisplayBox>
  );
};

const mapStateToProps = (state: any) => {
  return {
    creations: getSubmissions(state, state.selected.assignmentId),
  };
};

export default withRouter(connect(mapStateToProps)(FeedbackSummary));
