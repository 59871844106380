import { GradingScoreUtil } from '@kritik/utils/grade';
import ScoreDecorator from '../ScoreDecorator';
import InfoPopup from 'components/core/InfoPopup';
import { localize } from 'locales';
import { getCriterion } from 'components/General/StarRubricMarks';
import { calcWeightedScoreFromMark } from '@kritik/utils/rubric';

type GetPopoverScoreInfoArgs = {
  mark: number;
  criterionIndex: number;
  rubric: any;
};

const getPopoverScoreInfo = ({ mark, criterionIndex, rubric }: GetPopoverScoreInfoArgs) => {
  const criterion = getCriterion({ criterionIndex, criteria: rubric.criteria });
  if (mark > 0) {
    return {
      score: calcWeightedScoreFromMark(mark, criterionIndex, rubric).toFixed(2),
      weight: criterion.weight,
    };
  }
  return null;
};

function ScoreChangePerCriteria({
  scoreDiff,
  maxScoreChangePerCriteria,
  criterionIndex,
  mark,
  rubric,
  comparisonMarks,
  isInstructor,
}: any) {
  const comparisonMark = comparisonMarks[criterionIndex];

  const score = getPopoverScoreInfo({ mark, criterionIndex, rubric })?.score || 0;
  const compareScore =
    getPopoverScoreInfo({ mark: comparisonMark, criterionIndex, rubric })?.score || 0;
  const totalLevels =
    getPopoverScoreInfo({ mark: comparisonMark, criterionIndex, rubric })?.weight || 0;

  const scoreDiffInPercentage = Math.max(
    0,
    GradingScoreUtil.calcScorePerCriterion(scoreDiff, maxScoreChangePerCriteria)
  );
  const displayScoreDiff = `${scoreDiffInPercentage.toFixed(0)}%`;

  const getPopoverDescription = () => {
    return (
      <>
        <p>
          <i className="fa fa-user-graduate student-score-icon" />
          {isInstructor
            ? localize({ message: 'StarRubricMark.Icon.Label.ScoreStudentGave' })
            : localize({ message: 'StarRubricMark.Icon.Label.ScoreYouGave' })}
          <span className="student-score-number">{`${score}/${totalLevels}`}</span>
        </p>
        <p>
          <i className="fa fa-clipboard-check final-score-icon" />
          {localize({ message: 'StarRubricMark.Icon.Label.CreationFinalScore' })}
          <span className="final-score-number">{`${compareScore}/${totalLevels}`}</span>
        </p>
        <p>{localize({ message: 'StarRubricMark.Popover.Label.GradingScore1' })}</p>
        <ul>
          <li>{localize({ message: 'StarRubricMark.Popover.Label.GradingScore.ListItem1' })}</li>
          <li>{localize({ message: 'StarRubricMark.Popover.Label.GradingScore.ListItem2' })}</li>
          <li>{localize({ message: 'StarRubricMark.Popover.Label.GradingScore.ListItem3' })}</li>
        </ul>
        <p>
          {localize({ message: 'StarRubricMark.Popover.Label.GradingScore2' })}{' '}
          <a href={localize({ message: 'StarRubricMark.Popover.Label.GradingScore.Link' })}>
            {localize({ message: 'StarRubricMark.Popover.Label.GradingScore.LinkText' })}
          </a>
        </p>
      </>
    );
  };

  return (
    <InfoPopup
      description={getPopoverDescription()}
      buttonContent={<ScoreDecorator score={scoreDiff}>{displayScoreDiff}</ScoreDecorator>}
      popoverContentClass="info-popup__width-lg"
    />
  );
}

ScoreChangePerCriteria.defaultProps = {
  isEvaluatorScoreEnabled: true,
};

export default ScoreChangePerCriteria;
