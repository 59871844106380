import CRITICALBG from 'images/feedback/pattern-critical.png';
import CRITICALBADGE from 'images/feedback/badge-critical.svg';
import MOTIVATIONALBG from 'images/feedback/pattern-motivational.png';
import MOTIVATIONALBADGE from 'images/feedback/badge-motivational.svg';
import { localize } from 'locales';

export const TEMPLATES = {
  critical: {
    title: localize({ message: 'Feedback.Constants.Critical.Title' }),
    description: localize({ message: 'Feedback.Constants.Critical.Description' }),
    background: CRITICALBG,
    badge: CRITICALBADGE,
    marks: [
      {
        value: 1,
        label: localize({ message: 'Feedback.Constants.Critical.Mark1' }),
      },
      {
        value: 2,
        label: localize({ message: 'Feedback.Constants.Critical.Mark2' }),
      },
      {
        value: 3,
        label: localize({ message: 'Feedback.Constants.Critical.Mark3' }),
      },
      {
        value: 4,
        label: localize({ message: 'Feedback.Constants.Critical.Mark4' }),
      },
    ],
  },
  motivational: {
    title: localize({ message: 'Feedback.Constants.Motivational.Title' }),
    description: localize({ message: 'Feedback.Constants.Motivational.Description' }),
    background: MOTIVATIONALBG,
    badge: MOTIVATIONALBADGE,
    marks: [
      {
        value: 1,
        label: localize({ message: 'Feedback.Constants.Motivational.Mark1' }),
      },
      {
        value: 2,
        label: localize({ message: 'Feedback.Constants.Motivational.Mark2' }),
      },
      {
        value: 3,
        label: localize({ message: 'Feedback.Constants.Motivational.Mark3' }),
      },
      {
        value: 4,
        label: localize({ message: 'Feedback.Constants.Motivational.Mark4' }),
      },
    ],
  },
};

export const SUBMITTING_DESCRIPTIONS = {
  critical: [
    localize({ message: 'Feedback.Constants.Submitting.Critical.Description1' }),
    localize({ message: 'Feedback.Constants.Submitting.Critical.Description2' }),
    localize({ message: 'Feedback.Constants.Submitting.Critical.Description3' }),
    localize({ message: 'Feedback.Constants.Submitting.Critical.Description4' }),
  ],
  motivational: [
    localize({ message: 'Feedback.Constants.Submitting.Motivational.Description1' }),
    localize({ message: 'Feedback.Constants.Submitting.Motivational.Description2' }),
    localize({ message: 'Feedback.Constants.Submitting.Motivational.Description3' }),
    localize({ message: 'Feedback.Constants.Submitting.Motivational.Description4' }),
  ],
};

export const VIEWING_DESCRIPTIONS = {
  critical: [
    localize({ message: 'Feedback.Constants.Viewing.Critical.Description1' }),
    localize({ message: 'Feedback.Constants.Viewing.Critical.Description2' }),
    localize({ message: 'Feedback.Constants.Viewing.Critical.Description3' }),
    localize({ message: 'Feedback.Constants.Viewing.Critical.Description4' }),
  ],
  motivational: [
    localize({ message: 'Feedback.Constants.Viewing.Motivational.Description1' }),
    localize({ message: 'Feedback.Constants.Viewing.Motivational.Description2' }),
    localize({ message: 'Feedback.Constants.Viewing.Motivational.Description3' }),
    localize({ message: 'Feedback.Constants.Viewing.Motivational.Description4' }),
  ],
};

export const TYPES = {
  CRITICAL: 'critical',
  MOTIVATIONAL: 'motivational',
};

export default {
  TEMPLATES,
  TYPES,
  SUBMITTING_DESCRIPTIONS,
  VIEWING_DESCRIPTIONS,
};
