import { useState, useEffect } from 'react';
import ActivityTable from 'components/LmsGradeSync/LmsTable/ActivityTable';
import OverallTable from 'components/LmsGradeSync/LmsTable/OverallTable';
import { getAssignments } from 'actions/activity';
import { connect } from 'react-redux';
import courseSelector from 'selectors/course';
import TableUtils from 'components/LmsGradeSync/LmsTable/ActivityTable/utils';
import * as ActivityStatusUtils from '@kritik/utils/stage';
import * as ActivityUtils from '@kritik/utils/activity';
import { getEdlinkActivities } from 'actions/edlink';
import Spinner from 'components/Loaders/Spinner';
import InlineInformation from 'components/layout/InlineInformation';
import { getCourse } from 'actions/courses';
import { useParams } from 'react-router';
import { useGetCachedCourse } from 'hooks/course';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const LmsTable = (props: any) => {
  const { courseId } = useParams();
  const course = useGetCachedCourse(courseId);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);

  const getInitialSyncStatus = (lmsGradeSyncInformation: any) => {
    let status = 'synced';
    if (lmsGradeSyncInformation.lastSyncAt && lmsGradeSyncInformation.syncStatus === 'error') {
      status = 'error';
    }
    if (!lmsGradeSyncInformation.lastSyncAt) {
      status = 'not-synced';
    }
    return status;
  };

  const getCompletedActivitiesList = (activitiesList: any) => {
    const finalizedActivities = activitiesList
      .filter((activity: any) => {
        return (
          ActivityStatusUtils.isFinalized({ assignment: activity }) &&
          !ActivityUtils.isCalibrationActivity(activity)
        );
      })
      .map((activity: any) => {
        const lmsGradeSyncInformation = activity.lmsGradeSyncInformation || {};
        const status = getInitialSyncStatus(lmsGradeSyncInformation);
        return {
          title: activity.title,
          _id: activity._id,
          lmsGradeSyncInformation,
          status,
        };
      });
    return finalizedActivities;
  };

  const getEdlinkCourseActivities = () => {
    if (props.course?.lms?.connectedCourseId) {
      return props
        .getEdlinkActivities(props.course.lms.connectedCourseId)
        .then((lmsColumns: any) => {
          return lmsColumns;
        });
    }
  };

  const getActivities = () => {
    return props.getAssignments({ courseId: props.course._id }).then((_activities: any) => {
      return _activities;
    });
  };
  async function getAndSetData() {
    try {
      setIsLoading(true);
      const [_activities, _lmsColumns] = await Promise.all([
        getActivities(),
        getEdlinkCourseActivities(),
      ]);
      const completedActivities = getCompletedActivitiesList(_activities);
      const updatedActivities = completedActivities.map((activity: any) => {
        const _activity = {
          ...activity,
          id: activity._id,
        };
        const column = TableUtils.getSelectedLineItem(
          _activity.lmsGradeSyncInformation,
          _lmsColumns
        );
        if (!column) {
          _activity.column = {
            id: activity._id,
            label: activity.title,
            isNewColumn: true,
            scoreMaximum: course.markingScheme.isPercentage ? 100 : 1,
          };
          _activity.lmsGradeSyncInformation = {};
        } else {
          _activity.column = column;
          _activity.column.isNewColumn = false;
        }
        return _activity;
      });
      props.setLmsColumns(_lmsColumns);
      props.setActivities(updatedActivities);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(true);
      console.log(err);
    }
  }

  useEffect(() => {
    if (props.isNewIntegration) {
      void getAndSetData();
    }
  }, []);

  useEffect(() => {
    if (props.isNewIntegration) {
      if (props.onRefresh) {
        void getAndSetData();
        props.getCourse({ id: props.course._id });
      }
    }
  }, [props.onRefresh]);

  if (isLoading) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <InlineInformation type="danger" title="" information>
        <TranslatedText i18nKey="LmsTable.ErrorMessage" />
      </InlineInformation>
    );
  }

  const renderContent = () => {
    if (props.isNewIntegration && props.gradeSyncType === 'activity') {
      return (
        <ActivityTable
          activities={props.activities}
          lmsColumns={props.lmsColumns}
          onChangeLmsColumns={props.setLmsColumns}
          onChangeActivities={props.setActivities}
        />
      );
    }
    return (
      <OverallTable
        setSelectedColumn={props.setSelectedColumn}
        selectedColumn={props.selectedColumn}
        lmsColumns={props.lmsColumns}
        isNewIntegration={props.isNewIntegration}
      />
    );
  };
  return <div className="lms-table">{renderContent()}</div>;
};

const mapStateToProps = (state: any) => {
  return {
    course: courseSelector.getCourse(state),
  };
};

export default connect(mapStateToProps, { getAssignments, getEdlinkActivities, getCourse })(
  LmsTable
);
