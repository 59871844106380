import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import { addGroup, selectCurrentGroupSet } from 'redux/groupManager';
import { TranslatedText } from 'components/TranslatedText';

function AddGroupBtn({ groupSet, addGroup, disabled }: any) {
  const handleClickAddGroup = () => {
    addGroup(groupSet._id);
  };

  return (
    <Button
      onClick={handleClickAddGroup}
      disabled={disabled}
      type={disabled ? '' : 'secondary'}
      testid="add-group"
    >
      <TranslatedText i18nKey="GroupManager.AddGroupBtn.AddGroup" />
    </Button>
  );
}

const mapStateToProps = (state: any) => {
  return {
    groupSet: selectCurrentGroupSet(state),
  };
};

export default connect(mapStateToProps, {
  addGroup,
})(AddGroupBtn);
