import Page from 'pages/Page';
import PresentationScheduleContainer from 'containers/PresentationSchedule';
import { localize } from 'locales';

function PresentationSchedule() {
  return (
    <Page
      title={localize({
        message: 'PresentationSchedule.PageTitle',
      })}
      meta={[]}
      link={[]}
      script={[]}
    >
      <PresentationScheduleContainer />
    </Page>
  );
}

export default PresentationSchedule;
