import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { Field, FormSpy } from 'react-final-form';
import RadioInput from 'components/core/input/Radio';
import FormTitle from 'components/core/form/Title';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormFieldInput from 'components/core/form/FieldInput';
import FormFieldInfo from 'components/core/form/FieldInfo';
import NumberInput from 'components/core/input/Number';
import * as courseUtils from '@kritik/utils/course';
import * as ActivityUtils from '@kritik/utils/activity';
import { ACTIVITY_TYPES, MAX_WEIGHT, MIN_WEIGHT } from '@kritik/constants/activity';
import InlineInformation from 'components/layout/InlineInformation';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import { localize } from 'locales';
import { FIELD_NAME, FIELD_LABEL, DEFAULT_OPTIONS, CALIBRATION_OPTIONS } from './constants';
import { TranslatedText } from 'components/TranslatedText';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const ActivityWeight = (props: any) => {
  const [weightOption, setWeightOption] = useState(0);

  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setWeightOption(getDefaultWeightOption());
  }, [props.values.activityType]);

  const getDefaultWeightOption = () => {
    let selectedOption = DEFAULT_OPTIONS.DEFAULT.value;
    if (props.values.activityType === ACTIVITY_TYPES.WITHIN_GROUP) {
      selectedOption = DEFAULT_OPTIONS.CUSTOM.value;
    }
    if (
      !props.activity ||
      !props.course ||
      props.values.activityType === ACTIVITY_TYPES.CALIBRATION
    ) {
      return selectedOption;
    }
    const courseWeight = getCourseWeight();
    const activityWeight = ActivityUtils.getActivityWeight(props.activity);
    if (activityWeight !== courseWeight) {
      selectedOption = DEFAULT_OPTIONS.CUSTOM.value;
    }
    return selectedOption;
  };

  const getDefaultWeight = () => {
    const activityWeight = ActivityUtils.getActivityWeight(props.activity);
    const courseWeight = getCourseWeight();
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (weightOption === DEFAULT_OPTIONS.CUSTOM.value) {
      return courseWeight;
    }
    if (!props.activity) {
      return courseWeight;
    }
    return activityWeight || courseWeight;
  };

  const getCourseWeight = () => {
    return courseUtils.getDefaultActivityWeight(props.course);
  };

  const renderDescription = () => {
    if (ActivityUtils.isCalibrationActivity(props.values)) {
      return (
        <FormFieldInfo>
          <TranslatedText i18nKey="Activity.WeightField.Calibration.DefaultWeight" />
        </FormFieldInfo>
      );
    }
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    if (weightOption === DEFAULT_OPTIONS.DEFAULT.value) {
      if (props.course) {
        const courseWeight = getCourseWeight();
        return (
          <FormFieldInfo>
            <TranslatedText i18nKey="Activity.WeightField.DefaultWeight" />
            <b>
              {courseWeight}{' '}
              <TranslatedText i18nKey="Activity.Weight.Points" values={{ count: courseWeight }} />
            </b>
            . <TranslatedText i18nKey="Activity.WeightField.DefaultWeight.HowToChange" />
            <a
              target="_blank"
              href={`/course/${props.course._id}/score/edit`}
              className="underlined-link"
            >
              <TranslatedText i18nKey="Activity.Edit.CourseSettings" />
            </a>
            .
          </FormFieldInfo>
        );
      }
      return null;
    }
    return (
      <React.Fragment>
        <FormFieldInfo label={localize({ message: 'Activity.WeightField.Info' })} />
        <FormFieldInput>
          <Field
            name={LABEL.value}
            validate={validators.composeValidators(
              validators.isRequired,
              validators.minValue(0),
              validators.maxValue(9999)
            )}
          >
            {({ input }) => {
              return (
                <NumberInput
                  name="activityWeight"
                  value={input.value}
                  onChange={(e: any) => {
                    return changeWeight(e, input.onChange);
                  }}
                  min={MIN_WEIGHT}
                  max={MAX_WEIGHT}
                  testid="activity-weight-input"
                />
              );
            }}
          </Field>
        </FormFieldInput>
      </React.Fragment>
    );
  };

  const setWeightInput = (e: any, onChange: any) => {
    setWeightOption(e.target.value);
    onChange(getDefaultWeight());
  };

  const sanitizeWeightChange = (e: any) => {
    let weight = e.target.value ? e.target.value : 0;
    weight = Math.max(weight, MIN_WEIGHT);
    weight = Math.min(weight, MAX_WEIGHT);
    weight = _.round(weight, 2);
    return weight;
  };

  const getOptions = () => {
    if (ActivityUtils.isCalibrationActivity(props.values)) {
      return Object.values(CALIBRATION_OPTIONS);
    }
    return Object.values(DEFAULT_OPTIONS);
  };

  const renderOptions = () => {
    return (
      <Field name={LABEL.value} validate={validators.isRequired}>
        {({ input }) => {
          return (
            <RadioInput
              onChange={(e: any) => {
                return setWeightInput(e, input.onChange);
              }}
              options={getOptions()}
              value={weightOption}
              type="button"
              legendText="Legend.Text.GradingWeight"
            />
          );
        }}
      </Field>
    );
  };

  const changeWeight = (e: any, onChange: any) => {
    const _weight = sanitizeWeightChange(e);
    onChange(_weight);
  };

  const renderBanner = (weight: any) => {
    let banner = null;
    if (ActivityUtils.isCalibrationActivity(props.values)) {
      banner = (
        <InlineInformation type="information">
          {localize({ message: 'Activity.WeightField.Calibration.Banner' })}
        </InlineInformation>
      );
    } else if (weight === MIN_WEIGHT) {
      banner = (
        <InlineInformation type="information">
          {localize({ message: 'Activity.WeightField.Banner' })}
        </InlineInformation>
      );
    }
    if (banner) {
      return <div className="activity-edit-weight__information">{banner}</div>;
    }
    return null;
  };

  return (
    <AdvancedOptionField>
      <FormTitle label={LABEL.label} />
      {renderOptions()}
      {renderDescription()}
      <Field name={LABEL.value}>
        {({ input }) => {
          return renderBanner(input.value);
        }}
      </Field>
    </AdvancedOptionField>
  );
};

const Spy = (props: any) => {
  // @ts-expect-error TS(2322) FIXME: Type '{ component: (props: any) => Element; activi... Remove this comment to see the full error message
  return <FormSpy component={ActivityWeight} activity={props.activity} course={props.course} />;
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    course: getCourse(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, {})(Spy);
