import React from 'react';
import StatusLabel from 'components/Creation/StatusLabel';
import { isCalibrationActivity } from '@kritik/utils/activity';
import { CreationScoreUtil, EvaluationScoreUtil, FeedbackScoreUtil } from '@kritik/utils/grade';
import { localize } from 'locales';

const ICON_STYLES = {
  width: 'inherit',
};

export const InstructorGradedLabel = (props: any) => {
  if (isCalibrationActivity(props.activity)) {
    return null;
  }
  if (
    CreationScoreUtil.isInstructorGraded(props.creation) ||
    CreationScoreUtil.isProfRevised(props.creation) ||
    EvaluationScoreUtil.isEvaluationScoredByTeacher(props.creation) ||
    FeedbackScoreUtil.isFeedbackScoredByTeacher(props.creation)
  ) {
    return (
      <StatusLabel
        status="resolved"
        label={localize({ message: 'Creation.StatusLabel.InstructorGraded.Label' })}
        icon={<i className="fa fa-book" style={ICON_STYLES} />}
      />
    );
  }
  return null;
};

export default InstructorGradedLabel;
