import React from 'react';
import StatusLabel from 'components/Creation/StatusLabel';
import { isCalibrationActivity } from '@kritik/utils/activity';
import * as LateSubmissionUtils from '@kritik/utils/creation/lateCreation';
import * as creationStatusUtil from '@kritik/utils/creation/status';
import { localize } from 'locales';

const ICON_STYLES = {
  width: 'inherit',
};

export const NotGradedLabel = (props: any) => {
  if (isCalibrationActivity(props.activity)) {
    return null;
  }
  if (
    !LateSubmissionUtils.isLateSubmissionRejected(props.creation) &&
    creationStatusUtil.isCreationNotGradedAfterEvaluation(props.creation, props.activity)
  ) {
    return (
      <StatusLabel
        status="danger"
        label={localize({ message: 'Creation.StatusLabel.NotGraded' })}
        icon={<i className="fa fa-book" style={ICON_STYLES} />}
      />
    );
  }
  return null;
};

export default NotGradedLabel;
