import { useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Grid from '@material-ui/core/Grid';
import InfoPopup from 'components/core/InfoPopup';
import PropagationInterceptor from 'components/Utility/PropagationInterceptor';
import ScoreDisplayCopy from 'components/Assignment/ActivityListDashboard/ScoreCard/ScoreDisplay/ScoreDisplayCopy';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';

const mapStateToProps = (state: any) => {
  return {};
};

function ScoreTrend({ scoreTrend }: any) {
  const renderArrow = (scoreTrend: any) => {
    if (scoreTrend > 0) {
      return <ArrowUpwardIcon color="inherit" />;
    }
    if (scoreTrend < 0) {
      return <ArrowDownwardIcon color="inherit" />;
    }
    return null;
  };
  const roundedScoreTrend = Math.round(scoreTrend);
  return (
    <div className="dashboard-score-display__trending">
      <div className="dashboard-score-display__trending-arrow">
        {renderArrow(roundedScoreTrend)}
      </div>
      <div className="dashboard-score-display__trending-number">{Math.abs(roundedScoreTrend)}%</div>
    </div>
  );
}

function Score({ score, scoreTrend }: any) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <div className="dashboard-score-display__number">
          {score === null ? <CircularProgress /> : `${Math.round(score)}%`}
        </div>
      </Grid>
      <Grid item xs={6}>
        {scoreTrend !== null && <ScoreTrend scoreTrend={scoreTrend} />}
      </Grid>
    </Grid>
  );
}

Score.defaultProps = {
  score: null,
  scoreTrend: null,
};

function Weight({ weight, isPercentageGrading }: any) {
  const text = isPercentageGrading
    ? localize({ message: 'Activity.Score.Weight.Grade', options: { weight } })
    : localize({ message: 'Activity.Score.Weight.Points', options: { weight } });
  return (
    <div className="dashboard-score-display__weight">{weight !== undefined && <p>{text}</p>}</div>
  );
}

function ScoreDescription({ description, ariaLabel }: any) {
  return (
    <div className="dashboard-score-display__desc">
      <p role="contentinfo" aria-label={ariaLabel}>
        {description}
      </p>
    </div>
  );
}

function ScoreDisplay(props: any) {
  const { scoreName } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const { isStudentInCourse } = useUserRoleInCourse();

  function handleCancelPopover(e: any) {
    if (anchorEl && (anchorEl as any).contains(e.target)) {
      setIsCancelling(true);
    }
    setAnchorEl(null);
  }

  function renderQuestionPopover() {
    return (
      <PropagationInterceptor>
        <InfoPopup
          anchorEl={anchorEl}
          isOpen={!!anchorEl}
          onCancel={handleCancelPopover}
          title={ScoreDisplayCopy.title[scoreName]}
          description={ScoreDisplayCopy.content[scoreName]}
          learnMoreUrl={ScoreDisplayCopy.links[scoreName]}
        />
      </PropagationInterceptor>
    );
  }

  return (
    <div className="dashboard-score-display" role="contentinfo" aria-label={scoreName}>
      <Score scoreTrend={props.scoreTrend} score={props.score} />
      <Grid container justifyContent="space-between">
        <h3 className="dashboard-score-display__header" role="heading">
          {scoreName}
        </h3>
        {isStudentInCourse && renderQuestionPopover()}
      </Grid>
      {props.weight && (
        <Weight weight={props.weight} isPercentageGrading={props.isPercentageGrading} />
      )}
      <ScoreDescription description={props.description} ariaLabel={props.ariaLabel} />
    </div>
  );
}

export default connect(mapStateToProps)(ScoreDisplay);
