import React from 'react';
import InlineInformation from 'components/layout/InlineInformation';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

export const RosterSyncError = ({ cancel, error }: any) => {
  return (
    <>
      <InlineInformation type="danger">
        {error || <TranslatedText i18nKey="RosterManager.SyncRosterPopup.Error.DefaultMessage" />}
      </InlineInformation>
      <ButtonContainer>
        <Button type="secondary" onClick={cancel}>
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default RosterSyncError;
