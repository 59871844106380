import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import type { Course, User } from '@kritik/types.generated';
import CourseService from 'services/course';
import { courseService, userService } from 'services/index';
import { trackEvent } from 'utils/userEvents';
import { useParams } from 'react-router';
import { AuthUser, UserRole } from 'app-types';
import { useAuthUserFromRedux } from './user';
import { useStore } from 'react-redux';
import { updateCourseInReduxStore } from 'actions/courses';
import { enqueueSnackbar } from 'notistack';
import { localize } from 'locales';

export type LtiCourseParams = {
  title: string;
  department: string;
  user: string;
  lms: {
    connectedCourseId: string;
    connectedCourseName: string;
    edlinkIntegrationId: string;
  };
  institution: string;
  courseSettings: {
    hideAverageGrade: boolean;
  };
  code: string;
};

export type LtiDuplicateCourseParams = {
  title: string;
  courseId: string;
  includeActivities: boolean;
  section: string;
  code: string;
  user: string;
  lms: {
    connectedCourseId: string;
    connectedCourseName: string;
    edlinkIntegrationId: string;
    lmsClassId?: string;
  };
};

export type Collaborator = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
  enrollmentStatus: 'pending' | 'removed' | 'enrolled';
};

export function useFetchCourses() {
  return useQuery(['courses'], async () => {
    const result = await CourseService().list({});
    return result.data;
  });
}

export function useFetchCourse(courseId: string) {
  const store = useStore();
  return useQuery(
    ['course', courseId],
    async () => {
      const result = await CourseService().get({ id: courseId });
      updateCourseInReduxStore(store.dispatch, result.data);
      return result.data as Course & { userRole: UserRole };
    },
    { enabled: Boolean(courseId) }
  );
}

export function createCourseMutation(authUser: AuthUser) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (course: courses.POST.Request) => {
      const result = await CourseService().create(course);
      return result.data;
    },
    onSuccess: async (course) => {
      trackEvent('Course Created', authUser, {
        courseName: course.title,
        courseId: course._id,
        instructorEmail: authUser.email,
        createdAt: course.createdAt.toString(),
      });

      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
    onError: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
  });
}

export function duplicateCourseMutation(authUser: AuthUser) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (course: LtiDuplicateCourseParams) => {
      const result = await CourseService().createDuplicate({
        courseId: course.courseId,
        data: course,
      });
      return result.data;
    },
    onSuccess: async (_, context) => {
      trackEvent('Course Duplicated', authUser, {
        instructorEmail: authUser.email,
        activitiesDuplicated: context.includeActivities,
      });
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
  });
}

export function updateCourseMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (course: Course) => {
      const result = await CourseService().update({ id: course._id, data: course });
      return result.data;
    },
    onSuccess: () => {
      enqueueSnackbar('', {
        title: localize({
          message: 'Course.Update.Success',
        }),
        variant: 'success',
      });
    },
    onSettled: async (course) => {
      await queryClient.invalidateQueries({
        queryKey: ['course', course._id],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
  });
}

type DeleteCourseMutationArgs = {
  onError: () => void;
};
export function deleteCourseMutation({ onError }: DeleteCourseMutationArgs) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (courseId: string) => {
      const result = await CourseService().delete({ id: courseId });
      return result.data;
    },
    onSuccess: async () => {
      enqueueSnackbar('', {
        title: localize({
          message: 'Course.Delete.Success',
        }),
        variant: 'success',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
    onError: async () => {
      onError();
      enqueueSnackbar('', {
        title: localize({
          message: 'Course.Delete.Error',
        }),
        variant: 'error',
      });
    },
  });
}

export const useStudentInfo = ({
  courseId,
  isStudent = false,
}: {
  courseId: string;
  isStudent: boolean;
}) => {
  return useQuery(
    ['studentInfo', courseId],
    async () => {
      const response = await userService().getStudent({
        courseId: courseId,
      });
      return response.data;
    },
    { enabled: isStudent }
  );
};

export const handleRemoveCollaborator = (courseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      courseId,
      collaboratorEmail,
    }: {
      courseId: string;
      collaboratorEmail: string;
    }) => {
      return await courseService().removeCollaborator({ courseId, collaboratorEmail });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courseCollaborators', courseId],
      });
    },
    onError: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courseCollaborators', courseId],
      });
    },
  });
};

export const inviteCollaborators = (courseId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ courseId, emails }: { courseId: string; emails: string[] }) => {
      return await courseService().inviteCollaborators({ courseId, emails });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courseCollaborators', courseId],
      });
    },
    onError: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courseCollaborators', courseId],
      });
    },
  });
};

export function addCollaboratorMutation(courseId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user: any) => {
      return await courseService().addCollaborator({ courseId, user });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courseCollaborators', courseId],
      });
    },
    onError: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['courseCollaborators', courseId],
      });
    },
  });
}

export function archiveCourseMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (courseId: string) => {
      return await courseService().archive({ courseId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
  });
}

export function unArchiveCourseMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (courseId: string) => {
      return await courseService().unArchive({ courseId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['courses'],
      });
    },
  });
}

export function inviteStudentsMutation(courseId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      courseId,
      emails,
      reinvite,
    }: {
      courseId: string;
      emails: string[];
      reinvite: boolean;
    }) => {
      return await courseService().inviteStudents({ courseId, emails, reinvite });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
    },
  });
}

export function removeStudentInviteMutation(courseId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ courseId, studentEmail }: { courseId: string; studentEmail: string }) => {
      return await courseService().removeStudentInvite({ courseId, studentEmail });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
    },
  });
}

export function useUserRoleInCourse() {
  const authUser = useAuthUserFromRedux();
  const { courseId } = useParams();
  const course = useGetCachedCourse(courseId);
  return {
    role: course?.userRole,
    isInstructorInCourse: course?.userRole === 'instructor',
    isStudentInCourse: course?.userRole === 'student',
    isOwnerInCourse: (course?.user as User)?._id === authUser?.id,
  };
}

export function useGetCachedCourse(courseId: string) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const course = queryCache.find<Course & { userRole: 'student' | 'instructor' }>([
    'course',
    courseId,
  ]);
  return course.state.data;
}

export function useFetchCourseCollaborators(courseId: string) {
  return useQuery(['courseCollaborators', courseId], async () => {
    const result = await CourseService().getCourseCollaborators({ courseId });
    return result.data as Collaborator[];
  });
}

export function useAttachFileId({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: async (params: { courseId: string; fileId: string }) => {
      const response = await CourseService().attachFileId(params);
      return response.data;
    },
    onSuccess,
    onError,
  });
}
