import * as _ from 'lodash-es';
import FormInputSwitch from 'components/Form/FormInputSwitch';
import { isCreateOrLater } from '@kritik/utils/stage';
import { useTranslation } from 'react-i18next';
import { InlineInformation } from 'components/layout';
import { TranslatedText } from 'components/TranslatedText';

const OptionalCreateStage = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <FormInputSwitch
        checked={props.isCreateStageOptional}
        helpText={t('Activity.Settings.OptionalCreateStage.Info')}
        label={t('Activity.Settings.OptionalCreateStage.Label')}
        name="isCreateStageOptional"
        onChange={props.onOptionalCreateStageChange}
        disabled={isCreateOrLater(props.activity) && !props.isDuplicatingActivity}
        testId="switch-skip-create-stage"
      />
      {props.isCreateStageOptional && props.acceptLateCreations && (
        <InlineInformation type="warning" testid="optional-create-stage-warning">
          <TranslatedText i18nKey="Activity.Settings.OptionalCreateStage.Warning" />
        </InlineInformation>
      )}
    </>
  );
};

export default OptionalCreateStage;
