import { Field } from 'react-final-form';
import NumberField from 'components/core/form/NumberField';
import { localize } from 'locales';
import { useParams } from 'react-router';
import { MINIMUM_WORD_COUNT } from '@kritik/constants/course';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { TranslatedText } from 'components/TranslatedText';

const MinimumWordCount = ({
  label,
  fieldName,
  wordCount,
  testid,
}: {
  label?: string;
  fieldName: string;
  wordCount?: number;
  testid?: string;
}) => {
  const { courseId } = useParams();

  return (
    <Field
      required
      name={fieldName}
      validate={validators.composeValidators(validators.minValue(MINIMUM_WORD_COUNT))}
    >
      {({ input, meta }) => {
        return (
          <>
            <NumberField
              label={label}
              value={input.value}
              onChange={(e: any) => {
                return input.onChange(Number(e.target.value));
              }}
              error={meta.error && meta.touched && meta.error}
              testid={testid}
              id="min-word-count-course-details"
              aria-describedby="minimum-count-instruction"
            />
            <div>
              {wordCount ? (
                <p>
                  {localize({
                    message: 'Activity.WordCount',
                    options: {
                      default: `${wordCount}`,
                    },
                  })}
                  <a target="_blank" href={`/course/${courseId}`} className="underlined-link">
                    {localize({
                      message: 'Activity.Edit.CourseSettings',
                    })}
                  </a>
                  .
                </p>
              ) : (
                <p id="minimum-count-instruction">
                  <TranslatedText
                    i18nKey="MinimumWordCount.Instruction"
                    values={{ count: MINIMUM_WORD_COUNT }}
                  />
                </p>
              )}
            </div>
          </>
        );
      }}
    </Field>
  );
};

MinimumWordCount.defaultProps = {
  label: localize({ message: 'MinimumWordCount.DefaultLabel' }),
};

export default MinimumWordCount;
