import { Grid } from '@material-ui/core';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { getNewKS } from 'utils/student';
import ShortenText from 'components/General/ShortenText';
import Typography from 'components/core/Typography';
import { Button } from 'components/buttons';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

const NameCell = ({ student, group, isListByGroup }: any) => {
  //student might be null at this point, so we need to access the property safely with ?
  const name = isListByGroup && group ? group.name : student?.user?.profile?.name;

  return (
    <Button
      type="hidden"
      className="creation-table__row-name"
      aria-label={localize({ message: 'StudentInfo.SelectStudent', options: { name } })}
    >
      <AvatarDisplay
        user={student?.user}
        kritikScore={getNewKS(student)}
        size="50px"
        style={{ marginRight: 5 }}
        group={isListByGroup ? group : null}
      />
      <Grid container>
        <Grid item xs={12}>
          <ShortenText label={name} maxLength={15} />
        </Grid>
        <Grid item xs={12}>
          {!isListByGroup && group && (
            <Typography weight={400} fontSize={12}>
              <ShortenText label={group.name} maxLength={15} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Button>
  );
};

export default NameCell;
