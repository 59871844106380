import { Component } from 'react';
import { connect } from 'react-redux';
import { RouterProp, withRouter } from 'utils/withRouter';
import { getStudentInCourse } from 'actions/users';
import { selectCourse, selectAssignment } from 'actions/select';
import AssignmentList from 'components/Assignment/List';
import { getAssignmentList } from 'selectors/activity';
import { getStudentFromUser } from 'selectors/student';
import { getCourse } from 'selectors/course';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import ScoreTutorial from 'components/tutorial/Banner/ScoreTutorial';
import Access from 'components/Access';
import { push } from 'router';
import { GET_STUDENT } from 'types';
import { navigateToRubricManager, navigateToActivityImport } from 'redux/redirect';
import { localize } from 'locales';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { trackEvent } from 'utils/userEvents';
import { useUserRoleInCourse } from 'hooks/course';
import { UserRoleInCourse } from 'app-types';
import DownloadGradebookModal from 'components/DownloadGradebookModal';
import { Course, Student } from 'old-common/types.generated';
import { Activity } from 'old-common/utils/factory';
import { TranslatedText } from 'components/TranslatedText';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    assignmentList: getAssignmentList(state),
    student: getStudentFromUser(state, state.selected.courseId),
    course: getCourse(state),
    getStudentSuccess: state.async[GET_STUDENT].success,
    getStudentError: state.async[GET_STUDENT].error,
  };
};

type Props = {
  user: UserWithAuthUser;
  userRole: UserRoleInCourse;
  router: RouterProp;
  course: Course;
  student: Student;
  isAiActivityCreationEnabled: boolean;
  getStudentInCourse: ({ courseId }: { courseId: string }) => void;
  selectCourse: (string) => void;
  selectAssignment: (any) => void;
  getStudentError: any;
  getStudentSuccess: any;
  navigateToRubricManager: () => void;
  navigateToActivityImport: ({ courseId }: { courseId: string }) => void;
  assignmentList: Activity[];
};

type CourseAssignmentsComponentState = {
  isConfirmDownloadGradebookModalOpen: boolean;
};

class CourseAssignmentsComponent extends Component<Props, CourseAssignmentsComponentState> {
  state = { isConfirmDownloadGradebookModalOpen: false };

  componentDidMount() {
    const {
      router,
      userRole: { isStudentInCourse },
    } = this.props;

    if (isStudentInCourse) {
      this.props.getStudentInCourse({ courseId: router.params.courseId });
    }
    this.props.selectCourse(router.params.courseId);
    this.props.selectAssignment(null);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.getStudentError && this.props.getStudentError != prevProps.getStudentError) {
      this.props.router.push('/');
    }
    if (
      this.props.getStudentSuccess &&
      this.props.student &&
      this.props.student.removedOn != null
    ) {
      this.props.router.push('/');
    }
  }

  createAssignment = () => {
    this.props.router.push(`/course/${this.props.course._id}/create-activity`);
  };

  createActivityAi = () => {
    this.props.router.push(`/course/${this.props.course._id}/create-activity-ai`);
  };

  setIsConfirmDownloadGradebookModalOpen = (isConfirmDownloadGradebookModalOpen) => {
    this.setState({ isConfirmDownloadGradebookModalOpen });
  };

  renderMoreActions = () => {
    const MANAGE_KEY = 'manage_rubric_templates';
    const IMPORT_KEY = 'import_content';
    const DOWNLOAD_GRADEBOOK_KEY = 'download_gradebook';

    const handleMoreActionSelection = (e: any) => {
      const option = e.target.value;
      switch (option) {
        case MANAGE_KEY:
          return this.props.navigateToRubricManager();
        case IMPORT_KEY:
          return this.props.navigateToActivityImport({
            courseId: this.props.course._id,
          });
        case DOWNLOAD_GRADEBOOK_KEY:
          return this.setIsConfirmDownloadGradebookModalOpen(true);
        default:
          return null;
      }
    };
    const options = [
      {
        label: localize({ message: 'CourseDetails.Actions.ImportContent' }),
        value: IMPORT_KEY,
        testid: 'import-activity',
      },
      this.props.user.authUser?.permissions.includes('canCreateCourse') && {
        label: localize({ message: 'CourseDetails.Actions.ManageRubricTemplates' }),
        value: MANAGE_KEY,
        testid: 'manage-rubric-templates',
      },
      {
        label: localize({ message: 'CourseDetails.Actions.DownloadGradebook' }),
        value: DOWNLOAD_GRADEBOOK_KEY,
        testid: 'download-gradebook',
      },
    ].filter(Boolean);

    return (
      <ButtonWithSelect
        testid="course_see-more-actions"
        title={localize({ message: 'Course.Actions.MoreActions' })}
        options={options}
        onChange={handleMoreActionSelection}
        type="secondary"
      />
    );
  };

  renderTeacherOptions = () => {
    const { isInstructorInCourse } = this.props.userRole;
    if (!isInstructorInCourse) {
      return null;
    }
    return (
      <ButtonContainer>
        {this.renderMoreActions()}
        <Button
          role="link"
          type="primary"
          data-testid="create-activity"
          onClick={() => {
            return this.createAssignment();
          }}
        >
          {localize({ message: 'Activity.Create' })}
        </Button>
        {this.props.isAiActivityCreationEnabled && (
          <Button
            role="link"
            type="primary"
            onClick={() => {
              trackEvent('Clicked on Generate AI Activity', this.props.user.authUser, {
                courseName: this.props.course?.title,
              });
              return this.createActivityAi();
            }}
          >
            {localize({ message: 'Activity.CreateWithAI' })}
          </Button>
        )}
      </ButtonContainer>
    );
  };

  render() {
    const {
      course,
      student,
      userRole: { isStudentInCourse, isInstructorInCourse },
    } = this.props;

    if (!course) {
      return null;
    }

    if (isStudentInCourse && (!student || student.removedOn != null)) {
      return null;
    }

    if (!isStudentInCourse && course && course.user && !isInstructorInCourse) {
      this.props.router.push('/');
    }

    return (
      <PageContainer>
        <DownloadGradebookModal
          course={course}
          isOpen={this.state.isConfirmDownloadGradebookModalOpen}
          setIsOpen={this.setIsConfirmDownloadGradebookModalOpen}
        />
        <PageHeader className="page-title" title={<TranslatedText i18nKey="Activity.PageHeader" />}>
          {this.renderTeacherOptions()}
        </PageHeader>
        <Access student={<ScoreTutorial />} />
        <AssignmentList assignments={this.props.assignmentList} />
      </PageContainer>
    );
  }
}

const CourseAssignments = (props: any) => {
  const isAiActivityCreationEnabled = useIsFeatureFlagEnabled('create_activity_ai');
  const userRole = useUserRoleInCourse();
  return (
    <CourseAssignmentsComponent
      {...props}
      isAiActivityCreationEnabled={isAiActivityCreationEnabled}
      userRole={userRole}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, {
    getStudentInCourse,
    selectCourse,
    selectAssignment,
    push,
    navigateToActivityImport,
    navigateToRubricManager,
  })(CourseAssignments)
);
