import React from 'react';
import { connect } from 'react-redux';
import {
  isCalibrationActivity,
  getActivityWeight,
  isGroupAssignment,
  isPresentationActivity,
} from '@kritik/utils/activity';
import WeightWarning from 'components/Assignment/Weight/Warning';
import LabeledProgressBar from 'components/layout/ProgressBarWithLabel';
import { selectCurrentCourse } from 'selectors/course';
import { selectCurrentActivity } from 'selectors/activity';
import { selectCurrentStudent } from 'selectors/student';
import {
  getSubmissionsToEvaluate,
  getSubmission,
  getMySubmission,
  getCreationsFromEntities,
} from 'selectors/creation';
import { OverallScoreUtil, GradeHistoryUtil, FeedbackScoreUtil } from '@kritik/utils/grade';
import { TranslatedText } from 'components/TranslatedText';
import GradeViewUtils from 'components/Creation/GradeView/utils';
import * as _GradeHistoryUtil from '@kritik/utils/gradeHistory';
import { simplePercent } from '@kritik/utils/format';
import { getCreationStage, getEvaluationStage, getFeedbackStage } from '@kritik/utils/stage';
import { localize } from 'locales';

const Item = ({ children }: any) => {
  return (
    <div className="finalized-status-display__item" tabIndex={0}>
      {children}
    </div>
  );
};

const FinalizedPanelDisplay = (props: any) => {
  const { course, activity, creation, student, submissionsToEvaluate, creations } = props;
  const getScoreNames = () => {
    return {
      creationScoreName: localize({ message: 'FinalizedPanelDisplay.ScoreNames.CreationScore' }),
      evaluationScoreName: localize({
        message: 'FinalizedPanelDisplay.ScoreNames.EvaluationScore',
      }),
      feedbackScoreName: localize({ message: 'FinalizedPanelDisplay.ScoreNames.FeedbackScore' }),
      overallScoreName: localize({ message: 'FinalizedPanelDisplay.ScoreNames.OverallScore' }),
    };
  };
  const gradeHistory = _GradeHistoryUtil.getStudentActivityGradeHistory(
    student.gradeHistories,
    props.activity._id
  );

  const creationScore = GradeHistoryUtil.getCreationScoreFromGradeHistory(gradeHistory);

  const getFeedbackScore = () => {
    if (isGroupAssignment(activity) || isPresentationActivity(activity)) {
      return FeedbackScoreUtil.calcScore({ creation, activity, studentId: student._id, creations });
    }
    return FeedbackScoreUtil.calcScore({ creation, activity, studentId: creation.student._id });
  };

  const feedbackScore = getFeedbackScore();

  const { evaluation } = GradeViewUtils.getEvaluationScores({
    gradeHistory,
    creationsToEvaluate: submissionsToEvaluate,
    student,
    activity,
    course,
    creation,
  });

  const getFinalizedPanelCategories = () => {
    const scoreNames = getScoreNames();
    const shouldHideFeedbackScore = isCalibrationActivity(activity);

    const categories = [];

    if (!isCalibrationActivity(activity)) {
      const overallScore = OverallScoreUtil.calculateOverallScore(gradeHistory, course);
      categories.push({
        name: scoreNames.overallScoreName,
        progress: overallScore,
        content: simplePercent(overallScore),
      });
    }

    categories.push({
      name: scoreNames.creationScoreName,
      progress: creationScore,
      content: simplePercent(creationScore),
    });

    categories.push({
      name: scoreNames.evaluationScoreName,
      progress: evaluation,
      content: simplePercent(evaluation),
    });

    if (!shouldHideFeedbackScore) {
      categories.push({
        name: scoreNames.feedbackScoreName,
        progress: feedbackScore,
        content: simplePercent(feedbackScore),
      });
    }

    return categories;
  };

  const getFinalizedPanelCategoriesForPresentationActivity = () => {
    const scoreNames = getScoreNames();
    const categories = [];

    const creationStage = getCreationStage(activity) as PresentationAssignmentStatus;
    const evaluationStage = getEvaluationStage(activity) as PresentationAssignmentStatus;
    const feedbackStage = getFeedbackStage(activity) as PresentationAssignmentStatus;

    if (creationStage.isParticipating) {
      categories.push({
        name: scoreNames.creationScoreName,
        progress: creationScore,
        content: simplePercent(creationScore),
      });
    }

    if (evaluationStage.isParticipating) {
      categories.push({
        name: scoreNames.evaluationScoreName,
        progress: evaluation,
        content: simplePercent(evaluation),
      });
    }

    if (feedbackStage.isParticipating) {
      categories.push({
        name: scoreNames.feedbackScoreName,
        progress: feedbackScore,
        content: simplePercent(feedbackScore),
      });
    }

    return categories;
  };
  const renderWeight = () => {
    const weight = getActivityWeight(props.activity);
    return (
      <Item>
        <div className="finalized-status-display__weight-label">
          <TranslatedText i18nKey="Activity.ScoreSummary.Weight" />
        </div>
        <div className="finalized-status-display__weight">
          {weight} <TranslatedText i18nKey="Activity.Weight.Points" values={{ count: weight }} />
        </div>
        <WeightWarning activity={props.activity} />
      </Item>
    );
  };
  const categories = isPresentationActivity(props.activity)
    ? getFinalizedPanelCategoriesForPresentationActivity()
    : getFinalizedPanelCategories();
  return (
    <React.Fragment>
      {renderWeight()}
      {categories.map((category) => {
        if (
          isCalibrationActivity(props.activity) &&
          category.name === localize({ message: 'FinalizedPanelDisplay.ScoreNames.CreationScore' })
        ) {
          return null;
        }
        return (
          <Item>
            <LabeledProgressBar
              className="finalized-status-display__progress"
              testid={`progress-bar-${category.name}`}
              label={category.name}
              progress={category.progress}
              progressLabel={category.content}
            />
          </Item>
        );
      })}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const activity = selectCurrentActivity(state);
  const student = selectCurrentStudent(state);
  return {
    course: selectCurrentCourse(state),
    activity,
    student,
    submissionsToEvaluate: getSubmissionsToEvaluate(state, activity._id, student._id),
    creation:
      getSubmission(state, state.selected.submissionId) || getMySubmission(state, activity._id),
    creations: getCreationsFromEntities(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps)(FinalizedPanelDisplay);
