import { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { localize } from 'locales';
import { useCreateFile, useUploadFile } from 'hooks/file';
import FileList from 'components/layout/AttachmentManager/FileList';
import { CourseFile } from '@kritik/types.generated';
import { File } from 'app-types';

type Props = {
  courseId?: string;
  existingSyllabus?: CourseFile;
  onUploadFile: (file: File) => void;
  onRemoveFile?: (file: File) => void;
  maxSize?: number;
  acceptedMimeTypes?: string[];
};
export default function SyllabusFileUpload(props: Props) {
  const [loading, setLoading] = useState(false);
  const newFile = useRef(null);
  const [file, setFile] = useState(props.existingSyllabus);
  const [error, setError] = useState(null);
  const dropzoneRef = useRef(null);

  function validateFile(inputFile: any) {
    if (inputFile.size > props.maxSize) {
      return localize({ message: 'Activity.CreateAI.FileUpload.Error.FileTooLarge' });
    }
    if (!props.acceptedMimeTypes.includes(inputFile.type)) {
      return localize({ message: 'Activity.CreateAI.FileUpload.Error.InvalidFileType' });
    }
  }

  const createFileMutation = useCreateFile({
    onSuccess: (data: { fileId: string }) => {
      props.onUploadFile({
        ...newFile.current,
        fileId: data?.fileId,
      });

      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setError(localize({ message: 'Activity.CreateAI.FileUpload.Error.Generic' }));
    },
  });

  const uploadFileMutation = useUploadFile({
    onSuccess: (data: UploadResponse[]) => {
      if (!data || data?.length < 1) {
        return setError(localize({ message: 'Activity.CreateAI.FileUpload.Error.Generic' }));
      }
      const s3Info = data[0];
      newFile.current = {
        ...newFile.current,
        url: s3Info.Location,
        mimetype: s3Info.mimetype,
      };

      // create File entity on the server
      createFileMutation.mutate(newFile.current);
    },
    onError: () => {
      setLoading(false);
      setError(localize({ message: 'Activity.CreateAI.FileUpload.Error.Generic' }));
    },
  });

  async function handleUpload(files: any) {
    if (files.length < 1) {
      return;
    }
    const selectedFile = files[0];
    const validationError = validateFile(selectedFile);
    if (validationError) {
      return setError(validationError);
    } else {
      setError(null);
    }

    setLoading(true);
    setFile(null);
    setError(null);

    if (newFile.current) {
      props.onRemoveFile?.(newFile.current);
    }
    setFile({
      _id: null,
      name: selectedFile.name,
      size: selectedFile.size,
    });

    newFile.current = {
      name: selectedFile.name,
      size: selectedFile.size,
    };

    // upload file
    const formData = new FormData();
    formData.append('file[]', selectedFile, selectedFile.name);
    uploadFileMutation.mutate({
      formData,
      allowedExtensions: props.acceptedMimeTypes,
    });
  }

  function handleRemove() {
    props.onRemoveFile?.(newFile.current);
    setFile(null);
    setError(null);
  }

  return (
    <>
      <Dropzone
        ref={dropzoneRef}
        name="file"
        onDrop={async (files: any) => {
          await handleUpload(files);
        }}
        multiple={false}
        className="syllabus-file-upload-dropzone"
        activeClassName="syllabus-file-upload-active-dropzone"
      >
        {Boolean(file) ? (
          <>
            <FileList files={[file]} onRemoveFile={handleRemove} loading={loading} />
            <div className="syllabus-file-upload__save-container"></div>
          </>
        ) : (
          <>
            <div
              className="syllabus-file-upload__browse_drop"
              role="button"
              aria-describedby="syllabus-file-upload-instructions"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  dropzoneRef.current.open();
                }
              }}
            >
              <i className="fas fa-upload"></i>{' '}
              {localize({ message: 'Activity.CreateAI.FileUpload.BrowseOrDragNDrop.Text' })}
            </div>
            <div
              className="syllabus-file-upload__instructions helptip"
              id="syllabus-file-upload-instructions"
            >
              {localize({
                message: 'Activity.CreateAI.FileUpload.Instructions.Text',
                options: { maxFileSize: '10 MB' },
              })}
            </div>
          </>
        )}
        <div className="syllabus-file-upload__hover">
          <i className="fas fa-upload"></i>{' '}
          {localize({ message: 'Activity.CreateAI.FileUpload.DragNDrop.Hover.Text' })}
        </div>
        {error && <div className="syllabus-file-upload__error">{error}</div>}
      </Dropzone>
    </>
  );
}
SyllabusFileUpload.defaultProps = {
  maxSize: 10485760, // in bytes (10 MB)
  acceptedMimeTypes: [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
};
