import React from 'react';
import InfoLabel from 'components/layout/InfoLabel';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { localize } from 'locales';

const PassFailGrade = ({ creation }: any) => {
  const isPassed = CreationScoreUtil.isPassed(creation);
  if (isPassed) {
    return <InfoLabel type="success" label={localize({ message: 'PassFailGrade.Passed' })} />;
  }

  return <InfoLabel type="danger" label={localize({ message: 'PassFailGrade.Failed' })} />;
};

export default PassFailGrade;
